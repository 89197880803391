import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {UserService} from './services/user.service';
import {GoogleMapsModule} from '@angular/google-maps';
import { HomeComponent } from './components/home/home.component';
import {DialogInfoComponent, SensormonitoringComponent} from './components/sensor-monitoring/sensormonitoring.component';
import { DronesComponent } from './components/drones/drones.component';
import {DatePipe} from '@angular/common';
import { SensorMonitoringDetailsComponent } from './components/sensor-monitoring-details/sensor-monitoring-details.component';
import {AgmCoreModule} from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { VideoCamerasComponent } from './components/video-cameras/video-cameras.component';
import {FormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ModalComponent } from './modal/modal.component';
import { MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {FireDangerComponent, InfoDialogComponent} from './components/fire-danger/fire-danger.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FireBehaviourComponent, InfoDialogFBComponent} from './components/fire-behaviour/fire-behaviour.component';
import { SensorMonitoringAlarmsComponent } from './components/sensor-monitoring-alarms/sensor-monitoring-alarms.component';
import {MatCardModule} from '@angular/material/card';
import { BoardAdminComponent } from './components/board-admin/board-admin.component';
import { BoardParkmanagerComponent } from './components/board-parkmanager/board-parkmanager.component';
import { BoardUserComponent } from './components/board-user/board-user.component';
import {AuthInterceptor} from './helpers/auth.interceptor';
import { MenuComponent } from './components/menu/menu.component';
import {FilterPipe} from './helpers/filter.pipe';
import {MatSelectModule} from '@angular/material/select';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SiteMonitoringDetailsComponent } from './components/site-monitoring-details/site-monitoring-details.component';
import {MatRadioModule} from '@angular/material/radio';
import {
  DeleteConfirmItaComponent, EventLogsItaComponent,
  FireCoordinatesItaComponent,
  FleetManagementItaComponent,
  LogsItaComponent,
  MessagesItaComponent,
} from './components/fleet-management_ita/fleet-management.component';
import {
  DeleteConfirmComponent,
  EventLogsComponent,
  FireCoordinatesComponent,
  FleetManagementComponent,
  LogsComponent,
  MessagesComponent,
} from './components/fleet-management/fleet-management.component';
import {ChatService} from './services/chat.service';
import {MenuItaComponent} from './components/menu_ita/menu.component';
import {FireDangerItaComponent, InfoDialogItaComponent} from './components/fire-danger_ita/fire-danger.component';
import {DialogInfoItaComponent, SensormonitoringItaComponent} from './components/sensor-monitoring_ita/sensormonitoring.component';
import {SensorMonitoringAlarmsItaComponent} from './components/sensor-monitoring-alarms_ita/sensor-monitoring-alarms.component';
import {SensorMonitoringDetailsItaComponent} from './components/sensor-monitoring-details_ita/sensor-monitoring-details.component';
import {VideoCamerasItaComponent} from './components/video-cameras_ita/video-cameras.component';
import {SiteMonitoringDetailsItaComponent} from './components/site-monitoring-details_ita/site-monitoring-details.component';
import {DronesItaComponent} from './components/drones_ita/drones.component';
import {FireBehaviourItaComponent, InfoDialogFBItaComponent} from './components/fire-behaviour_ita/fire-behaviour.component';
import { CameraItaComponent } from './components/camera-ita/camera-ita.component';
import { CameraComponent } from './components/camera/camera.component';
import { MeteoItaComponent } from './components/meteo_ita/meteo.component';
import { MeteoComponent } from './components/meteo/meteo.component';

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    ModalComponent,
    FilterPipe,
    HomeComponent,
    BoardAdminComponent,
    BoardParkmanagerComponent,
    BoardUserComponent,
    MenuComponent,
    MenuItaComponent,
    FireDangerComponent,
    InfoDialogComponent,
    FireDangerItaComponent,
    InfoDialogItaComponent,
    FireBehaviourComponent,
    FireBehaviourItaComponent,
    InfoDialogFBComponent,
    InfoDialogFBItaComponent,
    SensormonitoringComponent,
    DialogInfoComponent,
    SensormonitoringItaComponent,
    DialogInfoItaComponent,
    SensorMonitoringDetailsComponent,
    SensorMonitoringDetailsItaComponent,
    SensorMonitoringAlarmsComponent,
    SensorMonitoringAlarmsItaComponent,
    SiteMonitoringDetailsComponent,
    SiteMonitoringDetailsItaComponent,
    MeteoItaComponent,
    MeteoComponent,
    VideoCamerasComponent,
    VideoCamerasItaComponent,
    CameraComponent,
    CameraItaComponent,
    DronesComponent,
    DronesItaComponent,
    FleetManagementComponent,
    FleetManagementItaComponent,
    LogsComponent,
    LogsItaComponent,
    EventLogsComponent,
    EventLogsItaComponent,
    MessagesComponent,
    MessagesItaComponent,
    FireCoordinatesComponent,
    FireCoordinatesItaComponent,
    DeleteConfirmComponent,
    DeleteConfirmItaComponent,
    CameraItaComponent,
    CameraComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GoogleMapsModule,
    AgmCoreModule.forRoot({ // @agm/core
      apiKey: 'AIzaSyBB97ngw_ed017I_P643KXpvEveKiCJ4Gs',
      libraries: ['geometry']
    }),
    AgmDirectionModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatCardModule,
    MatSelectModule,
    NgbModule,
    MatRadioModule,
    GoogleMapsModule,
    GoogleMapsModule,
  ],
  providers: [UserService, DatePipe, ChatService,
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    }
    ],
  bootstrap: [AppComponent],
  entryComponents: [ModalComponent]
})
export class AppModule { }

