import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ChatService {

  events: Subject<any>;
  messages: Subject<any>;
  startalarms: Subject<any>;
  interventionalarms: Subject<any>;
  stopalarms: Subject<any>;

  // Our constructor calls our wsService connect method
  constructor(private wsService: WebsocketService) {
    this.events = (wsService
      .connect('event').pipe(map((response: any) => response)) as Subject<any>);
    this.messages = (wsService
      .connect('msg').pipe(map((response: any) => response)) as Subject<any>);
    this.startalarms = (wsService
      .connect('startalarm').pipe(map((response: any) => response)) as Subject<any>);
    this.interventionalarms = (wsService
      .connect('interventionalarm').pipe(map((response: any) => response)) as Subject<any>);
    this.stopalarms = (wsService
      .connect('stopalarm').pipe(map((response: any) => response)) as Subject<any>);
  }

  // Our simplified interface for sending
  // messages back to our socket.io server
  sendMsg(message) {
    this.messages.next(message);
  }
  sendAlarm(message) {
    this.startalarms.next(message);
  }
}
