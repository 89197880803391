import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {PYOPHIDIA_DASHBOARD_URL, PYOPHIDIA_EXIST_URL} from '../../env';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogInfoComponent} from '../sensor-monitoring/sensormonitoring.component';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
export interface DialogData {
  param: string;
  description: string;
}
declare var initMap: any;
@Component({
  selector: 'app-fire-danger',
  templateUrl: './fire-danger.component.html',
  styleUrls: ['./fire-danger.component.css']
})
export class FireDangerComponent implements OnInit {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, public dialog: MatDialog) {
  }
  myCenter = new google.maps.LatLng(40.94256444, 16.87774658);
  mapOptions = {
    center: this.myCenter,
    zoom: 8,
    mapTypeId: google.maps.MapTypeId.HYBRID
  };
  googleMap: google.maps.Map;
  marker: google.maps.Marker;
  zoom = 8;
  center = {lat: 40.94256444, lng: 16.87774658};
  overlay: any;
  arraydate: any;
  mapdate: any;
  existmap: boolean;
  date: any;
  urlexist: boolean;
  firstday: any;
  secondday: any;
  thirdday: any;
  day: any;
  indexvalue = 'ffwi';
  indexnow: string;
  hour: any;
  max: any;
  seconds: number;
  interv: any;
  bool = false;
  cont = 1;
  timer = 0;
  reset = false;
  resetbar = false;
  recount = false;
  /*position of marker*/
  position: any;
  lat: number;
  lon: number;
  /*Grafana url*/
  dangerousUrl: any;
  trustedUrl: any;
  panelId: number;
  period: string;
  /*buttons focus*/
  focused = 'ffwi';
  /*timer to refresh maps*/
  time: any;

async getUrl(check, exist): Promise<any> {

  return new Promise((resolve, reject) => {
    const olddate = new Date();
    olddate.setDate(olddate.getDate() - 1);
    const old = olddate.getFullYear() + '' + ('0' + (olddate.getMonth() + 1)).slice(-2) + '' + ('0' + (olddate.getDate())).slice(-2);
    let date = new Date().toJSON('yyyy/MM/dd').split('T')[0].replace(/-/g, '');
    const url = `${PYOPHIDIA_EXIST_URL}` + date;
    this.http.get(url).subscribe(
      response => {
        this.urlexist = response[`exist`];
        if (!this.urlexist) {
          date = old;
        }
        if (check && exist) {
          clearInterval(this.time);
          this.mapdate = date;
          this.overlay = initMap(this.googleMap, this.mapdate, this.indexvalue, 1, false);
          this.time = setInterval(() => {
            this.getUrl(false, this.urlexist);
          }, 86400000);
        }
        if (check && !exist) {
          clearInterval(this.time);
          this.time = setInterval(() => {
            this.getUrl(false, this.urlexist);
          }, 3600000);
        }
        if (this.urlexist !== exist) {
          clearInterval(this.time);
          if (this.urlexist) {
            this.time = setInterval(() => {
              this.getUrl(false, this.urlexist);
            }, 86400000);
          }
          else {
            this.time = setInterval(() => {
              this.getUrl(false, this.urlexist);
            }, 3600000);
          }
        }
        console.log([date, this.urlexist]);
        resolve([date, this.urlexist]);
      }, error => {
        console.log('error');
      }
    );
  });
}
  /*change fire danger index initial map*/
changeMap(value){
    initMap(this.googleMap, this.mapdate, value, 1, false);
    this.indexvalue = value;
    if (this.focused !== value) {
      document.getElementById(this.focused).style.borderWidth = '1px';
      document.getElementById(value).style.borderWidth = '2px';
      this.focused = value;
    }
    this.hour = 1;
    this.day = new Date();
    this.date = this.day.toJSON('yyyy/MM/dd').split('T')[0];
    this.timer = 0;
    switch (this.indexvalue) {
      case 'ffwi':
        this.max = 3;
        document.getElementById('mat-radio-buttons').style.display = 'block';
        if (this.period === 'week') {this.panelId = 4; }
        else { this.panelId = 7; }
        break;
      case 'fwi':
        this.max = 3;
        document.getElementById('mat-radio-buttons').style.display = 'block';
        if (this.period === 'week') {this.panelId = 5; }
        else { this.panelId = 8; }
        break;
      case 'ifi':
        this.max = 3;
        document.getElementById('mat-radio-buttons').style.display = 'block';
        if (this.period === 'week') {this.panelId = 6; }
        else { this.panelId = 9; }
        break;
      case 'wind':
        this.max = 73;
        document.getElementById('mat-radio-buttons').style.display = 'none';
        this.panelId = 14;
        break;
      case 'tp':
        this.max = 73;
        document.getElementById('mat-radio-buttons').style.display = 'none';
        this.panelId = 10;
        break;
      case 'rh':
        this.max = 73;
        document.getElementById('mat-radio-buttons').style.display = 'none';
        this.panelId = 11;
        break;
      case 'temp':
        this.max = 73;
        document.getElementById('mat-radio-buttons').style.display = 'none';
        this.panelId = 12;
        break;
      case 'swf':
        this.max = 73;
        document.getElementById('mat-radio-buttons').style.display = 'none';
        this.panelId = 13;
        break;
    }
    this.dangerousUrl = `${PYOPHIDIA_DASHBOARD_URL}` + '&var-lat=' + this.lat + '&var-lon=' + this.lon + '&panelId=' + this.panelId;
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
  }

  /*change fire danger index map on time*/
changeHour(){
    if (this.reset) {
      this.day = new Date();
      this.day.setDate(this.day.getDate() - 1);
      this.date = this.day.toJSON('yyyy/MM/dd').split('T')[0];
      this.hour = 0;
      this.timer = 0;
      this.reset = false;
    }
    if (this.indexvalue === 'ffwi' || this.indexvalue === 'fwi' || this.indexvalue === 'ifi') {
      this.max = 3;
      if (this.hour === this.max) {
        this.resetbar = false;
        this.hour = 1;
        initMap(this.googleMap, this.mapdate, this.indexvalue, this.hour, false);
        this.day = new Date();
        this.date = this.day.toJSON('yyyy/MM/dd').split('T')[0];
      } else {
        this.resetbar = true;
        this.hour = this.hour + 1;
        initMap(this.googleMap, this.mapdate, this.indexvalue, this.hour, false);
        this.day.setDate(this.day.getDate() + 1);
        this.date = this.day.toJSON('yyyy/MM/dd').split('T')[0];
      }
    } else {
      this.max = 73;
      if (this.hour === this.max) {
        this.resetbar = false;
        this.hour = 1;
        this.day = new Date();
        this.date = this.day.toJSON('yyyy/MM/dd').split('T')[0];
        initMap(this.googleMap, this.mapdate, this.indexvalue, this.hour, false);
      } else {
        this.resetbar = true;
        this.hour = this.hour + 1;
        initMap(this.googleMap, this.mapdate, this.indexvalue, this.hour, false);
        switch (true) {
          case (this.hour > 24 && this.hour <= 48):
            this.timer = this.hour - 25;
            this.date = this.firstday.toJSON('yyyy/MM/dd').split('T')[0];
            break;
          case (this.hour > 48 && this.hour <= 72):
            this.timer = this.hour - 49;
            this.date = this.secondday.toJSON('yyyy/MM/dd').split('T')[0];
            break;
          case (this.hour > 72):
            this.timer = this.hour - 73;
            this.date = this.thirdday.toJSON('yyyy/MM/dd').split('T')[0];
            break;
          default:
            this.timer = this.hour - 1;
            break;
        }
      }
    }
  }

  /*show the sequence on maps on time*/
startTimer(bool){
    this.bool = bool;
    if (bool){
      if (this.indexvalue !== this.indexnow) {
        this.cont = 1;
        this.timer = 0;
        this.day = new Date();
        this.date = this.day.toJSON('yyyy/MM/dd').split('T')[0];
        if (this.indexvalue === 'ffwi' || this.indexvalue === 'fwi' || this.indexvalue === 'ifi') {
          this.seconds = 3;
        } else {
          this.seconds = 73;
        }
        this.max = 100;
        this.hour = 100 / this.seconds;
      }
      if (this.resetbar && this.indexvalue === this.indexnow || this.recount) {
        this.cont = 1;
        this.timer = 0;
        this.day = new Date();
        this.date = this.day.toJSON('yyyy/MM/dd').split('T')[0];
        if (this.indexvalue === 'ffwi' || this.indexvalue === 'fwi' || this.indexvalue === 'ifi') {
          this.seconds = 3;
        } else {
          this.seconds = 73;
        }
        this.max = 100;
        this.hour = 100 / this.seconds;
        this.resetbar = false;
        this.recount = false;
      }
      this.interv = setInterval(() => {
        this.cont = this.cont + 1;
        if (this.indexvalue === 'ffwi' || this.indexvalue === 'fwi' || this.indexvalue === 'ifi') {
          this.day.setDate(this.day.getDate() + 1);
          this.date = this.day.toJSON('yyyy/MM/dd').split('T')[0];
        } else {
          switch (true) {
            case (this.cont > 24 && this.cont <= 48):
              this.timer = this.cont - 25;
              this.date = this.firstday.toJSON('yyyy/MM/dd').split('T')[0];
              break;
            case (this.cont > 48 && this.cont <= 72):
              this.timer = this.cont - 49;
              this.date = this.secondday.toJSON('yyyy/MM/dd').split('T')[0];
              break;
            case (this.cont > 72):
              this.timer = this.cont - 73;
              this.date = this.thirdday.toJSON('yyyy/MM/dd').split('T')[0];
              break;
            default:
              this.timer = this.cont - 1;
              break;
          }
        }
        if (this.cont === this.seconds) {
          this.reset = true;
          this.bool = false;
          this.recount = true;
          clearInterval(this.interv);
        }
        initMap(this.googleMap, this.mapdate, this.indexvalue, this.cont, false);
        this.hour = this.cont * 100 / this.seconds;
      }, 1000);
    }
    else {
      this.cont = 1;
      this.timer = 0;
      this.indexnow = this.indexvalue;
      this.reset = true;
      this.hour = 100 / this.seconds;
      clearInterval(this.interv);
      this.day = new Date();
      this.date = this.day.toJSON('yyyy/MM/dd').split('T')[0];
    }
  }
  /*insert a marker on click*/
placeMarker(event){
    this.marker.setPosition(event.latLng);
    this.lat = event.latLng.lat();
    this.lon = event.latLng.lng();
    switch (this.indexvalue) {
      case 'ffwi':
        if (this.period === 'week') {this.panelId = 4; }
        else { this.panelId = 7; }
        break;
      case 'fwi':
        if (this.period === 'week') {this.panelId = 5; }
        else { this.panelId = 8; }
        break;
      case 'ifi':
        if (this.period === 'week') {this.panelId = 6; }
        else { this.panelId = 9; }
        break;
      case 'wind':
        this.panelId = 14;
        break;
      case 'tp':
        this.panelId = 10;
        break;
      case 'rh':
        this.panelId = 11;
        break;
      case 'temp':
        this.panelId = 12;
        break;
      case 'swf':
        this.panelId = 13;
        break;
    }
    this.dangerousUrl = `${PYOPHIDIA_DASHBOARD_URL}` + '&var-lat=' + this.lat + '&var-lon=' + this.lon + '&panelId=' + this.panelId;
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
  }
  /*choosing between last week or last month*/
changePeriod(event){
    if (event.value === '1')
    {
      this.period = 'week';
    }
    else {
      this.period = 'month';
    }
    switch (this.indexvalue) {
      case 'ffwi':
        if (this.period === 'week') {this.panelId = 4; }
        else { this.panelId = 7; }
        break;
      case 'fwi':
        if (this.period === 'week') {this.panelId = 5; }
        else { this.panelId = 8; }
        break;
      case 'ifi':
        if (this.period === 'week') {this.panelId = 6; }
        else { this.panelId = 9; }
        break;
      default:
        if (this.period === 'week') {this.panelId = 4; }
        else { this.panelId = 7; }
        break;
    }
    this.dangerousUrl = `${PYOPHIDIA_DASHBOARD_URL}` + '&var-lat=' + this.lat + '&var-lon=' + this.lon + '&panelId=' + this.panelId;
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
  }

  /*function to execute refreshing of maps everyday at 10:00 a.m.*/
async callEveryDay(){
    console.log('refresh map');
    const nextdate: any = new Date();
    let thisdate: any;
    let difference: any;
    if (nextdate.getHours() === 10 && nextdate.getMinutes() === 0 && nextdate.getSeconds() === 0) { // You can check for seconds here too
      this.arraydate = await this.getUrl(true, this.existmap);
      this.mapdate = this.arraydate[0];
      this.existmap = this.arraydate[1];
    } else {
      if (this.existmap) {
        nextdate.setDate(nextdate.getDate() + 1);
        nextdate.setHours(10);
        nextdate.setMinutes(0);
        nextdate.setSeconds(0);
      }
      else {
        nextdate.setHours(nextdate.getHours() + 1);
        nextdate.setMinutes(0);
        nextdate.setSeconds(0);
      }
      thisdate = new Date();
      difference = nextdate - thisdate;
      setTimeout(async function() {
        this.arraydate = await this.getUrl(true, this.existmap);
        this.mapdate = this.arraydate[0];
        this.existmap = this.arraydate[1];
        console.log(this.existmap);
      }.bind(this), difference);
    }
  }
  /*open Info on buttons*/
openDialog(param, description): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '250px',
      data: {param, description}
    });
  }
async ngOnInit(){
    document.getElementById(this.focused).style.borderWidth = '2px';
    this.googleMap = new google.maps.Map(document.getElementById('map'), this.mapOptions);
    this.marker = new google.maps.Marker({
      position: this.myCenter,
      map: this.googleMap
    });
    /*setting initial graph*/
    // document.getElementById('mat-radio-buttons').style.display = 'block';
    this.period = 'week';
    this.lat = this.myCenter.lat();
    this.lon = this.myCenter.lng();
    this.dangerousUrl = `${PYOPHIDIA_DASHBOARD_URL}` + '&var-lat=' + this.lat + '&var-lon=' + this.lon + '&panelId=4';
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
    /*setting days for each index*/
    this.day = new Date();
    this.firstday = new Date();
    this.secondday = new Date();
    this.thirdday = new Date();
    this.date = this.day.toJSON('yyyy/MM/dd').split('T')[0];
    this.firstday.setDate(this.day.getDate() + 1);
    this.secondday.setDate(this.day.getDate() + 2);
    this.thirdday.setDate(this.day.getDate() + 3);
    /*verify if map exists*/
    this.arraydate = await this.getUrl(false, false);
    console.log(this.arraydate);
    this.mapdate = this.arraydate[0];
    this.existmap = this.arraydate[1];
    this.callEveryDay();
    /*set the initial map*/
    this.overlay = initMap(this.googleMap, this.mapdate, 'ffwi', 1, true);
    google.maps.event.addListener(this.overlay, 'click', (event) => {
      this.placeMarker(event);
    });
    this.indexvalue = 'ffwi';
    this.hour = 1;
    this.max = 3;
  }
}
@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'infodialogs.html',
})
export class InfoDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
}
