import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  ALARM_URL, DASHBOARD_URL,
  INFO_BY_SERIAL_NUMBER_URL, INFO_BY_TTFIRE_URL, MARKER_COLOR_URL,
  SENSORS_INFO_URL, SENSORS_NUMBER_FOR_SITE_URL,
  SENSORS_NUMBER_URL, SITE_PREALARM_HOURLY_URL,
  SITES_URL,
  TOTAL_MEASURES_URL
} from '../../env';
import MapTypeId = google.maps.MapTypeId;
import {LocalStorage} from '@ngx-pwa/local-storage';
import {ActivatedRoute, Router} from '@angular/router';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import { DatePipe } from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {TokenStorageService} from '../../services/token-storage.service';
@Component({
  selector: 'app-sensor-monitoring-alarms',
  templateUrl: './sensor-monitoring-alarms.component.html',
  styleUrls: ['./sensor-monitoring-alarms.component.css']
})
export class SensorMonitoringAlarmsComponent implements OnInit, AfterViewInit {
  /*role is admin or park manager or operator*/
  role: any;
  usersite: any;
  bool: boolean;
  /*grafana variables*/
  aggregationprealarm: any;
  dashboardUrl: any;
  riskCesineUrl: any;
  trustedCesineUrl: any;
  riskCuturiUrl: any;
  trustedCuturiUrl: any;
  riskGaleoneUrl: any;
  trustedGaleoneUrl: any;
  riskUgentoUrl: any;
  trustedUgentoUrl: any;
  /*sites map zoom and center*/
  zoomCesine = 15.4;
  centerCesine = {
    lat: 40.350097,
    lng: 18.335247
  };
  zoomCuturi = 16;
  centerCuturi = {
    lat: 40.341504,
    lng: 17.658152
  };
  zoomGaleone = 16;
  centerGaleone = {
    lat: 40.7449527,
    lng: 17.20138972
  };
  zoomUgento = 17;
  centerUgento = {
    lat: 39.880536,
    lng: 18.134581
  };
  /*tt info*/
  serial: any;
  ttfire: any;
  latitude: any;
  longitude: any;
  lastupdate: any;
  markercolor: any;
  /*map values*/
  zoom = 8;
  type: google.maps.MapTypeId = MapTypeId.HYBRID;
  site: any;
  count: number; /*total number of sensors*/
  countCesine: number; /*total number of Cesine sensors*/
  countCuturi: number; /*total number of Cuturi sensors*/
  countGaleone: number; /*total number of Galeone sensors*/
  countUgento: number; /*total number of Ugento sensors*/
  /*variables for days since the start of the project*/
  projectstart: Date = new Date('2015-11-20T00:00:00Z');
  daytime: number;
  days: number; /*number of days from the beginning of the project*/
  area: number; /*monitored area*/
  totmeasures: number; /*total of measures*/
  public center = {
    lat: 40.34654412118006,
    lng: 18.171386718750004
  };
  public markersCesine = [];
  sensormarkerCesine: any;
  public markersCuturi = [];
  sensormarkerCuturi: any;
  public markersGaleone = [];
  sensormarkerGaleone: any;
  public markersUgento = [];
  sensormarkerUgento: any;
  /*hours ago to change marker color*/
  timebetweendates: any;
  hoursago: number;
  @ViewChild('Cesinemap') cesineMap;
  @ViewChild('Cuturimap') cuturiMap;
  @ViewChild('Galeonemap') galeoneMap;
  @ViewChild('Ugentomap') ugentoMap;
  @ViewChild(MapMarker, {static: false}) mapMarker: MapMarker;
  @ViewChild(MapInfoWindow, {static: false}) infoWindow: MapInfoWindow;
  private interval: any;
  // tslint:disable-next-line:max-line-length
  constructor(private http: HttpClient, private localStorage: LocalStorage, private tokenStorageService: TokenStorageService, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer) {}
  private static _handleError(err: HttpErrorResponse | any) {
    return Observable.throw(err.message || 'Error: Unable to complete request.');
  }

  public getJSON_sensorsnumber(): Observable<any> {
    if (this.role === 'ROLE_ADMIN' || this.role === 'ROLE_OPERATOR')
    {
      return this.http.get(`${SENSORS_NUMBER_URL}` );
    }
    else {
      return this.http.get(`${SENSORS_NUMBER_FOR_SITE_URL}` + '\'' + this.usersite + '\'');
    }
  }
  public getJSON_sensorsnumberforsite(value): Observable<any> {
    return this.http.get(`${SENSORS_NUMBER_FOR_SITE_URL}` + '\'' + value + '\'');
  }

  public getJSON_totmeasures(): Observable<any> {
    if (this.role === 'ROLE_ADMIN' || this.role === 'ROLE_OPERATOR')
    {
      return this.http.get(`${TOTAL_MEASURES_URL}` );
    }
    else {
      return this.http.get(`${TOTAL_MEASURES_URL}` + 'WHERE "site"=' + '\'' + this.usersite + '\'');
    }
  }

  public getJSON_sensorsinfo(value): Observable<any> {
    return this.http.get(`${SENSORS_INFO_URL}` + 'where "site"=' + '\'' + value + '\'');
  }

  public getJSON_infobysensor(value): Observable<any> {
    return this.http.get(`${INFO_BY_TTFIRE_URL}` + '\'' + value + '\'' );
  }
  public getJSON_markercolor(value): Observable<any> {
    return this.http.get(`${MARKER_COLOR_URL}` + '\'' + value + '\'');
  }

  public getJSON_sites(): Observable<any> {
    return this.http.get(`${SITES_URL}` );
  }

  public getJSON_alarm(value): Observable<any> {
    return this.http.get(`${ALARM_URL}` + '\'' + value + '\'' );
  }

  /*fixing the map center*/
  calculateCenter(marker: MapMarker, content: string, site: string){
    this.site = site;
    this.infoWindow.open(marker);
    this.ttfire = content;
    /*taking sensors info*/
    this.getJSON_infobysensor(content).subscribe(data => {
      this.serial = data[`results`][0][`series`][0][`values`][0][4];
      this.refreshMarkerColor();
      this.latitude = data[`results`][0][`series`][0][`values`][0][1];
      this.longitude = data[`results`][0][`series`][0][`values`][0][2];
      switch (site) {
        case 'Cesine': {
          this.zoomCesine = 25;
          this.centerCesine = {
            lat: data[`results`][0][`series`][0][`values`][0][1],
            lng: data[`results`][0][`series`][0][`values`][0][2]
          };
          break;
        }
        case 'Masseria Cuturi': {
          this.zoomCuturi = 25;
          this.centerCuturi = {
            lat: data[`results`][0][`series`][0][`values`][0][1],
            lng: data[`results`][0][`series`][0][`values`][0][2]
          };
          break;
        }
        case 'Masseria Galeone': {
          this.zoomGaleone = 25;
          this.centerGaleone = {
            lat: data[`results`][0][`series`][0][`values`][0][1],
            lng: data[`results`][0][`series`][0][`values`][0][2]
          };
          break;
        }
        case 'Ugento': {
          this.zoomUgento = 25;
          this.centerUgento = {
            lat: data[`results`][0][`series`][0][`values`][0][1],
            lng: data[`results`][0][`series`][0][`values`][0][2]
          };
          break;
        }
      }
    });
  }
  /*function to execute refreshing of data*/
  callEveryHour(){
    const nextdate: any = new Date();
    let thisdate: any;
    let difference: any;
    if (nextdate.getSeconds() === 0) { // You can check for seconds here too
      this.refreshData(true);
    } else {
      // this.nextDate.setHours(this.nextDate.getHours() + 1);
      nextdate.setMinutes(nextdate.getMinutes() + 1);
      nextdate.setSeconds(0);
      thisdate = new Date();
      difference = nextdate - thisdate;
      setTimeout(function() {
        this.refreshData(true);
      }.bind(this), difference);
    }
  }
  /*function to refresh marker color*/
  refreshMarkerColor(){
    this.getJSON_markercolor(this.serial).subscribe((data: any[]) => {
      if (data[`results`][0][`series`] !== undefined)
        {
          this.timebetweendates = new Date().getTime() - new Date(data[`results`][0][`series`][0][`values`][0][0]).getTime();
          this.hoursago = Math.ceil(this.timebetweendates / (1000 * 3600));
          this.lastupdate = this.datePipe.transform(new Date(data[`results`][0][`series`][0][`values`][0][0]), 'yyyy-MM-dd HH:mm:ss');
        }
        else {
          this.hoursago = 72;
        }
      if (this.hoursago <= 2){
        this.markercolor = '../../../assets/markers/green.png';
        }
        else {
          if (this.hoursago <= 48){
            this.markercolor = '../../../assets/markers/yellow.png';
          }
          else {
            this.markercolor = '../../../assets/markers/red.png';
          }
        }
      },
      err => {
        console.log('Error! ' + err.message);
      });
  }
  /*function to refresh general info of interface*/
  refreshData(check) {
    if (check) {
      setInterval(() => {
        this.refreshData(false);
      }, 60000);
    }
    /*taking the number of days*/
    this.projectstart = new Date('2018-04-01T00:00:00Z');
    this.daytime = new Date().getTime() - this.projectstart.getTime();
    this.days = Math.ceil(this.daytime / (1000 * 3600 * 24));
    /*taking the total of measures*/
    this.getJSON_totmeasures().subscribe(data => {
        this.totmeasures = data[`results`][0][`series`][0][`values`][0][1];
      },
      err => {
        console.log('Error! ' + err.message);
      });
  }

  detectAlarm(check){
    console.log('detect alarm');
    if (check) {
      setInterval(() => {
        this.detectAlarm(false);
      }, 10000);
    }
    for (let i = 0; i < this.countCesine; i++) {
      if (this.markersCesine[i] !== undefined){
        this.getJSON_alarm(this.markersCesine[i].title).subscribe((data: any[]) => {
            if (data[`results`][0][`series`] !== undefined)
            {
              this.markersCesine[i].options = { icon: {
                  url: '../../../assets/markers/blink-red.gif', scaledSize: {height: 60, width: 48}
                }, zIndex: 923,
                optimized: false };
            }
            else {
              this.markersCesine[i].options = { icon: {
                  url: '../../../assets/markers/green.png', scaledSize: {height: 28, width: 24}
                } };
            }
          },
          err => {
            console.log('Error! ' + err.message);
          });
      }
    }
    for (let i = 0; i < this.countCuturi; i++) {
      if (this.markersCuturi[i] !== undefined){
        this.getJSON_alarm(this.markersCuturi[i].title).subscribe((data: any[]) => {
            if (data[`results`][0][`series`] !== undefined)
            {
              this.markersCuturi[i].options = { icon: {
                  url: '../../../assets/markers/blink-red.gif', scaledSize: {height: 60, width: 48}
                }, zIndex: 923,
                optimized: false };
            }
            else {
              this.markersCuturi[i].options = { icon: {
                  url: '../../../assets/markers/green.png', scaledSize: {height: 28, width: 24}
                } };
            }
          },
          err => {
            console.log('Error! ' + err.message);
          });
      }
    }
    for (let i = 0; i < this.countGaleone; i++) {
      if (this.markersGaleone[i] !== undefined){
        this.getJSON_alarm(this.markersGaleone[i].title).subscribe((data: any[]) => {
            if (data[`results`][0][`series`] !== undefined)
            {
              this.markersGaleone[i].options = { icon: {
                  url: '../../../assets/markers/blink-red.gif', scaledSize: {height: 60, width: 48}
                }, zIndex: 923,
                optimized: false };
            }
            else {
              this.markersGaleone[i].options = { icon: {
                  url: '../../../assets/markers/green.png', scaledSize: {height: 28, width: 24}
                } };
            }
          },
          err => {
            console.log('Error! ' + err.message);
          });
      }
    }
    for (let i = 0; i < this.countUgento; i++) {
      if (this.markersUgento[i] !== undefined){
        this.getJSON_alarm(this.markersUgento[i].title).subscribe((data: any[]) => {
            if (data[`results`][0][`series`] !== undefined)
            {
              this.markersUgento[i].options = { icon: {
                  url: '../../../assets/markers/blink-red.gif', scaledSize: {height: 60, width: 48}
                }, zIndex: 923,
                optimized: false };
            }
            else {
              this.markersUgento[i].options = { icon: {
                  url: '../../../assets/markers/green.png', scaledSize: {height: 28, width: 24}
                } };
            }
          },
          err => {
            console.log('Error! ' + err.message);
          });
      }
    }
  }
  ngOnInit() {
    /*taking the role of user*/
    this.role = this.tokenStorageService.getUser().role;
    /*showing cameras for operator*/
    if (this.role === 'ROLE_OPERATOR') {
      document.getElementById('videosite').style.display = 'block';
    }
    else {
      document.getElementById('videosite').style.display = 'none';
    }
    this.usersite = this.tokenStorageService.getUser().site;
    /*taking the sensors number from json and the covered area*/
    this.getJSON_sensorsnumber().subscribe(data => {
        if (this.role === 'ROLE_ADMIN' || this.role === 'ROLE_OPERATOR')
        {
          this.count = data[`results`][0][`series`][0][`values`][0][0];
          document.getElementById('Cesine').style.display = 'block';
          document.getElementById('Masseria Cuturi').style.display = 'block';
          document.getElementById('Masseria Galeone').style.display = 'block';
          document.getElementById('Ugento').style.display = 'block';
          this.bool = true;
        }
        else {
          this.count = data[`results`][0][`series`][0][`values`][0][1];
          /*show only the site map of the park manager*/
          document.getElementById(this.usersite).style.display = 'block';
          document.getElementById('grid-container').style.gridTemplateColumns = 'auto';
          this.bool = true;
        }
        this.area = Math.round(this.count * 50 * 50 * Math.PI);
      },
      err => {
        console.log('Error! ' + err.message);
      });
    this.getJSON_sensorsnumberforsite('Cesine').subscribe(data => {
      this.countCesine = data[`results`][0][`series`][0][`values`][0][1];
      /*taking sensors info*/
      this.getJSON_sensorsinfo('Cesine').subscribe(siteData => {
        for (let i = 0; i < this.countCesine; i++){
          this.sensormarkerCesine = {
            position: {
              lat: siteData[`results`][0][`series`][0][`values`][i][1],
              lng: siteData[`results`][0][`series`][0][`values`][i][2],
            },
            title: siteData[`results`][0][`series`][0][`values`][i][5],
            options: { icon: {
                url: '../../../assets/markers/green.png', scaledSize: {height: 28, width: 24}
              } },
            info: siteData[`results`][0][`series`][0][`values`][i][3],
          };
          this.markersCesine.push(this.sensormarkerCesine);
        }
      });
    });
    this.getJSON_sensorsnumberforsite('Masseria Cuturi').subscribe(data => {
      this.countCuturi = data[`results`][0][`series`][0][`values`][0][1];
      /*taking sensors info*/
      this.getJSON_sensorsinfo('Masseria Cuturi').subscribe(siteData => {
        for (let i = 0; i < this.countCuturi; i++){
          this.sensormarkerCuturi = {
            position: {
              lat: siteData[`results`][0][`series`][0][`values`][i][1],
              lng: siteData[`results`][0][`series`][0][`values`][i][2],
            },
            title: siteData[`results`][0][`series`][0][`values`][i][5],
            options: { icon: {
                url: '../../../assets/markers/green.png', scaledSize: {height: 28, width: 24}
              } },
            info: siteData[`results`][0][`series`][0][`values`][i][3],
          };
          this.markersCuturi.push(this.sensormarkerCuturi);
        }
      });
    });
    this.getJSON_sensorsnumberforsite('Masseria Galeone').subscribe(data => {
      this.countGaleone = data[`results`][0][`series`][0][`values`][0][1];
      /*taking sensors info*/
      this.getJSON_sensorsinfo('Masseria Galeone').subscribe(siteData => {
        for (let i = 0; i < this.countGaleone; i++){
          this.sensormarkerGaleone = {
            position: {
              lat: siteData[`results`][0][`series`][0][`values`][i][1],
              lng: siteData[`results`][0][`series`][0][`values`][i][2],
            },
            title: siteData[`results`][0][`series`][0][`values`][i][5],
            options: { icon: {
                url: '../../../assets/markers/green.png', scaledSize: {height: 28, width: 24}
              } },
            info: siteData[`results`][0][`series`][0][`values`][i][3],
          };
          this.markersGaleone.push(this.sensormarkerGaleone);
        }
      });
    });
    this.getJSON_sensorsnumberforsite('Ugento').subscribe(data => {
      this.countUgento = data[`results`][0][`series`][0][`values`][0][1];
      /*taking sensors info*/
      this.getJSON_sensorsinfo('Ugento').subscribe(siteData => {
        for (let i = 0; i < this.countUgento; i++){
          this.sensormarkerUgento = {
            position: {
              lat: siteData[`results`][0][`series`][0][`values`][i][1],
              lng: siteData[`results`][0][`series`][0][`values`][i][2],
            },
            title: siteData[`results`][0][`series`][0][`values`][i][5],
            options: { icon: {
                url: '../../../assets/markers/green.png', scaledSize: {height: 28, width: 24}
              } },
            info: siteData[`results`][0][`series`][0][`values`][i][3],
          };
          this.markersUgento.push(this.sensormarkerUgento);
        }
      });
    });
    /*taking risk index*/
    this.dashboardUrl = `${DASHBOARD_URL}`;
    this.aggregationprealarm = `${SITE_PREALARM_HOURLY_URL}`;
    this.riskCesineUrl = this.dashboardUrl + this.aggregationprealarm + 'Cesine' + '&panelId=18';
    this.trustedCesineUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.riskCesineUrl);
    this.riskCuturiUrl = this.dashboardUrl + this.aggregationprealarm + 'Masseria%20Cuturi' + '&panelId=18';
    this.trustedCuturiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.riskCuturiUrl);
    this.riskGaleoneUrl = this.dashboardUrl + this.aggregationprealarm + 'Masseria%20Galeone' + '&panelId=18';
    this.trustedGaleoneUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.riskGaleoneUrl);
    this.riskUgentoUrl = this.dashboardUrl + this.aggregationprealarm + 'Ugento' + '&panelId=18';
    this.trustedUgentoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.riskUgentoUrl);
    this.refreshData(false);
    this.callEveryHour();
    /*detect alarms every 10 seconds*/
    this.detectAlarm(true);
  }
  ngAfterViewInit() {
    this.cesineMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('frameCes'));
    this.cuturiMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('frameCut'));
    this.galeoneMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('frameGal'));
    this.ugentoMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('frameUg'));
  }
  goToPageDetails(pageName: string): void {
    localStorage.setItem('serial', this.serial);
    localStorage.setItem('ttfire', this.ttfire);
    localStorage.setItem('latitude', this.latitude);
    localStorage.setItem('longitude', this.longitude);
    localStorage.setItem('site', this.site);
    localStorage.setItem('update', this.lastupdate);
    localStorage.setItem('color', this.markercolor);
    if (this.role === 'ROLE_OPERATOR' || this.role === 'ROLE_ADMIN') {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`../${pageName}`], { relativeTo: this.route })
      );
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([`../${pageName}`], { relativeTo: this.route }).then(() => {
        window.location.reload();
      });
    }
  }
  goToVideos(pageName: string): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`../${pageName}`], { relativeTo: this.route })
    );
    window.open(url, '_blank');
  }
}


