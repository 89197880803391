import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {WelcomeComponent} from './components/welcome/welcome.component';
import {HomeComponent} from './components/home/home.component';
import {SensormonitoringComponent} from './components/sensor-monitoring/sensormonitoring.component';
import {SensorMonitoringDetailsComponent} from './components/sensor-monitoring-details/sensor-monitoring-details.component';
import {BoardAdminComponent} from './components/board-admin/board-admin.component';
import {VideoCamerasComponent} from './components/video-cameras/video-cameras.component';
import {DronesComponent} from './components/drones/drones.component';
import {FireDangerComponent} from './components/fire-danger/fire-danger.component';
import {FireBehaviourComponent} from './components/fire-behaviour/fire-behaviour.component';
import {SensorMonitoringAlarmsComponent} from './components/sensor-monitoring-alarms/sensor-monitoring-alarms.component';
import {BoardUserComponent} from './components/board-user/board-user.component';
import {BoardParkmanagerComponent} from './components/board-parkmanager/board-parkmanager.component';
import {MenuComponent} from './components/menu/menu.component';
import {SiteMonitoringDetailsComponent} from './components/site-monitoring-details/site-monitoring-details.component';
import {FleetManagementItaComponent} from './components/fleet-management_ita/fleet-management.component';
import {MenuItaComponent} from './components/menu_ita/menu.component';
import {FireDangerItaComponent} from './components/fire-danger_ita/fire-danger.component';
import {SensormonitoringItaComponent} from './components/sensor-monitoring_ita/sensormonitoring.component';
import {SensorMonitoringAlarmsItaComponent} from './components/sensor-monitoring-alarms_ita/sensor-monitoring-alarms.component';
import {SensorMonitoringDetailsItaComponent} from './components/sensor-monitoring-details_ita/sensor-monitoring-details.component';
import {VideoCamerasItaComponent} from './components/video-cameras_ita/video-cameras.component';
import {SiteMonitoringDetailsItaComponent} from './components/site-monitoring-details_ita/site-monitoring-details.component';
import {DronesItaComponent} from './components/drones_ita/drones.component';
import {FireBehaviourItaComponent} from './components/fire-behaviour_ita/fire-behaviour.component';
import {CameraComponent} from './components/camera/camera.component';
import {CameraItaComponent} from './components/camera-ita/camera-ita.component';
import {FleetManagementComponent} from './components/fleet-management/fleet-management.component';
import {MeteoComponent} from './components/meteo/meteo.component';
import {MeteoItaComponent} from './components/meteo_ita/meteo.component';


const routes: Routes = [{ path: '', component: WelcomeComponent},
  {path: 'home', component: HomeComponent },
  { path: 'operator', component: BoardUserComponent, canActivate: [BoardUserComponent],
    children: [
      {path: 'alarms', component: SensorMonitoringAlarmsComponent },
      {path: 'alarms_ita', component: SensorMonitoringAlarmsItaComponent },
      {path: 'ttdetails', component: SensorMonitoringDetailsComponent },
      {path: 'ttdetails_ita', component: SensorMonitoringDetailsItaComponent },
      {path: 'videocameras', component: VideoCamerasComponent },
      {path: 'videocameras_ita', component: VideoCamerasItaComponent },
      { path: '', redirectTo: 'alarms_ita', pathMatch: 'full' },
    ]},
  { path: 'parkmanager', component: BoardParkmanagerComponent, canActivate: [BoardParkmanagerComponent],
    children: [
      { path: 'sensor', component: SensormonitoringComponent},
      {path: 'sensor_ita', component: SensormonitoringItaComponent },
      {path: 'alarms', component: SensorMonitoringAlarmsComponent },
      {path: 'alarms_ita', component: SensorMonitoringAlarmsItaComponent },
      {path: 'ttdetails', component: SensorMonitoringDetailsComponent },
      {path: 'ttdetails_ita', component: SensorMonitoringDetailsItaComponent },
      {path: 'sitedetails', component: SiteMonitoringDetailsComponent},
      {path: 'sitedetails_ita', component: SiteMonitoringDetailsItaComponent},
      {path: 'camera', component: CameraComponent },
      {path: 'camera_ita', component: CameraItaComponent },
      {path: 'cameraCuturi', component: CameraComponent },
      {path: 'cameraCuturi_ita', component: CameraItaComponent },
      {path: 'meteo', component: MeteoComponent },
      {path: 'meteo_ita', component: MeteoItaComponent },
      { path: '', redirectTo: 'sensor_ita', pathMatch: 'full' },
    ]},
  { path:  'admin', component:  BoardAdminComponent, canActivate: [BoardAdminComponent],
  children: [
    { path: 'menu', component: MenuComponent},
    { path: 'menu_ita', component: MenuItaComponent},
    { path: '', redirectTo: 'menu_ita', pathMatch: 'full' },
    { path: 'firedanger', component: FireDangerComponent, pathMatch: 'full' },
    { path: 'firedanger_ita', component: FireDangerItaComponent },
    {path: 'firebehaviour', component: FireBehaviourComponent },
    {path: 'firebehaviour_ita', component: FireBehaviourItaComponent },
    {path: 'sensor', component: SensormonitoringComponent },
    {path: 'sensor_ita', component: SensormonitoringItaComponent },
    {path: 'meteo', component: MeteoComponent },
    {path: 'meteo_ita', component: MeteoItaComponent },
    {path: 'alarms', component: SensorMonitoringAlarmsComponent },
    {path: 'alarms_ita', component: SensorMonitoringAlarmsItaComponent },
    {path: 'ttdetails', component: SensorMonitoringDetailsComponent },
    {path: 'ttdetails_ita', component: SensorMonitoringDetailsItaComponent },
    {path: 'sitedetails', component: SiteMonitoringDetailsComponent},
    {path: 'sitedetails_ita', component: SiteMonitoringDetailsItaComponent},
    {path: 'videocameras', component: VideoCamerasComponent },
    {path: 'videocameras_ita', component: VideoCamerasItaComponent },
    {path: 'drones', component: DronesComponent },
    {path: 'drones_ita', component: DronesItaComponent },
    {path: 'fleet', component: FleetManagementComponent },
    {path: 'fleet_ita', component: FleetManagementItaComponent },
]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [BoardAdminComponent, BoardParkmanagerComponent, BoardUserComponent],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// tslint:disable-next-line:max-line-length
export const routingComponents = [WelcomeComponent, HomeComponent, BoardAdminComponent, BoardParkmanagerComponent, BoardUserComponent, MenuComponent, MenuItaComponent, FireDangerComponent, FireDangerItaComponent, FireBehaviourComponent, FireBehaviourItaComponent, SensormonitoringComponent, SensormonitoringItaComponent, SensorMonitoringDetailsComponent, SensorMonitoringDetailsItaComponent, SensorMonitoringAlarmsComponent, SensorMonitoringAlarmsItaComponent, SiteMonitoringDetailsComponent, SiteMonitoringDetailsItaComponent, VideoCamerasComponent, VideoCamerasItaComponent, CameraComponent, CameraItaComponent, DronesComponent, DronesItaComponent, FleetManagementComponent, FleetManagementItaComponent];

