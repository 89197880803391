<div class="row">
  <div class="col-sm-12">
    <div class="container">
      <div *ngIf="loaded">
        <div id="map" [ngClass]="{'map': loaded}"></div>
        <div id="popup" class="ol-popup">
          <a href="#" id="popup-closer" class="ol-popup-closer"></a>
          <div id="popup-content"></div>
        </div>
      </div>
      <form class="select">
        <select name="options" id="select" (change)="changeMapZone($event)" style="height:2.5em;">
          <option value="Apulia">Apulia</option>
          <option value="Epiro">Epiro</option>
          <option value="All">All</option>
        </select>
      </form>
      <div class="list">
        <div class='legend-scale'>
          <div [ngSwitch]="indexvalue">
            <div *ngSwitchCase="'BurnProbability'">
              <img src="assets/legends/BP_legend.png" style="width: 140px">
            </div>
            <div *ngSwitchCase="'ConditionalFlameLength'">
              <img src="assets/legends/CFL_legend.png" style="width: 200px">
            </div>
            <div *ngSwitchCase="'FireSize'">
              <img src="assets/legends/FS_legend.png" style="width: 130px">
            </div>
            <div *ngSwitchCase="'FirePotentialIndex'">
              <img src="assets/legends/FPI_legend.png" style="width: 140px">
            </div>
            <div *ngSwitchCase="'CampbellAnalysis'">
              <img src="assets/legends/Campbell_legend.png" style="width: 140px">
            </div>
            <div *ngSwitchCase="'FlameLength'">
              <img src="assets/legends/FL_legend.png" style="width: 130px">
            </div>
            <div *ngSwitchCase="'RateOfSpread'">
              <img src="assets/legends/ROS_legend.png" style="width: 170px">
            </div>
            <div *ngSwitchCase="'FireIntensity'">
              <img src="assets/legends/FI_legend.png" style="width: 170px">
            </div>
            <div *ngSwitchCase="'SuppressionCapacity'">
              <img src="assets/legends/SupCapacity_legend.png" style="width: 320px">
            </div>
            <div *ngSwitchDefault>
              <img src="assets/legends/BP_legend.png" style="width: 140px">
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="margin-left: 4px">
        <mat-radio-group id="mat-radio-buttons" color="primary" aria-label="Select an option" (change)="changeMaps($event)">
          <mat-radio-button value="1" checked style="font-size: small;">Flammap</mat-radio-button>
          <mat-radio-button value="2" style="font-size: small;">WFA</mat-radio-button>
        </mat-radio-group>
      </div>
      <br>
      <div *ngIf="flammap" class="btn-group pull-left">
        <div class="boxbuttons" id="BurnProbability" style="margin-right: 4px; border-width: 2px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('BurnProbability')">Burn Probability</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Conditional Burn Probability ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="ConditionalFlameLength" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('ConditionalFlameLength')">Conditional Flame Length</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Conditional Flame Length ','is expressed in m')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="FireSize" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('FireSize')">Fire Size</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Fire Size ','is expressed in ha')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="FirePotentialIndex" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('FirePotentialIndex')">Fire Potential Index</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Fire Potential Index ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
      </div>
      <div *ngIf="!flammap" class="btn-group pull-left">
        <div class="boxbuttons" id="CampbellAnalysis" style="margin-right: 4px; border-width: 2px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('CampbellAnalysis')">Campbell Analysis</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Campbell Analysis ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="FlameLength" style="margin-right: 10px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('FlameLength')">Flame Length</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Flame Length ','is expressed in m')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="RateOfSpread" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('RateOfSpread')">Rate Of Spread</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Rate Of Spread ','is expressed in m/s')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="FireIntensity" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('FireIntensity')">Fire Intensity</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Fire Intensity ','is expressed in MW/m')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="SuppressionCapacity" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('SuppressionCapacity')">Suppression Capacity</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Suppression Capacity ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
      </div>
      <div id="info"></div>
    </div>
  </div>
</div>
<!--<div class="row">-->
<!--  <div class="col-sm-12">-->
<!--    <div class="container">-->
<!--      <div id="map"></div>-->
<!--      <div class="list">-->
<!--        <div class='legend-scale'>-->
<!--          <div [ngSwitch]="indexvalue">-->
<!--            <div *ngSwitchCase="'wind'">-->
<!--            </div>-->
<!--            <div *ngSwitchCase="'tp'">-->
<!--              <img src="assets/legends/tp.png" width="70" height="230">-->
<!--            </div>-->
<!--            <div *ngSwitchCase="'rh'">-->
<!--              <img src="assets/legends/rh.png" width="70" height="230">-->
<!--            </div>-->
<!--            <div *ngSwitchCase="'temp'">-->
<!--              <img src="assets/legends/t.png" width="70" height="230">-->
<!--            </div>-->
<!--            <div *ngSwitchCase="'swf'">-->
<!--              <img src="assets/legends/swf.png" width="70" height="230">-->
<!--            </div>-->
<!--            <div *ngSwitchDefault>-->
<!--              <img src="assets/legends/fireindices.png" width="80" height="230">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="btn-group pull-left">-->
<!--        <div class="boxbuttons" #1 id="ffwi" style="margin-right: 4px">-->
<!--          <button class="button1" [disabled]="bool" (click) = "changeMap('ffwi')">Campbell Analysis</button>-->
<!--          <button class="button2" [disabled]="bool" (click)="openDialog('Campbell Analysis ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>-->
<!--        </div>-->
<!--        <div class="boxbuttons" #3 id="ifi" style="margin-right: 10px">-->
<!--          <button class="button1" [disabled]="bool" (click) = "changeMap('ifi')">Flame Length</button>-->
<!--          <button class="button2" [disabled]="bool" (click)="openDialog('Flame Length ','is expressed in m')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>-->
<!--        </div>-->
<!--        <div class="boxbuttons" #5 id="tp" style="margin-right: 4px">-->
<!--          <button class="button1" [disabled]="bool" (click) = "changeMap('tp')">Rate Of Spread</button>-->
<!--          <button class="button2" [disabled]="bool" (click)="openDialog('Rate Of Spread ','is expressed in m/s')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>-->
<!--        </div>-->
<!--        <div class="boxbuttons" #7 id="temp" style="margin-right: 4px">-->
<!--          <button class="button1" [disabled]="bool" (click) = "changeMap('temp')">Fire Intensity</button>-->
<!--          <button class="button2" [disabled]="bool" (click)="openDialog('Fire Intensity ','is expressed in MW/m')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>-->
<!--        </div>-->
<!--      </div>-->
<!--      <progress class="bar pull-left" [value]="hour" [max]="max" style="width: 200px; height: 30px; margin-right: 20px"></progress>-->
<!--      <button id = "forward" type="button" class="btn btn-default btn-circle" [disabled]="bool" (click) = "changeHour()" style="margin-top: 0px; margin-right: 20px"><i class="fa fa-forward"></i></button>-->
<!--      <button id = "play" type="button" class="btn btn-default btn-circle" [disabled]="bool" (click) = "startTimer(true)" style="margin-top: 0px; margin-right: 4px"><i class="fa fa-play"></i></button>-->
<!--      <button id = "stop" type="button" class="btn btn-default btn-circle" [disabled]="!bool" (click) = "startTimer(false)" style="margin-top: 0px; margin-right: 10px"><i class="fa fa-stop"></i></button>-->
<!--      <label style="margin-right: 50px">{{date}} {{timer}}:00</label>-->
<!--      <mat-radio-group id="mat-radio-buttons" color="primary" aria-label="Select an option" (change)="changePeriod($event)">-->
<!--        <mat-radio-button value="1" checked style="font-size: small;">Last week</mat-radio-button>-->
<!--        <mat-radio-button value="2" style="font-size: small;">Last month</mat-radio-button>-->
<!--      </mat-radio-group>-->
<!--      <iframe [src]="trustedUrl" style="margin-top: 15px" width="100%" height="250" frameborder="0"></iframe>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
