<div class="container-info">
  <div class="grid-container">
  <div class="row">
        <div class="col-sm-3 text-center">
          <h4>{{count}} sensors present on the territory </h4>
        </div>
        <div class="col-sm-3 text-center">
          <h4>{{days}} days since the start of the project</h4>
        </div>
        <div class="col-sm-3 text-center">
          <h4>{{area}} m<sup>2</sup> monitored</h4>
        </div>
        <div class="col-sm-3 text-center">
          <h4>{{totmeasures}} total measurements</h4>
        </div>
      </div>
  </div>
</div>
<br>
  <div class="row">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <h3 class="title">SENSORS MAP</h3>
        </div>
        <div class="col-md-2 col-sm-3 clearfix map-legend">
          <img src="../../../assets/markers/green.png" class="img-responsive pull-left map-legend-icon">
          <h4>No alarm</h4>
        </div>
        <div class="col-md-2 col-sm-3 clearfix map-legend">
          <img id="blink" src="../../../assets/markers/red.png" class="img-responsive pull-left map-legend-icon">
          <h4>Danger alarm</h4>
        </div>
      </div>
      <div class="container">
        <button id="videosite" class="video" (click)="goToVideos('videocameras')" style="display: none"><i class="fa fa-video-camera"></i></button>
        <div id="grid-container" class="grid-container">
          <div id="Cesine" class="grid-item" style="display: none">
            <label style="font-size: x-large" *ngIf="bool">Cesine</label>
            <google-map #Cesinemap width="100%" *ngIf="markersCesine; else stillLoading"
              [zoom]="zoomCesine"
              [center]="centerCesine"
              [mapTypeId]= "type">
              <map-marker
                #markerElem
                *ngFor="let marker of markersCesine"
                [position]="marker.position"
                [title]="marker.title"
                [options]="marker.options"
                (mapClick)="calculateCenter(markerElem, marker.title, marker.info)"
              >
              </map-marker>
              <map-info-window>
                <h5>SERIAL: {{ serial }} - {{ttfire}}</h5>
                <button class="btn details" align="center" (click)="goToPageDetails('ttdetails')"><i class="fa fa-binoculars"></i> Historical and Details</button>
              </map-info-window>
            </google-map>
            <iframe id="frameCes" [src]="trustedCesineUrl" width="170" height="120" frameborder="0"></iframe>
            <ng-template #stillLoading><p>Loading...</p></ng-template>
          </div>
          <div id="Masseria Cuturi" class="grid-item" style="display: none">
            <label style="font-size: x-large" *ngIf="bool">Masseria Cuturi</label>
            <google-map #Cuturimap width="100%" *ngIf="markersCuturi; else stillLoading"
              [zoom]="zoomCuturi"
              [center]="centerCuturi"
              [mapTypeId]= "type">
              <map-marker
                #markerElem
                *ngFor="let marker of markersCuturi"
                [position]="marker.position"
                [title]="marker.title"
                [options]="marker.options"
                (mapClick)="calculateCenter(markerElem, marker.title, marker.info)"
              >
              </map-marker>
              <map-info-window>
                <h5>SERIAL: {{ serial }} - {{ttfire}}</h5>
                <button class="btn details" align="center" (click)="goToPageDetails('ttdetails')"><i class="fa fa-binoculars"></i> Historical and Details</button>
              </map-info-window>
            </google-map>
            <iframe id="frameCut" [src]="trustedCuturiUrl" width="170" height="120" frameborder="0"></iframe>
            <ng-template #stillLoading><p>Loading...</p></ng-template>
          </div>
          <div id="Masseria Galeone" class="grid-item" style="display: none">
            <label style="font-size: x-large" *ngIf="bool">Masseria Galeone</label>
            <google-map #Galeonemap width="100%" *ngIf="markersGaleone; else stillLoading"
              [zoom]="zoomGaleone"
              [center]="centerGaleone"
              [mapTypeId]= "type">
              <map-marker
                #markerElem
                *ngFor="let marker of markersGaleone"
                [position]="marker.position"
                [title]="marker.title"
                [options]="marker.options"
                (mapClick)="calculateCenter(markerElem, marker.title, marker.info)"
              >
              </map-marker>
              <map-info-window>
                <h5>SERIAL: {{ serial }} - {{ttfire}}</h5>
                <button class="btn details" align="center" (click)="goToPageDetails('ttdetails')"><i class="fa fa-binoculars"></i> Historical and Details</button>
              </map-info-window>
            </google-map>
            <iframe id="frameGal" [src]="trustedGaleoneUrl" width="170" height="120" frameborder="0"></iframe>
            <ng-template #stillLoading><p>Loading...</p></ng-template>
          </div>
          <div id="Ugento" class="grid-item" style="display: none">
            <label style="font-size: x-large" *ngIf="bool">Ugento</label>
            <google-map #Ugentomap width="100%" *ngIf="markersUgento; else stillLoading"
              [zoom]="zoomUgento"
              [center]="centerUgento"
              [mapTypeId]= "type">
              <map-marker
                #markerElem
                *ngFor="let marker of markersUgento"
                [position]="marker.position"
                [title]="marker.title"
                [options]="marker.options"
                (mapClick)="calculateCenter(markerElem, marker.title, marker.info)"
              >
              </map-marker>
              <map-info-window>
              <h5>SERIAL: {{ serial }} - {{ttfire}}</h5>
              <button class="btn details" align="center" (click)="goToPageDetails('ttdetails')"><i class="fa fa-binoculars"></i> Historical and Details</button>
              </map-info-window>
            </google-map>
            <iframe id="frameUg" [src]="trustedUgentoUrl" width="170" height="120" frameborder="0"></iframe>
            <ng-template #stillLoading><p>Loading...</p></ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

