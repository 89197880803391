<div class="container-info">
  <div class="grid-container">
    <div class="row">
        <div class="col-sm-3 text-center">
          <h4>{{count}} sensors present on the territory </h4>
        </div>
        <div class="col-sm-3 text-center">
          <h4>{{days}} days since the start of the project</h4>
        </div>
        <div class="col-sm-3 text-center">
          <h4>{{area}} m<sup>2</sup> monitored</h4>
        </div>
        <div class="col-sm-3 text-center">
          <h4>{{totmeasures}} total measurements</h4>
        </div>
      </div>
  </div>
</div>
<br>
  <div class="row">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <h3 class="title">SENSORS MAP</h3>
        </div>
        <div class="col-md-2 col-sm-3 clearfix map-legend">
          <img src="../../../assets/markers/green.png" class="img-responsive pull-left map-legend-icon">
          <h4>Less than 2 hours ago</h4>
        </div>
        <div class="col-md-2 col-sm-3 clearfix map-legend">
          <img src="../../../assets/markers/yellow.png" class="img-responsive pull-left map-legend-icon">
          <h4>Less than 2 days ago</h4>
        </div>
        <div class="col-md-2 col-sm-3 clearfix map-legend">
          <img src="../../../assets/markers/red.png" class="img-responsive pull-left map-legend-icon">
          <h4>Not updated</h4>
        </div>
      </div>
      <div class="container">
        <!--(zoomChanged)="calculateCenter()"-->
        <div class="content" role="main">
          <div class="card">
            <div class="form-group">
              <input type="text" class="fa" id="search-text" aria-describedby="search-text"
                     [(ngModel)]="searchText" placeholder="&#xf002; Search a TreeTalker" (click)="searchTT()" (mouseleave)="notsearchTT()"
                     autofocus>
            </div>
          </div>
        </div>
        <button id="binoc" class="binoculars" title="View site details" (click)="goToPageSiteDetails('sitedetails')"><i class="fa fa-binoculars pull-left"></i></button>
        <mat-form-field  id="selectfields" class="pull-left" appearance="outline">
          <mat-select #select placeholder="Ofidia Sensors" (selectionChange)="findMarker($event)">
            <mat-option *ngFor="let serialnumber of sortedserialnumbers | appFilter: searchText" value="{{ serialnumber }}">
              {{ serialnumber }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <google-map #googlemap *ngIf="loaded; else stillLoading"
          height="500px"
          width="100%"
          [zoom]="zoom"
          [center]="center"
        [mapTypeId]= "type">
          <map-marker
            #markerElem
            *ngFor="let marker of markers"
            [position]="marker.position"
            [label]="marker.label"
            [title]="marker.title"
            [options]="marker.options"
            (mapClick)="openInfo(markerElem, marker.title)"
          >
          </map-marker>
          <map-info-window>
            <h4>SERIAL: {{ infoContent }} - {{ ttfire }}</h4>
            <h5>Last update: {{ lastupdate }} ({{ minutesago }} minutes ago)</h5>
            <table style="width:100%">
              <tr>
                <td width="50%">Air Temperature</td>
                <td width="50%">{{ lasttemperature }} °C</td>
              </tr>
              <tr>
                <td width="50%">Relative Air Humidity</td>
                <td width="50%">{{ lasthumidity }} %</td>
              </tr>
              <tr>
                <td width="50%">Leaf Temperature</td>
                <td width="50%">{{ lastleaft }} °C</td>
              </tr>
              <tr>
                <td width="50%">Risk Index <i class="fa fa-info-circle fa-xs" title= "Risk Index is an hourly summary parameter that aims to provide generic information about the health of the plant. Risk index always ranges from 0 to 10: a value between 0 and 2 means low risk; a value between 2 and 5 means medium risk; a value between 5 and 8 means moderate risk; a value between 8 and 10 means high risk."></i></td>
                <td width="50%"> {{lastrisk}} - {{clusterrisk}} </td>
              </tr>
              <tr>
                <td width="50%"></td>
                <td width="50%">
                  <div class="progressbar">
                    <div id="risk width" class="progress-bar" role="progressbar" [attr.aria-valuenow]="lastrisk*10"
                         aria-valuemin="0" aria-valuemax="100">
                    </div>
                  </div>
                  <div class="start">0</div>
                  <div class="end">10</div>
                </td>
              </tr>
            </table>
              <br>
              <details>
                <summary>More...</summary>
                <br>
                <table style="width:100%">
                  <tr>
                    <td width="50%">Sap flow</td>
                    <td width="50%">{{ lastsapflow }} g/cm2 per hr</td>
                  </tr>
                  <tr>
                    <td width="50%">Stem water Content</td>
                    <td width="50%">{{ laststemh }} %</td>
                  </tr>
                  <tr>
                    <td width="50%">Growth Rate</td>
                    <td width="50%">{{ lastgrowth }} mm/year</td>
                  </tr>
                  <tr>
                    <td width="50%">Green-red ratio</td>
                    <td width="50%">{{ lastgr }} </td>
                  </tr>
                  <tr>
                    <td width="50%">NDV Index</td>
                    <td width="50%">{{ lastndvi }} </td>
                  </tr>
                  <tr>
                    <td width="50%">Carbon Dioxide</td>
                    <td width="50%">{{ lastco2 }} µg/m3</td>
                  </tr>
                  <tr>
                    <td width="50%">Ozone</td>
                    <td width="50%">{{ lasto3 }} µg/m3</td>
                  </tr>
                  <tr>
                    <td width="50%">Atm particulate matter 2.5</td>
                    <td width="50%">{{ lastpm25 }} µg/m3</td>
                  </tr>
                  <tr>
                    <td width="50%">Atm particulate matter 10</td>
                    <td width="50%">{{ lastpm10 }} µg/m3</td>
                  </tr>
                  <tr>
                    <td width="50%">Flame flag</td>
                    <td width="50%">{{ lastflameflag }}</td>
                  </tr>
                  <tr>
                    <td width="50%">Flame pulses</td>
                    <td width="50%">{{ lastflamepulses }}</td>
                  </tr>
                  <tr>
                    <td width="50%">Trunk axis movement</td>
                    <td width="50%">{{ lasttam }} rad/s</td>
                  </tr>
                </table>
              </details>
            <button class="btn details" align="center" (click)="goToPageDetails('ttdetails')"><i class="fa fa-binoculars"></i> Historical and Details</button>
            </map-info-window>
        </google-map>
	<ng-template #stillLoading>
          <div class="container" width="100%" height="500px">
            <img src="../../../assets/icons/spinner-icon-gif.gif" class="center" width="128" height="128" alt=""/>
          </div>
        </ng-template>
        <form class="list">
          <select name="serial numbers" id="serial number" (change)="findMarker($event)" style="height:2.5em;">
            <option value="{{ usersite }}">{{ usersite }}</option>
            <option *ngFor="let site of sites" value="{{ site }}">{{ site }}</option>
          </select>
        </form>
        <button class="alarms" (click)="goToPageAlarms('alarms')">Alarms <i class="fa fa-exclamation-triangle"></i></button>
        <button id="videosite" class="video" (click)="goToCameras('camera')"><i class="fa fa-video-camera"></i></button>
        <button id="Cesinestation" class="station" (click)="goToCesineStation('meteo')"><i class="fa fa-thermometer-empty"></i></button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-left">
      <h3 class="title">PRE-ALARM PARAMETERS IN THE LAST MONTH </h3>
      <div class="average box">
        <div class="btn-group">
          <div class="boxbuttons" #2 id="sap_flow">
            <button class="button1" (click) = "toggleDisplay('sap_flow',2, 'prealarm')">Sap flow [g/cm2 per hr]</button>
            <button class="button2" (click)="openDialog('Sap flow ','is the movement of fluid in the roots, stems and branches of plants. Sap flow is typically measured in the sapwood portion of xylem of plants. The data are expressed in liters / hour.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #5 id="stem_hum">
            <button class="button1" (click) = "toggleDisplay('stem_hum',5, 'prealarm')">Stem water Content [%]</button>
            <button class="button2" (click)="openDialog('Stem water Content (StWC %) ','is the moisture content measurement and is expressed as frequency signal with the range of 7000 to 14000 Hz in green wood. The data are expressed in % of water volume per unit of wood volume.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #6 id="growth_rate">
            <button class="button1" (click) = "toggleDisplay('growth_rate',6, 'prealarm')">Growth Rate [mm/year]</button>
            <button class="button2" (click)="openDialog('Growth Rate ','is the cumulative stem radial growth measured by an infra-red pulsed distance sensor positioned a few centimeters away from the tree trunk’s surface and kept in place by a carbon fiber stick anchored in the xylem. The data are expressed in mm/year.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #8 id="ndvi">
            <button class="button1" (click) = "toggleDisplay('ndvi',8, 'prealarm')">NDVI</button>
            <button class="button2" (click)="openDialog('NDVI (Normalized Difference Vegetation Index) ','quantifies vegetation by measuring the difference between near-infrared (which vegetation strongly reflects) and red light (which vegetation absorbs). NDVI always ranges from -1 to +1.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #32 id="green_red">
            <button class="button1" (click) = "toggleDisplay('green_red',32, 'prealarm')">Green/Red</button>
            <button class="button2" (click)="openDialog('Green Red Ratio ','is highly related to green plant growth and biomass. It is the ratio between the response in the green band and the response in the red band.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #7 id="leaf_temp">
            <button class="button1" (click) = "toggleDisplay('leaf_temp',7, 'prealarm')">Leaf Temperature [°C]</button>
            <button class="button2" (click)="openDialog('Leaf temperature ','allows to detect the water status of the plant because, when leaf transpiration decreases, the temperature increases compared to that of the air. Leaf temperature values are expressed in °C.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #9 id="air_temp">
            <button class="button1" (click) = "toggleDisplay('air_temp',9, 'prealarm')">Air Temperature [°C]</button>
            <button class="button2" (click)="openDialog('Air temperature ','is meteorological data expressed in °C.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #10 id="air_hum">
            <button class="button1" (click) = "toggleDisplay('air_hum',10, 'prealarm')">Air Relative Humidity [%]</button>
            <button class="button2" (click)="openDialog('Air relative humidity ','is meteorological data expressed in %.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
        </div>
        <iframe id="prealarmDashboard" [src]="trustedUrl" width="100%" height="400" frameborder="0"></iframe>
        <canvas id="average_chart"></canvas>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-left">
      <h3 class="title">ALARM PARAMETERS IN THE LAST MONTH </h3>
      <div class="average box">
        <div class="btn-group">
          <div class="boxbuttons" #11 id="carbon_dioxide">
            <button class="button1" (click) = "toggleDisplay('carbon_dioxide',11, 'alarm')">Carbon Dioxide [µg/m3]</button>
            <button class="button2" (click)="openDialog('Carbon Dioxide (CO2) ','is a parameter of Gas concentration expressed in mg/m3.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #12 id="ozone">
            <button class="button1" (click) = "toggleDisplay('ozone',12, 'alarm')">Ozone [µg/m3]</button>
            <button class="button2" (click)="openDialog('Ozone (03) ','is a parameter of Gas concentration expressed in mg/m3.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #13 id="pm_2.5">
            <button class="button1" (click) = "toggleDisplay('pm_2.5',13, 'alarm')">Atmospheric particulate matter 2.5 [µg/m3]</button>
            <button class="button2" (click)="openDialog('Atmospheric particulate matter (PM 2.5) ','is a parameter of Gas concentration expressed in mg/m3.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #14 id="pm_10">
            <button class="button1" (click) = "toggleDisplay('pm_10',14, 'alarm')">Atmospheric particulate matter 10 [µg/m3]</button>
            <button class="button2" (click)="openDialog('Atmospheric particulate matter (PM 10) ','is a parameter of Gas concentration expressed in mg/m3.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #15 id="flame_pulses">
            <button class="button1" (click) = "toggleDisplay('flame_pulses',15, 'alarm')">Flame pulses</button>
            <button class="button2" (click)="openDialog('Flame pulses ','allows to detect the flame temperature (2000-3000 ° K) at a distance of hundred meters. It checks the presence of the ignition of a fire at distances typically of hundred meters.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #31 id="flame_flag">
            <button class="button1" (click) = "toggleDisplay('flame_flag',31, 'alarm')">Flame flag</button>
            <button class="button2" (click)="openDialog('Flame flag ','is a boolean value that is 1 if the flame presence is detected, 0 otherwise.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #16 id="trunk_mov">
            <button class="button1" (click) = "toggleDisplay('trunk_mov',16, 'alarm')">Tree trunk axis movement [rad/s]</button>
            <button class="button2" (click)="openDialog('Tree trunk axis movement ','allows to measure the real-time position of the tree with respect to the gravity vector (through a gyroscopic sensor). The data are expressed in rad/sec.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
        </div>
        <iframe id="alarmDashboard" [src]="trusted1Url" width="100%" height="400" frameborder="0"></iframe>
        <canvas id="average1_chart"></canvas>
      </div>
    </div>
  </div>
