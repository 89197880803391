import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../../services/token-storage.service';
import {ActivatedRoute, CanActivate, Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './board-admin.component.html',
  styleUrls: ['./board-admin.component.css']
})
export class BoardAdminComponent implements OnInit, CanActivate{
  urls: string[] = [];
  underline: string;
  menu = new Map<string, string>();
  content: any;
  role: any;
  selected: boolean;
  services: string;
  eng: string;
  ita: string;
  back: string;
  out: string;
  canActivate() {
    this.role = this.tokenStorageService.getUser().role;
    if (this.role === 'ROLE_ADMIN') {
      return true;
    }
    else { return false; }
  }
  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private route: ActivatedRoute, private tokenStorageService: TokenStorageService, private userService: UserService) { }
  ngOnInit(): void {
    console.log(this.router.url);
    this.menu.set('Ofidia 2 Servizi DSS', '/admin/menu_ita')
      .set('Indici di pericolosità potenziale degli incendi', '/admin/firedanger_ita')
      .set('Comportamento dell\'incendio', '/admin/firebehaviour_ita')
      .set('Monitoraggio sensori', '/admin/sensor_ita')
      .set('Allarmi sensori', '/admin/alarms_ita')
      .set('Dettagli sensore', '/admin/ttdetails_ita')
      .set('Stazioni Meteo', '/admin/meteo_ita')
      .set('Video Camere', '/admin/videocameras_ita')
      .set('Storico video dei droni', '/admin/drones_ita')
      .set('Gestione delle flotte', '/admin/fleet_ita')
      .set('Ofidia 2 DSS Services', '/admin/menu')
      .set('Fire Danger Indices', '/admin/firedanger')
      .set('Fire Behaviour', '/admin/firebehaviour')
      .set('Sensor Monitoring', '/admin/sensor')
      .set('Sensor Alarms', '/admin/alarms')
      .set('Cesine', '/admin/sitedetails')
      .set('Masseria Cuturi', '/admin/sitedetails')
      .set('Masseria Galeone', '/admin/sitedetails')
      .set('Ugento', '/admin/sitedetails')
      .set('Sensor Details', '/admin/ttdetails')
      .set('Weather Stations', '/admin/meteo')
      .set('Video Cameras', '/admin/videocameras')
      .set('Historical of drone videos', '/admin/drones')
      .set('Fleet Management', '/admin/fleet');
    if (this.router.url.substr(this.router.url.length - 3) === 'ita') {
      // italian language
      this.selected = false;
      this.services = 'Ofidia 2 Servizi DSS';
      this.eng = 'Inglese';
      this.ita = 'Italiano';
      this.back = 'Indietro';
      this.out = 'Esci';
      switch (this.router.url) {
        case '/admin/firedanger_ita': {
          this.underline = 'Indici di pericolosità potenziale degli incendi';
          this.urls.push('Indici di pericolosità potenziale degli incendi');
          break;
        }
        case '/admin/firebehaviour_ita': {
          this.underline = 'Comportamento dell\'incendio';
          this.urls.push('Comportamento dell\'incendio');
          break;
        }
        case '/admin/sensor_ita': {
          this.underline = 'Monitoraggio sensori';
          this.urls.push('Monitoraggio sensori', 'Allarmi sensori', 'Cesine', 'Masseria Cuturi', 'Masseria Galeone', 'Ugento');
          break;
        }
        case '/admin/alarms_ita': {
          this.underline = 'Allarmi sensori';
          this.urls.push('Monitoraggio sensori', 'Allarmi sensori', 'Cesine', 'Masseria Cuturi', 'Masseria Galeone', 'Ugento');
          break;
        }
        case '/admin/sitedetails_ita': {
          this.underline = localStorage.getItem('selectedSite');
          this.urls.push('Monitoraggio sensori', 'Allarmi sensori', 'Cesine', 'Masseria Cuturi', 'Masseria Galeone', 'Ugento');
          break;
        }
        case '/admin/ttdetails_ita': {
          this.underline = 'Dettagli sensore';
          this.urls.push('Monitoraggio sensori', 'Allarmi sensori', 'Cesine', 'Masseria Cuturi', 'Masseria Galeone', 'Ugento', 'Dettagli sensore');
          break;
        }
        case '/admin/meteo_ita': {
          this.underline = 'Stazioni Meteo';
          this.urls.push('Stazioni Meteo');
          break;
        }
        case '/admin/videocameras_ita': {
          this.underline = 'Video Camere';
          this.urls.push('Video Camere');
          break;
        }
        case '/admin/drones_ita': {
          this.underline = 'Storico video dei droni';
          this.urls.push('Storico video dei droni');
          break;
        }
        case '/admin/fleet_ita': {
          this.underline = 'Gestione delle flotte';
          this.urls.push('Gestione delle flotte');
          break;
        }
      }
    }
    else {
      // english language
      this.selected = true;
      this.services = 'Ofidia 2 DSS Services';
      this.eng = 'English';
      this.ita = 'Italian';
      this.back = 'Back';
      this.out = 'LogOut';
      switch (this.router.url) {
        case '/admin/firedanger': {
          this.underline = 'Fire Danger Indices';
          this.urls.push('Fire Danger Indices');
          break;
        }
        case '/admin/firebehaviour': {
          this.underline = 'Fire Behaviour';
          this.urls.push('Fire Behaviour');
          break;
        }
        case '/admin/sensor': {
          this.underline = 'Sensor Monitoring';
          this.urls.push('Sensor Monitoring', 'Sensor Alarms', 'Cesine', 'Masseria Cuturi', 'Masseria Galeone', 'Ugento');
          break;
        }
        case '/admin/alarms': {
          this.underline = 'Sensor Alarms';
          this.urls.push('Sensor Monitoring', 'Sensor Alarms', 'Cesine', 'Masseria Cuturi', 'Masseria Galeone', 'Ugento');
          break;
        }
        case '/admin/sitedetails': {
          this.underline = localStorage.getItem('selectedSite');
          this.urls.push('Sensor Monitoring', 'Sensor Alarms', 'Cesine', 'Masseria Cuturi', 'Masseria Galeone', 'Ugento');
          break;
        }
        case '/admin/ttdetails': {
          this.underline = 'Sensor Details';
          this.urls.push('Sensor Monitoring', 'Sensor Alarms', 'Cesine', 'Masseria Cuturi', 'Masseria Galeone', 'Ugento', 'Sensor Details');
          break;
        }
        case '/admin/meteo': {
          this.underline = 'Weather Stations';
          this.urls.push('Weather Stations');
          break;
        }
        case '/admin/videocameras': {
          this.underline = 'Video Cameras';
          this.urls.push('Video Cameras');
          break;
        }
        case '/admin/drones': {
          this.underline = 'Historical of drone videos';
          this.urls.push('Historical of drone videos');
          break;
        }
        case '/admin/fleet': {
          this.underline = 'Fleet Management';
          this.urls.push('Fleet Management');
          break;
        }
      }
    }
    document.getElementById('noprint').style.display = 'none';
    this.userService.getAdminBoard().subscribe(
      data => {
        document.getElementById('noprint').style.display = 'block';
        this.content = data;
      },
      err => {
        document.getElementById('noprint').style.display = 'none';
        this.content = JSON.parse(err.error).message;
      }
    );
  }
  navigate(url: string) {
    if (url === 'Cesine' || url === 'Masseria Cuturi' || url === 'Masseria Galeone' || url === 'Ugento') {
      localStorage.setItem('selectedSite', url);
      if (!this.selected) {
        this.router.navigate([`${this.menu.get(url)}` + '_ita'], { relativeTo: this.route }).then(() => {
          window.location.reload();
        });
      }
      else {
        this.router.navigate([`${this.menu.get(url)}`], { relativeTo: this.route }).then(() => {
          window.location.reload();
        });
      }
    }
    else {
      this.router.navigate([`${this.menu.get(url)}`], { relativeTo: this.route }).then(() => {
        window.location.reload();
      });
    }
  }
  changeLanguage(event: any) {
    if (event.target.value === 'Italian') {
      this.router.navigate([this.router.url + '_ita']).then(() => {
         window.location.reload();
      });
    }
    else {
      const path = this.router.url.slice(0, -4);
      this.router.navigate([path]).then(() => {
        window.location.reload();
      });
    }
  }
  goback(): void {
    if (this.selected) {
      if (this.router.url === '/admin/alarms' || this.router.url === '/admin/ttdetails' || this.router.url === '/admin/sitedetails') {
        this.router.navigate(['/admin/sensor']).then(() => {
          window.location.reload();
        });
      }
      else {
        this.router.navigate(['/admin/menu']).then(() => {
          window.location.reload();
        });
      }
    }
    else {
      if (this.router.url === '/admin/alarms_ita' || this.router.url === '/admin/ttdetails_ita' || this.router.url === '/admin/sitedetails_ita') {
        this.router.navigate(['/admin/sensor_ita']).then(() => {
          window.location.reload();
        });
      }
      else {
        this.router.navigate(['/admin/menu_ita']).then(() => {
          window.location.reload();
        });
      }
    }
  }
  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }
}

