/*login url*/
export const AUTH_API = 'http://vs.ofidia.eu:5000/login';
export const LOGIN_URL = 'http://vs.ofidia.eu:5000/';

export const API_URL = 'http://vs.ofidia.eu:8080';
export const SENSORS_NUMBER_URL = 'http://vs.ofidia.eu:8086/query?q=SHOW TAG VALUES CARDINALITY ON "metrics" from "sensors" WITH KEY = "tree_talker"';
export const SENSORS_NUMBER_FOR_SITE_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT count("site") FROM "metrics"."autogen"."sensors" where "site"=';
export const TOTAL_MEASURES_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT count("air_temperature") FROM "metrics"."autogen"."sensor_metrics"';
export const SENSORS_INFO_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT * FROM "metrics"."autogen"."sensors"';
export const SENSOR_UPDATE_URL = '%3BSELECT last("air_temperature") FROM "metrics"."autogen"."sensor_metrics" WHERE time > now()-3d AND time < now()';
// tslint:disable-next-line:max-line-length
export const INFO_BY_SERIAL_NUMBER_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT * FROM "metrics"."autogen"."sensors" where "tree_talker"=';
export const SITES_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT distinct "site" FROM "metrics"."autogen"."sensors"';
export const MARKER_COLOR_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT last("air_temperature") FROM "metrics"."autogen"."sensor_metrics" WHERE time > now()-3d AND time < now() AND "tree_talker"=';
export const MARKER_VALUES_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT last("air_temperature"), "rel_air_humidity", "leaf_temperature", "flame_flag", "flame_pulses", "gas_co2", "gas_o3", "gas_pm10", "gas_pm2.5", "green_red", "ndvi", "radial_growth", "risk_index", "sap_flow_density", "stem_humidity", "trunk_axis_movement" FROM "metrics"."autogen"."sensor_metrics"';
export const TOTAL_TTMEASURES_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT count("air_temperature") FROM "metrics"."autogen"."sensor_metrics"';
export const TOTAL_TTALARMS_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT count("alarm_code") FROM "metrics"."autogen"."fire_alarms"';
export const LAST_UPDATE_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT last("air_temperature") FROM "metrics"."autogen"."sensor_metrics" WHERE "tree_talker"=';
export const SITE_LAST_UPDATE_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT last("air_temperature") FROM "metrics"."autogen"."sensor_metrics" WHERE "site"=';
export const DASHBOARD_URL = 'http://vs.ofidia.eu:3000/d/';
export const PREALARM_DAILY_URL = 'qawdlAnGz/pre-alarm-parameters-dashboard?orgId=1';
export const PREALARM_HOURLY_URL = 'h8P5PzYMz/24h-pre-alarm-parameters-dashboard?orgId=1';
export const ALARM_DAILY_URL = 'opmfSMOGk/alarm-parameters-dashboard?orgId=1';
export const ALARM_HOURLY_URL = 'XFlpPkLGk/24h-alarm-parameters-dashboard?orgId=1';
export const SITE_PREALARM_DAILY_URL = 'IXK9q0-Mz/site-pre-alarm-parameters-dashboard?orgId=1&var-site=';
export const SITE_PREALARM_HOURLY_URL = 'g3Z2ukLMz/24h-site-pre-alarm-parameters-dashboard?orgId=1&var-site=';
export const SITE_ALARM_DAILY_URL = 'nfu9eA-Gk/site-alarm-parameters-dashboard?orgId=1&var-site=';
export const SITE_ALARM_HOURLY_URL = '06e-uzYMk/24h-site-alarm-parameters-dashboard?orgId=1&var-site=';
/*weather stations*/
export const WS_DAILY_URL = 'HnBfpMZnz/ws-dashboard?orgId=1';
export const STATION_VALUES_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT last("total_precipitation"), "precipitation_intensity", "temperature", "relative_humidity", "solar_radiation", "wind_direction", "wind_speed" FROM "metrics"."autogen"."weather_station_metrics"';
/*alarm detection*/
export const INFO_BY_TTFIRE_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT * FROM "metrics"."autogen"."sensors" where "tree_talker_fire"=';
export const ALARM_URL = 'http://vs.ofidia.eu:8086/query?q=SELECT last("alarm_code") FROM "metrics"."autogen"."fire_alarms" WHERE time > now()-15m AND time < now() AND "tree_talker_fire"=';
/*drones urls*/
export const DRONES_VIDEOS_ITA_URL = 'http://93.57.89.5:16090/droni.html';
export const DRONES_VIDEOS_URL = 'http://93.57.89.5:16090/drones.html';
/*fire danger urls*/
export const PYOPHIDIA_DASHBOARD_URL = 'http://vs.ofidia.eu:3000/d/aE79rV-Mz/json-dashboard?orgId=1';
export const PYOPHIDIA_EXIST_URL = 'http://vs.ofidia.eu:3003/pyophidia/urlexists?url=https://ophidialab.cmcc.it/ophidia/ofidia/';
export const FIRE_BEHAVIOUR_URL = 'http://vs.ofidia.eu:3003/pyophidia/fire_behaviour?param=';
/*web socket*/
export const WS_URL = 'http://vs.ofidia.eu:5001';
/*fleet management urls*/
export const FLEET_URL = 'http://vs.ofidia.eu:8085/';
export const DISTANCE_URL = 'http://vs.ofidia.eu:8085/computeDistance?team_idteam=';
export const LOGS_BY_TEAM_URL = 'http://vs.ofidia.eu:8085/getDailyLogsPerTeam?team_idteam=';
export const LOGS_BY_EVENT_URL = 'http://vs.ofidia.eu:8085/getLogsPerEvent?sinapsiid=';
export const MESSAGES_BY_TEAM_URL = 'http://vs.ofidia.eu:8085/getMessages?name=';
export const EVENT_BY_TEAM_URL = 'http://vs.ofidia.eu:8085/getEvent?team_idteam=';
