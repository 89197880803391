import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {CanActivate, Router} from '@angular/router';
import {TokenStorageService} from '../../services/token-storage.service';

@Component({
  selector: 'app-board-moderator',
  templateUrl: './board-parkmanager.component.html',
  styleUrls: ['./board-parkmanager.component.css']
})
export class BoardParkmanagerComponent implements OnInit, CanActivate {

  content: any;
  role: any;
  selected: boolean;
  services: string;
  eng: string;
  ita: string;
  back: string;
  out: string;
  canActivate() {
    this.role = this.tokenStorageService.getUser().role;
    if (this.role === 'ROLE_PARKMANAGER') {
      return true;
    }
    else { return false; }
  }

  constructor(private router: Router, private userService: UserService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    if (this.router.url.substr(this.router.url.length - 3) === 'ita') {
      // italian language
      this.selected = false;
      this.services = 'Ofidia 2 Servizi DSS';
      this.eng = 'Inglese';
      this.ita = 'Italiano';
      this.back = 'Indietro';
      this.out = 'Esci';
    }
    else {
      // english language
      this.selected = true;
      this.services = 'Ofidia 2 DSS Services';
      this.eng = 'English';
      this.ita = 'Italian';
      this.back = 'Back';
      this.out = 'LogOut';
    }
    console.log(this.router.url);
    document.getElementById('noprint').style.display = 'none';
    this.userService.getParkManagerBoard().subscribe(
      data => {
        document.getElementById('noprint').style.display = 'block';
        this.content = data;
      },
      err => {
        document.getElementById('noprint').style.display = 'none';
        this.content = JSON.parse(err.error).message;
      }
    );
  }
  changeLanguage(event: any) {
    if (event.target.value === 'Italian') {
      this.router.navigate([this.router.url + '_ita']).then(() => {
        window.location.reload();
      });
    }
    else {
      const path = this.router.url.slice(0, -4);
      this.router.navigate([path]).then(() => {
        window.location.reload();
      });
    }
  }
  goback(): void {
    if (this.router.url === '/parkmanager/alarms' || this.router.url === '/parkmanager/ttdetails' || this.router.url === '/parkmanager/sitedetails') {
      this.router.navigate(['/parkmanager/sensor']).then(() => {
        window.location.reload();
      });
    }
    else {
      this.router.navigate(['/parkmanager/sensor_ita']).then(() => {
        window.location.reload();
      });
    }
  }
  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }

}

