import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {LOGIN_URL} from '../env';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getPublicContent(): Observable<any> {
    return this.http.get(`${LOGIN_URL}` + 'unprotected', { responseType: 'text' });
  }

  getUserBoard(): Observable<any> {
    return this.http.get(`${LOGIN_URL}` + 'protected', { responseType: 'text' });
  }

  getParkManagerBoard(): Observable<any> {
    return this.http.get(`${LOGIN_URL}` + 'protected', { responseType: 'text' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(`${LOGIN_URL}` + 'protected', { responseType: 'text' });
  }
}
