<div class="menu indicator box">
  <div class="btn-group" style="width:100%">
    <button class="item" (click)="goToPage('firedanger')" style="width:25%">
      <img src="assets/icons/logo1.jpg" width="230" height="138" alt=""/>
      <p>Fire Danger Indices</p>
    </button>
    <button class="item" disabled (click)="goToPage('firebehaviour')" style="width:25%">
      <img src="assets/icons/logo2.jpg" width="230" height="138" alt=""/>
      <p>Fire Behaviour</p>
    </button>
    <button class="item" (click)="goToPage('sensor')" style="width:25%">
      <img src="assets/icons/logo3.jpg" width="230" height="138" alt=""/>
      <p>Sensor Monitoring</p>
    </button>
    <button class="item" (click)="goToPage('meteo')" style="width:25%">
      <img src="assets/icons/logo7.png" width="230" height="138" alt=""/>
      <p>Weather Stations</p>
    </button>
  </div>
  <div class="btn-group" style="width:100%">
    <button class="item" (click)="goToPage('videocameras')" style="width:33.3%">
      <img src="assets/icons/logo4.jpg" width="230" height="138" alt=""/>
      <p>Video Cameras</p>
    </button>
    <button class="item" (click)="goToPage('drones')" style="width:33.3%">
      <img src="assets/icons/logo5.jpg" width="230" height="138" alt=""/>
      <p>Historical of drone videos</p>
    </button>
    <button class="item" disabled (click)="goToPage('fleet')" style="width:33.3%">
      <img src="assets/icons/logo6.jpg" width="230" height="138" alt=""/>
      <p>Fleet Management</p>
    </button>
  </div>
</div>

