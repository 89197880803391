import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, interval} from 'rxjs';
import {
  INFO_BY_SERIAL_NUMBER_URL,
  SENSORS_INFO_URL,
  SENSORS_NUMBER_URL,
  SITES_URL,
  TOTAL_MEASURES_URL,
  SENSORS_NUMBER_FOR_SITE_URL,
  MARKER_COLOR_URL,
  SENSOR_UPDATE_URL,
  MARKER_VALUES_URL,
  DASHBOARD_URL,
  PREALARM_DAILY_URL, ALARM_DAILY_URL
} from '../../env';
import MapTypeId = google.maps.MapTypeId;
import {LocalStorage} from '@ngx-pwa/local-storage';
import {ActivatedRoute, Router} from '@angular/router';
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';
import { DatePipe } from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {TokenStorageService} from '../../services/token-storage.service';
import {HashTable} from 'angular-hashtable';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
export interface DialogData {
  param: string;
  description: string;
}
@Component({
  selector: 'app-sensormonitoring',
  templateUrl: './sensormonitoring.component.html',
  styleUrls: ['./sensormonitoring.component.css']
})
export class SensormonitoringItaComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  constructor(private http: HttpClient, private localStorage: LocalStorage, private router: Router, private route: ActivatedRoute, private tokenStorageService: TokenStorageService, private datePipe: DatePipe, private sanitizer: DomSanitizer, public dialog: MatDialog) {}
  /*role is admin or park manager or operator*/
  role: any;
  usersite: any;
  /*map values*/
  zoom: number;
  type: google.maps.MapTypeId = MapTypeId.HYBRID;
  prealarmvalue: any = 2;
  alarmvalue: any = 11;
  value2: any = 17;
  value3: any = 24;
  selectedValue: any;
  site: any;
  count: number; /*total number of sensors*/
  /*filter to search a TreeTalker*/
  searchText;
  searchedvalue: any;
  searchedsite = '';
  /*variable to focuse a button on click*/
  focusedprealarm = 'sap_flow';
  focusedalarm = 'carbon_dioxide';
  /*variables for days since the start of the project*/
  projectstart: Date = new Date('2015-11-20T00:00:00Z');
  daytime: number;
  days: number; /*number of days from the beginning of the project*/
  area: number; /*monitored area*/
  totmeasures: number; /*total of measures*/
  public center = {};
  public markers = [];
  sensormarker: any;
  length: any;
  public serialnumbers = [];
  public sites = [];
  sortedserialnumbers: number[];
  ttfire: any;
  /*last values of all pre-alarm parameters*/
  lastsapflow: any;
  laststemh: any;
  lastgrowth: any;
  lastrisk: any;
  lastndvi: any;
  lastgr: any;
  lastleaft: any;
  lasttemperature: any;
  lasthumidity: any;
  /*last values of all alarm parameters*/
  lastco2: any;
  lasto3: any;
  lastpm25: any;
  lastpm10: any;
  lastflamepulses: any;
  lastflameflag: any;
  lasttam: any;
  /*last update and minutes ago*/
  lastupdate: string;
  minutesago: any;
  /*hours ago to change marker color*/
  timebetweendates: any;
  hoursago: number;
  clusterrisk: string;
  /*variable to refresh color*/
  colorUrl: string;
  loaded: boolean;
  /*hashTable*/
  table = new HashTable<string, any>();
  @ViewChild('googlemap') fullMap;
  @ViewChild(MapInfoWindow, {static: false}) infoWindow: MapInfoWindow;
  @ViewChild(MapMarker, {static: false}) mapMarker: MapMarker;
  @ViewChild('iframe', {static: false}) iframe: ElementRef;
  infoContent = '52060674';
  private interval: any;
/* Grafana url */
  prealarmUrl: any;
  alarmUrl: any;
  dangerousUrl: any;
  trustedUrl: any;
  dangerous1Url: any;
  trusted1Url: any;
  @ViewChild('select') select;
  private static _handleError(err: HttpErrorResponse | any) {
    return Observable.throw(err.message || 'Error: Unable to complete request.');
  }
  /*fixing the map center*/
  calculateCenter(){
    this.center = {
      lat: 40.460744,
      lng: 18.119061
    };
  }
  /*function to execute refreshing of data*/
  callEveryHour(){
    const nextdate: any = new Date();
    let thisdate: any;
    let difference: any;
    if (nextdate.getMinutes() === 10 && nextdate.getSeconds() === 0) { // You can check for seconds here too
      this.refreshData(true);
      this.refreshDashboards(true);
    } else {
      if (nextdate.getMinutes() < 10) {
        nextdate.setMinutes(10);
        nextdate.setSeconds(0);
      }
      else {
        nextdate.setHours(nextdate.getHours() + 1);
        nextdate.setMinutes(10);
        nextdate.setSeconds(0);
      }
      thisdate = new Date();
      difference = nextdate - thisdate;
      setTimeout(function() {
        this.refreshData(true);
        this.refreshDashboards(true);
      }.bind(this), difference);
    }
  }
  /*function to reload dashboards every hour*/
  refreshDashboards(check){
    if (check) {
      setInterval(() => {
        this.refreshDashboards(false);
      }, 3600000);
    }
    this.dangerousUrl = this.dangerousUrl;
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
    this.dangerous1Url = this.dangerous1Url;
    this.trusted1Url =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
  }
  /*function to change indicator*/
  toggleDisplay(focus: string, value: any, dashboard: any) {
    if (dashboard === 'prealarm'){
      this.prealarmvalue = value;
      if (this.focusedprealarm !== focus) {
        document.getElementById(this.focusedprealarm).style.borderWidth = '1px';
        document.getElementById(focus).style.borderWidth = '2px';
        this.focusedprealarm = focus;
      }
    }
    else {
      this.alarmvalue = value;
      if (this.focusedalarm !== focus) {
        document.getElementById(this.focusedalarm).style.borderWidth = '1px';
        document.getElementById(focus).style.borderWidth = '2px';
        this.focusedalarm = focus;
      }
    }
    // tslint:disable-next-line:max-line-length
    if (this.selectedValue === 'Sensori Ofidia' || this.selectedValue === 'Cesine' || this.selectedValue === 'Masseria Cuturi' || this.selectedValue === 'Masseria Galeone' || this.selectedValue === 'Ugento'){
      switch (value) {
        case 2: {
          this.value2 = 17;
          break;
        }
        case 5: {
          this.value2 = 18;
          break;
        }
        case 6: {
          this.value2 = 19;
          break;
        }
        case 8: {
          this.value2 = 20;
          break;
        }
        case 32: {
          this.value2 = 33;
          break;
        }
        case 7: {
          this.value2 = 21;
          break;
        }
        case 9: {
          this.value2 = 22;
          break;
        }
        case 10: {
          this.value2 = 23;
          break;
        }
        case 11: {
          this.value3 = 24;
          break;
        }
        case 12: {
          this.value3 = 25;
          break;
        }
        case 13: {
          this.value3 = 26;
          break;
        }
        case 14: {
          this.value3 = 27;
          break;
        }
        case 15: {
          this.value3 = 28;
          break;
        }
        case 31: {
          this.value3 = 30;
          break;
        }
        case 16: {
          this.value3 = 29;
          break;
        }
      }
      if (this.selectedValue === 'Sensori Ofidia') {
        this.site = 'All';
      }
      else {
        this.site = this.selectedValue;
      }
      if (dashboard === 'prealarm')
      {
        // tslint:disable-next-line:max-line-length
        this.dangerousUrl = this.prealarmUrl + '&var-site=' + this.site + '&var-tree_talker=All&panelId=' + this.value2;
        this.trustedUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
      }
      else { // tslint:disable-next-line:max-line-length
        this.dangerous1Url = this.alarmUrl + '&var-site=' + this.site + '&var-tree_talker=All&panelId=' + this.value3;
        this.trusted1Url =
          this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
        }
    }
    /*value is a sensor*/
    else {
      if (dashboard === 'prealarm'){
        // tslint:disable-next-line:max-line-length
        this.dangerousUrl = this.prealarmUrl + '&panelId=' + this.prealarmvalue + '&var-tree_talker=' + this.selectedValue;
        this.trustedUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
      }
      else {
        // tslint:disable-next-line:max-line-length
        this.dangerous1Url = this.alarmUrl + '&panelId=' + this.alarmvalue + '&var-tree_talker=' + this.selectedValue;
        this.trusted1Url =
          this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
      }
    }
  }

  public getJSON_sensorsnumber(): Observable<any> {
    if (this.role === 'ROLE_ADMIN')
    {
      return this.http.get(`${SENSORS_NUMBER_URL}` );
    }
    else {
      return this.http.get(`${SENSORS_NUMBER_FOR_SITE_URL}` + '\'' + this.usersite + '\'');
    }
  }

  public getJSON_totmeasures(): Observable<any> {
    if (this.role === 'ROLE_ADMIN')
    {
      return this.http.get(`${TOTAL_MEASURES_URL}` );
    }
    else {
      return this.http.get(`${TOTAL_MEASURES_URL}` + 'WHERE "site"=' + '\'' + this.usersite + '\'');
    }
  }

  public getJSON_sensorsinfo(): Observable<any> {
    if (this.role === 'ROLE_ADMIN')
    {
      return this.http.get(`${SENSORS_INFO_URL}` + 'GROUP BY "tree_talker"' + `${SENSOR_UPDATE_URL}` + 'GROUP BY "tree_talker"');
    }
    else {
      return this.http.get(`${SENSORS_INFO_URL}` + 'WHERE "site"=' + '\'' + this.usersite + '\'' + ' GROUP BY "tree_talker"' + `${SENSOR_UPDATE_URL}` + ' AND "site"=' + '\'' + this.usersite + '\'' + ' GROUP BY "tree_talker"');
    }
  }

  public getJSON_infobysensor(value): Observable<any> {
    return this.http.get(`${INFO_BY_SERIAL_NUMBER_URL}` + '\'' + value + '\'' );
  }

  public getJSON_sites(): Observable<any> {
    return this.http.get(`${SITES_URL}` );
  }

  public getJSON_markercolor(value): Observable<any> {
    return this.http.get(`${MARKER_COLOR_URL}` + '\'' + value + '\'');
  }

  public getJSON_markervalues(): Observable<any> {
    if (this.role === 'ROLE_ADMIN')
    {
      return this.http.get(`${MARKER_VALUES_URL}` + ' GROUP BY "tree_talker"');
    }
    else {
      return this.http.get(`${MARKER_VALUES_URL}` + 'WHERE "site"=' + '\'' + this.usersite + '\'' + ' GROUP BY "tree_talker"');
    }
  }

  /*function to open marker window to show details*/
  openInfo(marker: MapMarker, content: string) {
    console.log('open info');
    this.infoWindow.open(marker);
    this.infoContent = content;
    clearInterval(this.interval);
    this.refreshMarkerInfo(this.infoContent, false);
    const nextdate: any = new Date();
    let thisdate: any;
    let difference: any;
    if (nextdate.getSeconds() === 5) { // You can check for seconds here too
      this.refreshMarkerInfo(this.infoContent, true);
    } else {
      // this.nextDate.setHours(this.nextDate.getHours() + 1);
      nextdate.setMinutes(nextdate.getMinutes() + 1);
      nextdate.setSeconds(5);
      thisdate = new Date();
      difference = nextdate - thisdate;
      setTimeout(function() {
        this.refreshMarkerInfo(this.infoContent, true);
      }.bind(this), difference);
    }
  }
  /*function to find sensor marker*/
  findMarker(event: any) {
    if (event.target === undefined){
      this.searchedvalue = event.value;
    }
    else {
      this.searchedvalue = event.target.value;
      if (this.searchedvalue === 'Sensori Ofidia'){
        document.getElementById('binoc').style.display = 'none';
      }
      else {
        document.getElementById('binoc').style.display = 'block';
        this.searchedsite = this.searchedvalue;
      }
    }
    // tslint:disable-next-line:max-line-length
    if (this.searchedvalue === 'Sensori Ofidia' || this.searchedvalue === 'Cesine' || this.searchedvalue === 'Masseria Cuturi' || this.searchedvalue === 'Masseria Galeone' || this.searchedvalue === 'Ugento') {
      this.selectedValue = this.searchedvalue;
      switch (this.prealarmvalue) {
        case 2: {
          this.value2 = 17;
          break;
        }
        case 5: {
          this.value2 = 18;
          break;
        }
        case 6: {
          this.value2 = 19;
          break;
        }
        case 8: {
          this.value2 = 20;
          break;
        }
        case 32: {
          this.value2 = 33;
          break;
        }
        case 7: {
          this.value2 = 21;
          break;
        }
        case 9: {
          this.value2 = 22;
          break;
        }
        case 10: {
          this.value2 = 23;
          break;
        }
      }
      switch (this.alarmvalue) {
          case 11: {
            this.value3 = 24;
            break;
          }
          case 12: {
            this.value3 = 25;
            break;
          }
          case 13: {
            this.value3 = 26;
            break;
          }
          case 14: {
            this.value3 = 27;
            break;
          }
          case 15: {
            this.value3 = 28;
            break;
          }
        case 31: {
          this.value3 = 30;
          break;
        }
          case 16: {
            this.value3 = 29;
            break;
          }
        }
      if (this.selectedValue === 'Sensori Ofidia') {
        this.zoom = 8;
        this.center = {
          lat: 40.34654412118006,
          lng: 18.171386718750004
        };
        this.site = 'All';
        this.infoWindow.close();
      }
      else {
        switch (this.selectedValue) {
          case 'Cesine': {
            this.zoom = 15.4;
            this.center = {
              lat: 40.350097,
              lng: 18.335247
            };
            break;
          }
          case 'Masseria Cuturi': {
            this.zoom = 16;
            this.center = {
              lat: 40.3409694,
              lng: 17.661394
            };
            break;
          }
          case 'Masseria Galeone': {
            this.zoom = 16;
            this.center = {
              lat: 40.7449527,
              lng: 17.20138972
            };
            break;
          }
          case 'Ugento': {
            this.zoom = 18;
            this.center = {
              lat: 39.880724,
              lng: 18.134828
            };
            break;
          }
        }
        this.site = this.selectedValue;
        this.infoWindow.close();
      }
      // tslint:disable-next-line:max-line-length
      this.dangerousUrl = this.prealarmUrl + '&var-site=' + this.site + '&panelId=' + this.value2;
      this.trustedUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
      // tslint:disable-next-line:max-line-length
      this.dangerous1Url = this.alarmUrl + '&var-site=' + this.site + '&panelId=' + this.value3;
      this.trusted1Url =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    }
    // tslint:disable-next-line:max-line-length
    else {
        let newlat;
        this.zoom = 20;
        this.getJSON_infobysensor(this.searchedvalue).subscribe(data => {
          newlat = data[`results`][0][`series`][0][`values`][0][1] - 0.01;
          this.center = {
            lat: newlat,
            lng: data[`results`][0][`series`][0][`values`][0][2]
          };
        },
          err => {
            console.log('Error! ' + err.message);
          });
        (async () => {
        console.log('waiting for new center');
        while (newlat === undefined) { // define the condition as you like
          await new Promise(resolve => setTimeout(resolve, 2000));
          console.log('await');
        }
        for (let i = 0; i < this.count; i++) {
          if (this.markers[i].title === this.searchedvalue) {
            this.mapMarker.position = this.markers[i].position;
            this.mapMarker.title = this.markers[i].title;
            this.mapMarker.options = this.markers[i].options;
            this.openInfo(this.mapMarker, this.searchedvalue);
          }
        }
        })();
        this.selectedValue = this.searchedvalue;
        // tslint:disable-next-line:max-line-length
        this.dangerousUrl = this.prealarmUrl + '&panelId=' + this.prealarmvalue + '&var-tree_talker=' + this.selectedValue;
        this.trustedUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
        // tslint:disable-next-line:max-line-length
        this.dangerous1Url = this.alarmUrl + '&panelId=' + this.alarmvalue + '&var-tree_talker=' + this.selectedValue;
        this.trusted1Url =
          this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    }
  }
  /*function to refresh marker color*/
  refreshMarkerColor(){
    for (let i = 0; i < this.count; i++) {
      if (this.markers[i] !== undefined){
        this.getJSON_markercolor(this.markers[i].title).subscribe((data: any[]) => {
            if (data[`results`][0][`series`] !== undefined)
            {
              this.timebetweendates = new Date().getTime() - new Date(data[`results`][0][`series`][0][`values`][0][0]).getTime();
              this.hoursago = Math.ceil(this.timebetweendates / (1000 * 3600));
            }
            else {
              this.hoursago = 72;
            }
            if (this.hoursago <= 2){
              this.markers[i].options = { icon: {
                  url: '../../../assets/markers/green.png'
                } };
            }
            else {
              if (this.hoursago <= 48){
                this.markers[i].options = { icon: {
                    url: '../../../assets/markers/yellow.png'
                  } };
              }
              else {
                this.markers[i].options = { icon: {
                    url: '../../../assets/markers/red.png'
                  } };
              }
            }
          },
          err => {
            console.log('Error! ' + err.message);
          });
      }
    }
  }
  /*function to refresh general info of interface*/
  refreshData(check) {
    console.log('refreshdata');
    if (check) {
      setInterval(() => {
        this.refreshData(false);
      }, 3600000);
    }
    /*taking the number of days*/
    this.projectstart = new Date('2018-04-01T00:00:00Z');
    this.daytime = new Date().getTime() - this.projectstart.getTime();
    this.days = Math.ceil(this.daytime / (1000 * 3600 * 24));
    /*taking the total of measures*/
    this.getJSON_totmeasures().subscribe(data => {
        this.totmeasures = data[`results`][0][`series`][0][`values`][0][1];
      },
      err => {
        console.log('Error! ' + err.message);
      });
    (async () => {
      console.log('waiting for markers');
      while (this.markers.length === 0) { // define the condition as you like
        await new Promise(resolve => setTimeout(resolve, 2000));
        console.log('await');
        this.loaded = false;
      }
      this.loaded = true;
      console.log('markers are defined');
      this.getJSON_markervalues().subscribe(data => {
        /*populating hashTable with last values*/
        for (let i = 0; i < this.count; i++) {
          if (data[`results`][0][`series`][i] !== undefined) {
            this.daytime = new Date().getTime() - new Date(data[`results`][0][`series`][i][`values`][0][0]).getTime();
            this.table.put(data[`results`][0][`series`][i][`tags`][`tree_talker`], {
              last_timestamp: new Date(data[`results`][0][`series`][i][`values`][0][0]).getTime(),
              ttfire: this.markers[i].ttfire,
              lat: this.markers[i].position.lat,
              lon: this.markers[i].position.lng,
              site: this.markers[i].info,
              color: this.markers[i].options.icon.url,
              air_temp: data[`results`][0][`series`][i][`values`][0][1],
              air_hum: data[`results`][0][`series`][i][`values`][0][2],
              leaf_temp: data[`results`][0][`series`][i][`values`][0][3],
              flame_flag: data[`results`][0][`series`][i][`values`][0][4],
              flame_pulses: data[`results`][0][`series`][i][`values`][0][5],
              co2: data[`results`][0][`series`][i][`values`][0][6],
              o3: data[`results`][0][`series`][i][`values`][0][7],
              pm10: data[`results`][0][`series`][i][`values`][0][8],
              pm25: data[`results`][0][`series`][i][`values`][0][9],
              gr: data[`results`][0][`series`][i][`values`][0][10],
              ndvi: data[`results`][0][`series`][i][`values`][0][11],
              growth: data[`results`][0][`series`][i][`values`][0][12],
              risk: data[`results`][0][`series`][i][`values`][0][13],
              sapflow: data[`results`][0][`series`][i][`values`][0][14],
              stemh: data[`results`][0][`series`][i][`values`][0][15],
              tam: data[`results`][0][`series`][i][`values`][0][16],
              last_update: (this.datePipe.transform(new Date(data[`results`][0][`series`][i][`values`][0][0]), 'yyyy-MM-dd HH:mm:ss')),
            });
	  }
	}
        });
      })();
      this.refreshMarkerColor();
  }
  /*function to refresh marker info window*/
  refreshMarkerInfo(content, check) {
    console.log('refresh marker info');
    if (check) {
      this.interval = setInterval(() => {
        this.refreshMarkerInfo(this.infoContent, false);
      }, 60000);
    }
    this.refreshData(false);
    (async () => {
      console.log('waiting for table values');
      while (this.table.size() !== this.count) { // define the condition as you like
        await new Promise(resolve => setTimeout(resolve, 2000));
        console.log('await hash table');
      }
      console.log('hash table is ready');
      /*associating hash values to global variables*/
      this.daytime = new Date().getTime() - this.table.get(content).last_timestamp;
      this.ttfire = this.table.get(content).ttfire;
      this.lasttemperature = this.table.get(content).air_temp;
      this.lasthumidity = this.table.get(content).air_hum;
      this.lastleaft = this.table.get(content).leaf_temp;
      this.lastupdate = this.table.get(content).last_update;
      this.minutesago = Math.ceil(this.daytime / (1000 * 60));
      this.lastflameflag = this.table.get(content).flame_flag;
      this.lastflamepulses = this.table.get(content).flame_pulses;
      this.lastco2 = this.table.get(content).co2;
      this.lasto3 = this.table.get(content).o3;
      this.lastpm10 = this.table.get(content).pm10;
      this.lastpm25 = this.table.get(content).pm25;
      this.lastgr = this.table.get(content).gr;
      this.lastndvi = this.table.get(content).ndvi;
      this.lastgrowth = this.table.get(content).growth;
      this.lastrisk = this.table.get(content).risk;
      this.lastsapflow = this.table.get(content).sapflow;
      this.laststemh = this.table.get(content).stemh;
      this.lasttam = this.table.get(content).tam;
      const stylewidth = document.getElementById('risk width');
      if (stylewidth !== null)
      {
        stylewidth.style.width = this.lastrisk * 10 + '%';
      }
      switch (true) {
        case (this.lastrisk <= 2): {
          this.clusterrisk = 'basso';
          break;
        }
        case (this.lastrisk > 2 && this.lastrisk <= 5): {
          this.clusterrisk = 'medio';
          break;
        }
        case (this.lastrisk > 5 && this.lastrisk <= 8): {
          this.clusterrisk = 'moderato';
          break;
        }
        case (this.lastrisk > 8 && this.lastrisk <= 10): {
          this.clusterrisk = 'alto';
          break;
        }
      }
    })();
  }
  initializeMarkers() {
    (async () => {
      console.log('waiting for sensors number');
      while (this.count === undefined) { // define the condition as you like
        await new Promise(resolve => setTimeout(resolve, 2000));
        console.log('await');
      }
      /*taking sensors info*/
      this.getJSON_sensorsinfo().subscribe(data => {
          let j = 0;
          for (let i = 0; i < this.count; i++) {
            if (data[`results`][1][`series`] !== undefined && data[`results`][1][`series`][j] !== undefined) {
              if (data[`results`][0][`series`][i][`tags`][`tree_talker`] === data[`results`][1][`series`][j][`tags`][`tree_talker`]) {
                this.timebetweendates = new Date().getTime() - new Date(data[`results`][1][`series`][j][`values`][0][0]).getTime();
                this.hoursago = Math.ceil(this.timebetweendates / (1000 * 3600));
                j = j + 1;
              } else {
                this.hoursago = 72;
              }
            }
            else {
              this.hoursago = 72;
            }
            if (this.hoursago <= 2) {
              this.colorUrl = '../../../assets/markers/green.png';
            } else {
              if (this.hoursago <= 48) {
                this.colorUrl = '../../../assets/markers/yellow.png';
              } else {
                this.colorUrl = '../../../assets/markers/red.png';
              }
            }
            this.sensormarker = {
              position: {
                lat: data[`results`][0][`series`][i][`values`][0][1],
                lng: data[`results`][0][`series`][i][`values`][0][2],
              },
              ttfire: data[`results`][0][`series`][i][`values`][0][4],
              title: data[`results`][0][`series`][i][`tags`][`tree_talker`],
              options: {
                icon: {
                  url: this.colorUrl
                }
              },
              info: data[`results`][0][`series`][i][`values`][0][3],
            };
            this.markers.push(this.sensormarker);
            /*taking the list of all serial numbers*/
            this.serialnumbers.push(data[`results`][0][`series`][i][`tags`][`tree_talker`]);
          }
          /*sorting serial numbers array*/
          this.sortedserialnumbers = this.serialnumbers.sort((n1, n2) => n1 - n2);
        },
        err => {
          console.log('Error! ' + err.message);
        });
      })();
  }
  notsearchTT(){
    document.getElementById('selectfields').style.display = 'none';
  }
  searchTT() {
    this.select.open();
    document.getElementById('selectfields').style.display = 'block';
  }
  /*open Info on buttons*/
  openDialog(param, description): void {
    const dialogRef = this.dialog.open(DialogInfoItaComponent, {
      width: '250px',
      data: {param, description}
    });
  }
  ngOnInit() {
    this.loaded=false;
    /*setting focus on first button*/
    document.getElementById(this.focusedprealarm).style.borderWidth = '2px';
    document.getElementById(this.focusedalarm).style.borderWidth = '2px';
    document.getElementById('selectfields').style.display = 'none';
    document.getElementById('Cesinestation').style.display = 'none';
    /*setting Grafana dashboards url*/
    this.prealarmUrl = `${DASHBOARD_URL}` + `${PREALARM_DAILY_URL}`;
    this.alarmUrl = `${DASHBOARD_URL}` + `${ALARM_DAILY_URL}`;
    /*taking the role of user*/
    this.role = this.tokenStorageService.getUser().role;
    this.usersite = this.tokenStorageService.getUser().site;
    if (this.usersite === 'Ofidia Sensors') {
      this.usersite = 'Sensori Ofidia';
    }
    /*taking the sensors number from json and the covered area*/
    this.getJSON_sensorsnumber().subscribe(data => {
      if (this.role === 'ROLE_ADMIN')
      {
        this.count = data[`results`][0][`series`][0][`values`][0][0];
        this.selectedValue = 'Sensori Ofidia';
        document.getElementById('videosite').style.display = 'none';
      }
      else {
        this.count = data[`results`][0][`series`][0][`values`][0][1];
        this.selectedValue = this.usersite;
        this.searchedsite = this.usersite;
      }
      this.area = Math.round(this.count * 50 * 50 * Math.PI);
    },
      err => {
        console.log('Error! ' + err.message);
      });
    /*setting zoom and center based on user role*/
    if (this.role === 'ROLE_ADMIN') {
      /*disable site details initially*/
      document.getElementById('binoc').style.display = 'none';
      this.zoom = 8;
      this.center = {
        lat: 40.34654412118006,
        lng: 18.171386718750004
        };
      /*adding all sites in sensors list for admin*/
      this.getJSON_sites().subscribe((data: any[]) => {
        for (let i = 0; i < 4; i++) {
          /*taking the list of all sites*/
          this.sites.push(data[`results`][0][`series`][0][`values`][i][1]);
        }
      },
        err => {
          console.log('Error! ' + err.message);
        });
      /*set the initial Grafana dashboard for all sensors*/
      // tslint:disable-next-line:max-line-length
      this.dangerousUrl = this.prealarmUrl + '&var-site=All&panelId=17';
      this.trustedUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
      // tslint:disable-next-line:max-line-length
      this.dangerous1Url = this.alarmUrl + '&var-site=All&panelId=24';
      this.trusted1Url =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    }
    else {
      /*enable site details*/
      document.getElementById('binoc').style.display = 'block';
      switch (this.usersite) {
        case 'Cesine': {
          document.getElementById('Cesinestation').style.display = 'block';
          this.zoom = 15.4;
          this.center = {
            lat: 40.350097,
            lng: 18.335247
          };
          break;
        }
        case 'Masseria Cuturi': {
          this.zoom = 16;
          this.center = {
            lat: 40.341504,
            lng: 17.658152
          };
          break;
        }
        case 'Masseria Galeone': {
          this.zoom = 16;
          this.center = {
            lat: 40.7449527,
            lng: 17.20138972
          };
          break;
        }
        case 'Ugento': {
          this.zoom = 19;
          this.center = {
            lat: 39.880324,
            lng: 18.136555
          };
          break;
        }
      }
      /*set the initial Grafana dashboard depending on the monitored site*/
      // tslint:disable-next-line:max-line-length
      this.dangerousUrl = this.prealarmUrl + '&var-site=' + this.usersite + '&panelId=17';
      this.trustedUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
      // tslint:disable-next-line:max-line-length
      this.dangerous1Url = this.alarmUrl + '&var-site=' + this.usersite + '&panelId=24';
      this.trusted1Url =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    }
    this.initializeMarkers();
    this.refreshData(false);
    this.callEveryHour();
  }
  goToPageDetails(pageName: string): void {
    localStorage.setItem('serial', this.infoContent);
    localStorage.setItem('ttfire', this.ttfire);
    localStorage.setItem('latitude', this.table.get(this.infoContent).lat);
    localStorage.setItem('longitude', this.table.get(this.infoContent).lon);
    localStorage.setItem('site', this.table.get(this.infoContent).site);
    localStorage.setItem('update', this.table.get(this.infoContent).last_update);
    localStorage.setItem('color', this.table.get(this.infoContent).color);
    if (this.role === 'ROLE_ADMIN') {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`../${pageName}`], { relativeTo: this.route })
      );
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([`../${pageName}`], { relativeTo: this.route }).then(() => {
        window.location.reload();
      });
    }
  }
  goToPageSiteDetails(pageName: string): void {
    localStorage.setItem('selectedSite', this.searchedsite);
    this.router.navigate([`../${pageName}`], { relativeTo: this.route }).then(() => {
      window.location.reload();
    });
  }
  goToPageAlarms(pageName: string): void {
    this.router.navigate([`../${pageName}`], { relativeTo: this.route }).then(() => {
      window.location.reload();
    });
  }
  goToCameras(pageName: string): void {
    switch (this.usersite) {
      case 'Cesine': {
        localStorage.setItem('camera', 'http://cmcc:cmcc%402020@lecesine.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1280x720');
        break;
      }
      case 'Masseria Cuturi': {
        const url1 = this.router.serializeUrl(
          this.router.createUrlTree(['../cameraCuturi_ita'], { relativeTo: this.route })
        );
        window.open(url1, '_blank');
        localStorage.setItem('camera', 'http://cmcc:cmcc%402020@torrecolimena.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1280x720');
        break;
      }
      case 'Masseria Galeone': {
        localStorage.setItem('camera', 'http://cmcc:cmcc%402020@galeone.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1280x720');
        break;
      }
      case 'Ugento': {
        localStorage.setItem('camera', 'http://cmcc:cmcc%402020@ugento.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1280x720');
        break;
      }
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`../${pageName}`], { relativeTo: this.route })
    );
    window.open(url, '_blank');
  }
  goToCesineStation(pageName: string): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`../${pageName}`], { relativeTo: this.route })
    );
    window.open(url, '_blank');
  }
}

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'infodialogs.html',
})
export class DialogInfoItaComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogInfoItaComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
}
