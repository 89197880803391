import {Component, HostListener, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {
  ALARM_DAILY_URL,
  DASHBOARD_URL, ALARM_HOURLY_URL,
  LAST_UPDATE_URL, PREALARM_DAILY_URL, PREALARM_HOURLY_URL, TOTAL_TTALARMS_URL,
  TOTAL_TTMEASURES_URL
} from '../../env';
import MapTypeId = google.maps.MapTypeId;
import { DatePipe } from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'app-sensor-monitoring-details',
  templateUrl: './sensor-monitoring-details.component.html',
  styleUrls: ['./sensor-monitoring-details.component.css']
})
export class SensorMonitoringDetailsComponent implements OnInit {
  /*map values*/
  zoom = 10;
  type: google.maps.MapTypeId = MapTypeId.HYBRID;
  sensormarker: any;
  markercenter: any;
  circle: any;
  /*sensor info*/
  serial: string;
  ttfire: string;
  latitude: number;
  longitude: number;
  site: string;
  lastupdate: string;
  color: string;
  warning: any;
  /*time filter*/
  aggregationprealarm: any;
  aggregationalarm: any;
  timefilter: string;
  totTTmeasures: number;
  totTTmeasuresbytime: number;
  totTTalarms: number;
  totTTalarmsbytime: number;
  timeperiod: any = 'Last 24 hours';
  nowtimestamp: number;
  timestamp: number;
  prealarmvalue: any = 2;
  alarmvalue: any = 11;
  /* Grafana url */
  url: any;
  dangerousUrl: any;
  trustedUrl: any;
  dangerous1Url: any;
  trusted1Url: any;
  dangerous2Url: any;
  trusted2Url: any;
  dangerous3Url: any;
  trusted3Url: any;
  constructor(private http: HttpClient, private datePipe: DatePipe, private sanitizer: DomSanitizer) {
  }
  public getJSON_lastupdate(value): Observable<any> {
    return this.http.get(`${LAST_UPDATE_URL}` + '\'' + value + '\'');
  }
  public getJSON_totTTmeasures(query, value): Observable<any> {
    return this.http.get(`${TOTAL_TTMEASURES_URL}` + query + '\'' + value + '\'');
  }
  public getJSON_totTTalarms(query, value): Observable<any> {
    return this.http.get(`${TOTAL_TTALARMS_URL}` + query + '\'' + value + '\'');
  }
  /*function to execute refreshing of data*/
  callEveryHour(){
    const nextdate: any = new Date();
    let thisdate: any;
    let difference: any;
    if (nextdate.getMinutes() === 10 && nextdate.getSeconds() === 0) { // You can check for seconds here too
      this.refreshData(true);
      this.timeFilter(true, undefined, this.timeperiod);
    } else {
      if (nextdate.getMinutes() < 10) {
        nextdate.setMinutes(10);
        nextdate.setSeconds(0);
      }
      else {
        nextdate.setHours(nextdate.getHours() + 1);
        nextdate.setMinutes(10);
        nextdate.setSeconds(0);
      }
      thisdate = new Date();
      difference = nextdate - thisdate;
      setTimeout(function() {
        this.refreshData(true);
        this.timeFilter(true, undefined, this.timeperiod);
      }.bind(this), difference);
    }
  }
  /*function to change indicator*/
  toggleDisplay(value: any, dashboard: any) {
    this.nowtimestamp = Math.trunc(new Date().getTime());
    switch (this.timeperiod) {
      case 'Last 24 hours': {
        this.aggregationprealarm = `${PREALARM_HOURLY_URL}`;
        this.aggregationalarm = `${ALARM_HOURLY_URL}`;
        this.timefilter = 'WHERE time > now()-1d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 86400000;
        break;
      }
      case 'Last week': {
        this.aggregationprealarm = `${PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-7d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 604800000;
        break;
      }
      case 'Last month': {
        this.aggregationprealarm = `${PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-30d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 2592000000;
        break;
      }
      case 'Last quarter': {
        this.aggregationprealarm = `${PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-120d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 10368000000;
        break;
      }
      case 'Last semester': {
        this.aggregationprealarm = `${PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-180d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 15552000000;
        break;
      }
      case 'Last year': {
        this.aggregationprealarm = `${PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-365d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 31104000000;
        break;
      }
    }
    if (dashboard === 'prealarm'){
      this.prealarmvalue = value;
      // tslint:disable-next-line:max-line-length
      this.dangerousUrl = this.url + this.aggregationprealarm + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=' + this.prealarmvalue + '&var-tree_talker=' + this.serial;
      this.trustedUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
    }
    else {
      this.alarmvalue = value;
      switch (this.alarmvalue) {
        case 11: {this.warning = 500; break; }
        case 12: {this.warning = 600; break; }
        case 13: {this.warning = 700; break; }
        case 14: {this.warning = 800; break; }
        case 15: {this.warning = 900; break; }
        case 31: {this.warning = 500; break; }
        case 16: {this.warning = 500; break; }
      }
      // tslint:disable-next-line:max-line-length
      this.dangerous1Url = this.url + this.aggregationalarm + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=' + this.alarmvalue + '&var-tree_talker=' + this.serial;
      this.trusted1Url =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    }
  }
  /*function to refresh tt sensor details*/
  refreshData(check){
    if (check) {
      setInterval(() => {
        this.refreshData(false);
      }, 3600000);
    }
    this.getJSON_lastupdate(this.serial).subscribe(data => {
      if (data[`results`][0][`series`] !== undefined) {
        this.lastupdate = (this.datePipe.transform(new Date(data[`results`][0][`series`][0][`values`][0][0]), 'yyyy-MM-dd HH:mm:ss'));
        localStorage.setItem('update', this.lastupdate);
      }
    });
    this.timefilter = 'WHERE "tree_talker"=';
    this.getJSON_totTTmeasures(this.timefilter, this.serial).subscribe(data => {
      if (data[`results`][0][`series`] !== undefined) {
        this.totTTmeasures = data[`results`][0][`series`][0][`values`][0][1];
      }
    });
    this.getJSON_totTTalarms(this.timefilter, this.serial).subscribe(data => {
      if (data[`results`][0][`series`] !== undefined) {
        console.log(data[`results`][0][`series`][0][`values`][0][1]);
        this.totTTalarms = data[`results`][0][`series`][0][`values`][0][1];
      }
      else {
        this.totTTalarms = 0;
      }
    });
  }
  /*function to refresh measures by time period*/
  timeFilter(check, event, period) {
    if (check) {
      setInterval(() => {
        this.timeFilter(false, undefined, this.timeperiod);
      }, 3600000);
    }
    if (event !== undefined) {
      this.timeperiod = event.target.value;
    }
    else {
      this.timeperiod = period;
    }
    this.nowtimestamp = Math.trunc(new Date().getTime());
    switch (this.timeperiod) {
      case 'Last 24 hours': {
        this.aggregationprealarm = `${PREALARM_HOURLY_URL}`;
        this.aggregationalarm = `${ALARM_HOURLY_URL}`;
        this.timefilter = 'WHERE time > now()-1d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 86400000;
        break;
      }
      case 'Last week': {
        this.aggregationprealarm = `${PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-7d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 604800000;
        break;
      }
      case 'Last month': {
        this.aggregationprealarm = `${PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-30d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 2592000000;
        break;
      }
      case 'Last quarter': {
        this.aggregationprealarm = `${PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-120d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 10368000000;
        break;
      }
      case 'Last semester': {
        this.aggregationprealarm = `${PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-180d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 15552000000;
        break;
      }
      case 'Last year': {
        this.aggregationprealarm = `${PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-365d AND time < now() AND "tree_talker"=';
        this.timestamp = this.nowtimestamp - 31104000000;
        break;
      }
    }
    // tslint:disable-next-line:max-line-length
    this.dangerousUrl = this.url + this.aggregationprealarm + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=' + this.prealarmvalue + '&var-tree_talker=' + this.serial;
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
    // tslint:disable-next-line:max-line-length
    this.dangerous1Url = this.url + this.aggregationalarm + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=' + this.alarmvalue + '&var-tree_talker=' + this.serial;
    this.trusted1Url =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    // tslint:disable-next-line:max-line-length
    this.dangerous2Url = this.url + this.aggregationprealarm + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=4' + '&var-tree_talker=' + this.serial;
    this.trusted2Url = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous2Url);
    // tslint:disable-next-line:max-line-length
    this.dangerous3Url = this.url + this.aggregationalarm + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=4' + '&var-tree_talker=' + this.serial;
    this.trusted3Url = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous3Url);
    this.getJSON_totTTmeasures(this.timefilter, this.serial).subscribe(data => {
      if (data[`results`][0][`series`] !== undefined) {
        this.totTTmeasuresbytime = data[`results`][0][`series`][0][`values`][0][1];
      }
      else {
        this.totTTmeasuresbytime = 0;
      }
    });
    this.getJSON_totTTalarms(this.timefilter, this.serial).subscribe(data => {
      if (data[`results`][0][`series`] !== undefined) {
        this.totTTalarmsbytime = data[`results`][0][`series`][0][`values`][0][1];
      }
      else {
        this.totTTalarmsbytime = 0;
      }
    });
  }
  ngOnInit() {
    /*setting Grafana dashboards url*/
    this.url = `${DASHBOARD_URL}`;
    this.aggregationprealarm = `${PREALARM_HOURLY_URL}`;
    this.aggregationalarm = `${ALARM_HOURLY_URL}`;
    this.nowtimestamp = Math.trunc(new Date().getTime());
    this.timestamp = this.nowtimestamp - 86400000;
    this.serial = localStorage.getItem('serial');
    this.ttfire = localStorage.getItem('ttfire');
    this.latitude = parseFloat(localStorage.getItem('latitude'));
    this.longitude = parseFloat(localStorage.getItem('longitude'));
    this.site = localStorage.getItem('site');
    this.lastupdate = localStorage.getItem('update');
    this.color = localStorage.getItem('color');
    this.timefilter = 'WHERE "tree_talker"=';
    this.warning = 500;
    this.sensormarker = {
      position: {
        lat: this.latitude,
        lng: this.longitude,
      },
      title: this.serial,
      options: { icon: {
          url: this.color
        } },
    };
    this.markercenter = {
      lat: this.latitude,
      lng: this.longitude
    };
    // Add circle overlay and bind to marker
    this.circle = {
      options: { fillColor: 'blue', strokeColor: 'blue', strokeWeight: 0.5},
    };
    // tslint:disable-next-line:max-line-length
    this.dangerousUrl = this.url + this.aggregationprealarm + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=2&var-tree_talker=' + this.serial;
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
    // tslint:disable-next-line:max-line-length
    this.dangerous1Url = this.url + this.aggregationalarm + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=11&var-tree_talker=' + this.serial;
    this.trusted1Url =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    // tslint:disable-next-line:max-line-length
    this.dangerous2Url = this.url + this.aggregationprealarm + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=4&var-tree_talker=' + this.serial;
    this.trusted2Url = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous2Url);
    // tslint:disable-next-line:max-line-length
    this.dangerous3Url = this.url + this.aggregationalarm + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=4&var-tree_talker=' + this.serial;
    this.trusted3Url = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous3Url);
    this.refreshData(false);
    this.timeFilter(false, undefined, this.timeperiod);
    this.callEveryHour();
  }
}
