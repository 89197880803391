import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {DialogInfoComponent} from '../sensor-monitoring/sensormonitoring.component';
import {DASHBOARD_URL, STATION_VALUES_URL, WS_DAILY_URL} from '../../env';
import {Observable} from 'rxjs';
import {HashTable} from 'angular-hashtable';
import {TokenStorageService} from '../../services/token-storage.service';

@Component({
  selector: 'app-meteo',
  templateUrl: './meteo.component.html',
  styleUrls: ['./meteo.component.css']
})
export class MeteoComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(private http: HttpClient, private datePipe: DatePipe, private sanitizer: DomSanitizer, public dialog: MatDialog, private tokenStorageService: TokenStorageService) {
  }
  /*role is admin or park manager or operator*/
  role: any;
  usersite: any;
  /*hashTable*/
  infoContent = 'Cesine';
  table = new HashTable<string, any>();
  private interval: any;
  @ViewChild('googlemap') fullMap;
  @ViewChild(MapInfoWindow, {static: false}) infoWindow: MapInfoWindow;
  @ViewChild(MapMarker, {static: false}) mapMarker: MapMarker;
  /*map values*/
  zoom: number;
  type = google.maps.MapTypeId.TERRAIN;
  public center = {};
  selectedsite: any = 'All';
  marker1: { };
  marker2: { };
  markerwindow: google.maps.Marker;
  infowindow: google.maps.InfoWindow;
  // markers stations
  markers: any[] = [];
  // values
  name: string;
  lastupdate: string;
  totalprec: string;
  precintensity: string;
  temperature: string;
  humidity: string;
  radiation: string;
  winddirection: string;
  windspeed: string;
  /* Grafana url */
  grafanaUrl: any;
  dangerousUrl: any;
  trustedUrl: any;
  dangerous1Url: any;
  trusted1Url: any;
  /*variable to focuse a button on click*/
  value: any = 2;
  focused = 'tot_prec';
  handleOnTilesLoaded(){
    console.log('Tiles have loaded.');
    if (this.markers.length !== 2) {
      this.fullMap.panBy(0, 0);
    }
  }
  public getJSON_markervalues(): Observable<any> {
    return this.http.get(`${STATION_VALUES_URL}` + ' GROUP BY "site"');
  }
  /*function to execute refreshing of data*/
  callEveryHour(){
    const nextdate: any = new Date();
    let thisdate: any;
    let difference: any;
    // tslint:disable-next-line:max-line-length
    if ((nextdate.getMinutes() === 0 || nextdate.getMinutes() === 30) && nextdate.getSeconds() === 40) { // You can check for seconds here too
      this.refreshData(true);
      this.refreshDashboards(true);
    } else {
      if (nextdate.getMinutes() > 30) {
        nextdate.setHours(nextdate.getHours() + 1);
        nextdate.setMinutes(0);
        nextdate.setSeconds(40);
      }
      else {
        nextdate.setMinutes(30);
        nextdate.setSeconds(40);
      }
      thisdate = new Date();
      difference = nextdate - thisdate;
      setTimeout(function() {
        this.refreshData(true);
        this.refreshDashboards(true);
      }.bind(this), difference);
    }
  }
  /*function to reload dashboards every half an hour*/
  refreshDashboards(check){
    if (check) {
      setInterval(() => {
        this.refreshDashboards(false);
      }, 1800000);
    }
    this.dangerousUrl = this.dangerousUrl;
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
    this.dangerous1Url = this.dangerous1Url;
    this.trusted1Url =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
  }
  /*function to refresh general info of interface*/
  refreshData(check) {
    console.log('refreshdata');
    if (check) {
      setInterval(() => {
        this.refreshData(false);
      }, 1800000);
    }
    this.getJSON_markervalues().subscribe(data => {
      /*populating hashTable with last values*/
      for (let i = 0; i < 2; i++) {
        if (data[`results`][0][`series`][i] !== undefined) {
          this.table.put(data[`results`][0][`series`][i][`tags`][`site`], {
            name: data[`results`][0][`series`][i][`tags`][`site`],
            last_timestamp: new Date(data[`results`][0][`series`][i][`values`][0][0]).getTime(),
            tot_prec: data[`results`][0][`series`][i][`values`][0][1],
            prec_int: data[`results`][0][`series`][i][`values`][0][2],
            temp: data[`results`][0][`series`][i][`values`][0][3],
            rel_hum: data[`results`][0][`series`][i][`values`][0][4],
            sol_rad: data[`results`][0][`series`][i][`values`][0][5],
            wind_dir: data[`results`][0][`series`][i][`values`][0][6],
            wind_speed: data[`results`][0][`series`][i][`values`][0][7],
            last_update: (this.datePipe.transform(new Date(data[`results`][0][`series`][i][`values`][0][0]), 'yyyy-MM-dd HH:mm:ss')),
          });
        }
      }
    });
  }
  /*function to change indicator*/
  toggleDisplay(focus: string, value: any) {
    this.value = value;
    if (this.focused !== focus) {
      document.getElementById(this.focused).style.borderWidth = '1px';
      document.getElementById(focus).style.borderWidth = '2px';
      this.focused = focus;
    }
    // tslint:disable-next-line:max-line-length
    this.dangerousUrl = this.grafanaUrl + '&panelId=' + this.value + '&var-site=' + this.selectedsite;
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
  }
  /*open Info on buttons*/
  openDialog(param, description): void {
    this.dialog.open(DialogInfoComponent, {
      width: '250px',
      data: {param, description}
    });
  }
  /*function to open marker window to show details*/
  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.selectedsite = content;
    clearInterval(this.interval);
    this.refreshMarkerInfo(this.infoContent, false);
    const nextdate: any = new Date();
    let thisdate: any;
    let difference: any;
    // tslint:disable-next-line:max-line-length
    if ((nextdate.getMinutes() === 0 || nextdate.getMinutes() === 30) && nextdate.getSeconds() === 45) { // You can check for seconds here too
      this.refreshMarkerInfo(this.infoContent, true);
    } else {
      if (nextdate.getMinutes() > 30) {
        nextdate.setHours(nextdate.getHours() + 1);
        nextdate.setMinutes(0);
        nextdate.setSeconds(45);
      }
      else {
        nextdate.setMinutes(30);
        nextdate.setSeconds(45);
      }
      thisdate = new Date();
      difference = nextdate - thisdate;
      setTimeout(function() {
        this.refreshMarkerInfo(this.infoContent, true);
      }.bind(this), difference);
    }
    // tslint:disable-next-line:max-line-length
    this.dangerousUrl = this.grafanaUrl + '&panelId=' + this.value + '&var-site=' + this.selectedsite;
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
    this.dangerous1Url = this.grafanaUrl + '&panelId=4&var-site=' + this.selectedsite;
    this.trusted1Url =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    this.infoWindow.open(marker);
  }
  /*function to refresh marker info window*/
  refreshMarkerInfo(content, check) {
    console.log('refresh marker info');
    if (check) {
      this.interval = setInterval(() => {
        this.refreshMarkerInfo(this.infoContent, false);
      }, 1800000);
    }
    /*associating hash values to global variables*/
    this.name = this.table.get(content).name;
    this.lastupdate = this.table.get(content).last_update;
    this.totalprec = this.table.get(content).tot_prec.toFixed(2);
    this.precintensity = this.table.get(content).prec_int.toFixed(2);
    this.temperature = this.table.get(content).temp.toFixed(2);
    this.humidity = this.table.get(content).rel_hum.toFixed(2);
    this.radiation = this.table.get(content).sol_rad.toFixed(2);
    this.winddirection = this.table.get(content).wind_dir.toFixed(2);
    this.windspeed = this.table.get(content).wind_speed.toFixed(2);
  }
  ngOnInit() {
    this.role = this.tokenStorageService.getUser().role;
    this.usersite = this.tokenStorageService.getUser().site;
    /*setting focus on first button*/
    document.getElementById(this.focused).style.borderWidth = '2px';
    /*setting Grafana dashboards url*/
    this.grafanaUrl = `${DASHBOARD_URL}` + `${WS_DAILY_URL}`;
    this.dangerousUrl = this.grafanaUrl + '&var-site=All&panelId=2';
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
    this.dangerous1Url = this.grafanaUrl + '&var-site=All&panelId=4';
    this.trusted1Url =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    this.zoom = 8;
    this.center = {
      lat: 40.34654412118006,
      lng: 18.171386718750004
    };
    this.marker1 = {
      position: {
        lat: 40.74444,
        lng: 17.20583,
      },
      title: 'Masseria Galeone',
      options: {
        icon: {url: '../../../assets/markers/weather-station.png', scaledSize: {height: 48, width: 48}},
      },
    };
    this.marker2 = {
      position: {
        lat: 40.35000,
        lng: 18.33639,
      },
      title: 'Cesine',
      options: {
        icon: {url: '../../../assets/markers/weather-station.png', scaledSize: {height: 48, width: 48} },
      },
    };
    if (this.role === 'ROLE_PARKMANAGER' && this.usersite === 'Cesine') {
      this.dangerousUrl = this.grafanaUrl + '&var-site=Cesine&panelId=2';
      this.trustedUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
      this.dangerous1Url = this.grafanaUrl + '&var-site=Cesine&panelId=4';
      this.trusted1Url =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
      this.markers.push(this.marker2);
    }
    else {
      this.dangerousUrl = this.grafanaUrl + '&var-site=All&panelId=2';
      this.trustedUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
      this.dangerous1Url = this.grafanaUrl + '&var-site=All&panelId=4';
      this.trusted1Url =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
      this.markers.push(this.marker1, this.marker2);
    }
    this.refreshData(false);
    this.callEveryHour();
  }
}


