<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<head>

  <title>OFIDIA2 Dashboard</title>

  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">

  <link rel="icon" href="../../../img/icon.ico">

  <!-- Maps -->
  <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAP7Ni4fuCgNJgF4RkZMhycAPtwnvQKzYs"></script>
  <script src="https://maps.google.com/maps/api/js?sensor=false&libraries=geometry&v=3.22&key=AIzaSyAGkJdXZsJ2fqW-D2EKZrCHVda8dbOMV3U"></script>

  <!-- JQuery -->
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js"></script>

  <!-- Maplace.js -->
  <script src="../../../lib/maplace.min.js"></script>

  <!-- Chart.js -->
  <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.1.4/Chart.min.js"></script>

  <!-- Latest compiled and minified CSS -->
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css" integrity="sha384-1q8mTJOASx8j1Au+a5WDVnPi2lkFfwwEAa8hDDdjZlpLegxhjVME1fgjWPGmkzs7" crossorigin="anonymous">

  <!-- Optional theme -->
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap-theme.min.css" integrity="sha384-fLW2N01lMqjakBkx3l/M9EahuwpSfeNvV63J5ezn3uZzapT0u7EYsXMjQV+0En5r" crossorigin="anonymous">

  <!-- Latest compiled and minified JavaScript -->
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/js/bootstrap.min.js" integrity="sha384-0mSbJDEHialfmuBBQP6A4Qrprq5OVfW37PRR3j5ELqxss1yVqOtnepnHVP9aJ7xS" crossorigin="anonymous"></script>

  <link rel="stylesheet" href="board-user.component.css">

  <script src="board-user.component.ts"></script>
</head>



<body id="noprint">

<nav class="navbar navbar-default navbar-fixed-top">
  <div class="container-fluid">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only">Navigazione</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" id="dashboard">{{services}}</a>
    </div>

    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav">
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li>
          <select name="languages" id="lang" style="height:1.5em; margin-top: 14px" (change)="changeLanguage($event)">
            <option value="English" [selected]="selected">{{eng}}</option>
            <option value="Italian" [selected]="!selected">{{ita}}</option>
          </select>
        </li>
        <li><a class="link" (click)="goback()" style="color: #197F92; font-size: 18px;">{{back}}</a></li>
        <li><a href class="nav-link" id="logout_btn" (click)="logout()" style="color: #197F92; font-size: 18px;">{{out}}</a></li>
      </ul>
    </div><!-- /.navbar-collapse -->
  </div><!-- /.container-fluid -->
</nav>

<div class="container-fluid" style="background-color: #C8E0E5">

  <div class="row indicator box">
    <div class="col-sm-12">
      <div class="row logo">
        <img src="../../../assets/logo/barraloghipiccoli.png" width=100%>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>

  <div class="row boxbottom copyright">
    <p class="text-center">
      Copyright <span class="glyphicon glyphicon-copyright-mark"></span> 2020<br>
      <a>ASC Division</a> Tutti i diritti riservati.</p>
  </div>

</div>
</body>

