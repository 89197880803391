<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-md-5 col-sm-8">
        <h3 class="title">FLEET MANAGEMENT MAP</h3>
      </div>
      <div class="col-md-4 col-sm-8 clearfix map-legend">
        <img src="../../../assets/markers/vol_Cesine.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px">
        <img src="../../../assets/markers/vol_Ugento.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px">
        <img src="../../../assets/markers/vol_Cuturi.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px">
        <img src="../../../assets/markers/vol_Galeone.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px">
        <h4>Moving vehicles</h4>
      </div>
      <div class="col-md-2 col-sm-3 clearfix map-legend">
        <img src="../../../assets/markers/source.gif" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px">
        <h4>Fire location</h4>
      </div>
    </div>
    <div class="container">
      <div id="map"></div>
      <button id="path" class="path" (click)="hideDirs()">Hide routes <i class="fa fa-globe"></i></button>
      <button id="coord" class="coord" (click)="openDialog()">Insert fire <i class="fa fa-fire"></i></button>
      <button id="search" class="search" (click)="openEventLogs()">Fires report <i class="fa fa-search"></i></button>
      <button (click)="openDir()" id="go">Number</button>
      <button (click)="showDir()" id="show">Number</button>
      <button (click)="selectFire()" id="selectyes"></button>
      <button (click)="closeFire()" id="selectno"></button>
      <button (click)="editFire()" id="edityes"></button>
      <button (click)="closeFire()" id="editno"></button>
      <button (click)="deleteFire()" id="deleteyes"></button>
      <button (click)="closeFire()" id="deleteno"></button>
      <table id="t01">
        <tbody>
        <tr>
          <th>Team icon</th>
          <th>Team name</th>
          <th>Monitored district</th>
          <th>View messages</th>
          <th>View logs</th>
        </tr>
        <tr *ngFor="let team of teams">
          <td><img [src]="team[3]" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>
          <td>{{team[1]}}</td>
          <td>{{team[2]}}</td>
          <td>
            <button (click)="openMessages(team[0], team[1], team[4])">
              <i [id]="'button' + team[4]" class="fa fa-envelope-open-o" aria-hidden="true"></i>
            </button>
          </td>
          <td>
            <button (click)="openLogs(team[0])">
              <i class="fa fa-file-text-o" aria-hidden="true"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
