<div class="row">
  <div class="col-md-12">
    <div class="row1">
      <div class="col col3"><h3 class="baby"> INDICE DI RISCHIO MEDIANO</h3></div>
      <div class="col col3"><h2 class="baby"> {{site}}</h2></div>
    </div>
    <form class="list">
      <select name="serial numbers" (change)="timeFilter(false, $event, undefined)" style="top: 7px; position: absolute; right: 25px; height:2.5em;">
        <option value="Last 24 hours">Ultime 24 ore</option>
        <option value="Last week">Ultima settimana</option>
        <option value="Last month">Ultimo mese</option>
        <option value="Last quarter">Ultimo quadrimestre</option>
        <option value="Last semester">Ultimo semestre</option>
        <option value="Last year">Ultimo anno</option>
      </select>
    </form>
  </div>
  <div class="col-md-4">
    <div class="container">
      <iframe [src]="trustedriskUrl" width="90%" height="220" frameborder="0"></iframe>
    </div>
  </div>
  <div class="col-md-6">
    <br>
    <div class="box details">
      <br>
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="row details-row">
            <div class="col-xs-7 text-right details-label">
              MISURE TOTALI
            </div>
            <div class="col-xs-5">
              {{totTTmeasures}}
            </div>
          </div>
          <div class="row details-row">
            <div class="col-xs-7 text-right details-label">
              MISURE RILEVATE NEL PERIODO
            </div>
            <div class="col-xs-5">
              {{totTTmeasuresbytime}}
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="row details-row">
            <div class="col-xs-7 text-right details-label">
              ALLARMI TOTALI
            </div>
            <div class="col-xs-5">
              {{totTTalarms}}
            </div>
          </div>
          <div class="row details-row">
            <div class="col-xs-7 text-right details-label">
              ALLARMI RILEVATI NEL PERIODO
            </div>
            <div class="col-xs-5">
              {{totTTalarmsbytime}}
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12 text-left">
    <h3 class="title">RIEPILOGO PARAMETRI DI PRE-ALLARME </h3>
    <div class="average box">
      <div class="btn-group">
        <button #2 id="sap_flow" (click) = "toggleDisplay(2, 'prealarm')" autofocus >Flusso di linfa [g/cm2 per hr]</button>
        <button #4 id="stem_hum" (click) = "toggleDisplay(4, 'prealarm')">Umidità nel gambo [%]</button>
        <button #16 id="growth_rate" (click) = "toggleDisplay(16, 'prealarm')">Tasso di crescita del tronco [mm/year]</button>
        <button #14 id="ndvi" (click) = "toggleDisplay(14, 'prealarm')">NDVI</button>
        <button #12 id="green_red" (click) = "toggleDisplay(12, 'prealarm')">Verde/Rosso</button>
        <button #10 id="leaf_temp" (click) = "toggleDisplay(10, 'prealarm')">Temperatura fogliare [°C]</button>
        <button #8 id="air_temp" (click) = "toggleDisplay(8, 'prealarm')">Temperatura dell'aria [°C]</button>
        <button #6 id="air_hum" (click) = "toggleDisplay(6, 'prealarm')">Umidità relativa [%]</button>
      </div>
      <iframe [src]="trustedUrl" width="100%" height="400" frameborder="0"></iframe>
      <canvas id="average_chart"></canvas>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12 text-left">
    <h3 class="title">RIEPILOGO PARAMETRI DI ALLARME </h3>
    <div class="average box">
      <div class="btn-group">
        <button #24 id="carbon_dioxide" (click) = "toggleDisplay(24, 'alarm')">Diossido di carbonio [µg/m3]</button>
        <button #25 id="ozone" (click) = "toggleDisplay(25, 'alarm')">Ozono [µg/m3]</button>
        <button #26 id="pm_2.5" (click) = "toggleDisplay(26, 'alarm')">Particolato atmosferico 2.5 [µg/m3]</button>
        <button #27 id="pm_10" (click) = "toggleDisplay(27, 'alarm')">Particolato atmosferico 10 [µg/m3]</button>
        <button #28 id="flame_pulses" (click) = "toggleDisplay(28, 'alarm')">Impulsi di fiamma</button>
        <button #31 id="flame_flag" (click) = "toggleDisplay(31, 'alarm')">Flag di fiamma</button>
        <button #29 id="trunk_mov" (click) = "toggleDisplay(29, 'alarm')">Movimento dell'asse del tronco [rad/s]</button>
      </div>
      <iframe [src]="trusted1Url" width="100%" height="400" frameborder="0"></iframe>
      <canvas id="average1_chart"></canvas>
    </div>
  </div>
</div>
