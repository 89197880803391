<div id="download" mat-dialog-content>
  <p> {{head}}</p>
  <p *ngFor="let message of rows">{{message}}</p>
</div>
<p>Insert Sinapsi ID: </p>
<mat-form-field class="full-width" appearance="fill" style="width:195px; height: 20px">
  <mat-label></mat-label>
  <input maxlength="15" matInput (change)="takeSinapsiId($event)" autofocus>
</mat-form-field>
<br>
<br>
<mat-form-field appearance="fill">
  <mat-label>Choose a date </mat-label>
  <input matInput [matDatepicker]="picker" (dateChange)="dateChange($event)">
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<button mat-button (click)="searchLogs()"><i class="fa fa-search"></i></button>
<div mat-dialog-actions>
  <button mat-button (click)="download()">Download</button>
  <button mat-button (click)="onNoClick()">Close</button>
</div>

