import { Injectable } from '@angular/core';
import {io} from 'socket.io-client';
import { Observable } from 'rxjs';
import * as Rx from 'rxjs';
import {WS_URL} from '../env';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  // Our socket connection
  private socket;

  constructor() { }
  connect(value): Rx.Subject<MessageEvent> {
    // If you aren't familiar with environment variables then
    // you can hard code `environment.ws_url` as `http://localhost:5001`
    this.socket = io(`${WS_URL}`);

    // We define our observable which will observe any incoming messages
    // from our socket.io server.
    // tslint:disable-next-line:no-shadowed-variable
    const observable = new Observable(observer => {
      switch (value) {
        case 'event': {
          this.socket.on('event', (data) => {
            console.log('Received event from Websocket Server');
            observer.next(data);
          });
          break;
        }
        case 'msg': {
          this.socket.on('Operator_message', (data) => {
            console.log('Received message from Websocket Server');
            observer.next(data);
          });
          break;
        }
        case 'startalarm': {
          this.socket.on('Operator_Startalarm', (data) => {
            console.log('Received message from Websocket Server');
            observer.next(data);
          });
          break;
        }
        case 'interventionalarm': {
          this.socket.on('Operator_Interventionalarm', (data) => {
            console.log('Received message from Websocket Server');
            observer.next(data);
          });
          break;
        }
        case 'stopalarm': {
          this.socket.on('Operator_Stopalarm', (data) => {
            console.log('Stop alarm from Websocket Server');
            observer.next(data);
          });
          break;
        }
      }
      return () => {
        this.socket.disconnect();
      };
    });

    // We define our Observer which will listen to messages
    // from our other components and send messages back to our
    // socket server whenever the `next()` method is called.
    const observer = {
      next: (data) => {
        console.log(data);
        if (data.event !== undefined) {
          this.socket.emit('alarm', JSON.stringify(data));
        }
        if (data.chat_message !== undefined) {
          this.socket.emit('chat_message', JSON.stringify(data));
        }
        else {
          this.socket.emit('message', JSON.stringify(data));
        }
      },
    };

    // we return our Rx.Subject which is a combination
    // of both an observer and observable.
    return Rx.Subject.create(observer, observable);
  }

}
