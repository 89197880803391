import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import Map from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import {createStringXY, toStringXY} from 'ol/coordinate';
import {get as getProjection, transform} from 'ol/proj';
import proj4 from 'proj4';
import {register} from 'ol/proj/proj4';
import 'ol/ol.css';
import TileGrid from 'ol/tilegrid/TileGrid';
import {getWidth} from 'ol/extent';
import Overlay from 'ol/Overlay';
import {FIRE_BEHAVIOUR_URL} from '../../env';

export interface DialogData {
  param: string;
  description: string;
}
@Component({
  selector: 'app-fire-behaviour',
  templateUrl: './fire-behaviour.component.html',
  styleUrls: ['./fire-behaviour.component.css']
})
export class FireBehaviourComponent implements OnInit {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, public dialog: MatDialog) {
  }
  /*map*/
  map: any;
  loaded: boolean;
  /*buttons focus*/
  focused = 'BurnProbability';
  indexvalue = 'BurnProbability';
  bool = false;
  /*param selected in radio buttons*/
  radio: any;
  /*flammap is true if flammap is selected, false otherwise*/
  flammap: boolean;
  /*place that is Apulia or Epirus*/
  place: any;
  /*layer to show map*/
  tilesource: any;
  layer: any;
  layerApulia: any;
  layerEpirus: any;
  /*second layer to show two maps*/
  tilelayer: any;
  tilesource1: any;
  /*marker*/
  marker: any;
  feature: any;
  iconstyle: any;
  /*Elements that make up the popup*/
  container: any;
  content: any;
  closer: any;
  overlay: any;
  /*coordinates on click*/
  coordinates: any;
  /*variable*/
  variable: any;

  /*select Apulia map, Epirus map, or together*/
  changeMapZone(event){
    this.container.style.display = 'none';
    this.place = event.target.value;
    switch (this.indexvalue) {
      case 'BurnProbability':
        this.layerApulia = 'WeightedMean_BP';
        this.layerEpirus = 'BP';
        break;
      case 'ConditionalFlameLength':
        this.layerApulia = 'WeightedMean_CFL';
        this.layerEpirus = 'CFL';
        break;
      case 'FireSize':
        this.layerApulia = 'WeightedMean_FS';
        this.layerEpirus = 'FS';
        break;
      case 'FirePotentialIndex':
        this.layerApulia = 'WeightedMean_FPI';
        this.layerEpirus = 'FPI';
        break;
      case 'CampbellAnalysis':
        this.layerApulia = 'WeightedMeanCampbell';
        this.layerEpirus = 'Campbell';
        break;
      case 'FlameLength':
        this.layerApulia = 'WeightedMeanFL';
        this.layerEpirus = 'FL';
        break;
      case 'RateOfSpread':
        this.layerApulia = 'WeightedMeanROS';
        this.layerEpirus = 'ROS';
        break;
      case 'FireIntensity':
        this.layerApulia = 'WeightedMeanFI';
        this.layerEpirus = 'FI';
        break;
      case 'SuppressionCapacity':
        this.layerApulia = 'WeightedMeanSupCapacity';
        this.layerEpirus = 'SupCapacity';
        break;
    }
    this.map.removeLayer(this.tilelayer);
    /*Apulia maps*/
    if (this.place === 'Apulia') {
      this.map.getView().setCenter([1866305.86263, 5018067.77048]);
      this.map.getView().setZoom(8);
      this.layer = this.layerApulia;
    }
    else {
      /*Epirus maps*/
      if (this.place === 'Epiro') {
        this.map.getView().setCenter([2318749.0815, 4817166.79425]);
        this.map.getView().setZoom(8);
        this.layer = this.layerEpirus;
      }
      /*Apulia and Epirus maps*/
      else {
        this.map.getView().setCenter([2120818.9449258074, 4886459.148650684]);
        this.map.getView().setZoom(7);
        this.layer = this.layerApulia;
        this.tilelayer = new TileLayer({
          source: new TileWMS({
            url: 'http://vs.ofidia.eu:8080/geoserver/wms',
            params: {LAYERS: 'firebehaviour:' + this.layerEpirus, TILED: true},
            serverType: 'geoserver',
          })
        });
        this.map.addLayer(this.tilelayer);
      }
    }
    this.tilesource.updateParams({LAYERS: 'firebehaviour:' + this.layer, TILED: true});
    this.getCoordinates();
    this.map.render();
  }

  /*change fire behaviour index map*/
  changeMap(value) {
    // document.getElementById('popup').style.display = 'none';
    this.indexvalue = value;
    if (this.focused !== value) {
      document.getElementById(this.focused).style.borderWidth = '1px';
      document.getElementById(value).style.borderWidth = '2px';
      this.focused = value;
    }
    switch (this.indexvalue) {
      case 'BurnProbability':
        this.layerApulia = 'WeightedMean_BP';
        this.layerEpirus = 'BP';
        this.variable = 'BPsim';
        break;
      case 'ConditionalFlameLength':
        this.layerApulia = 'WeightedMean_CFL';
        this.layerEpirus = 'CFL';
        break;
      case 'FireSize':
        this.layerApulia = 'WeightedMean_FS';
        this.layerEpirus = 'FS';
        break;
      case 'FirePotentialIndex':
        this.layerApulia = 'WeightedMean_FPI';
        this.layerEpirus = 'FPI';
        break;
      case 'CampbellAnalysis':
        this.layerApulia = 'WeightedMeanCampbell';
        this.layerEpirus = 'Campbell';
        break;
      case 'FlameLength':
        this.layerApulia = 'WeightedMeanFL';
        this.layerEpirus = 'FL';
        break;
      case 'RateOfSpread':
        this.layerApulia = 'WeightedMeanROS';
        this.layerEpirus = 'ROS';
        break;
      case 'FireIntensity':
        this.layerApulia = 'WeightedMeanFI';
        this.layerEpirus = 'FI';
        break;
      case 'SuppressionCapacity':
        this.layerApulia = 'WeightedMeanSupCapacity';
        this.layerEpirus = 'SupCapacity';
        break;
    }
    this.map.removeLayer(this.tilelayer);
    if (this.place === 'Apulia') {
      this.layer = this.layerApulia;
    }
    else {
      /*Epirus maps*/
      if (this.place === 'Epiro') {
        this.layer = this.layerEpirus;
      }
      /*Apulia and Epirus maps*/
      else {
        this.layer = this.layerApulia;
        this.tilelayer = new TileLayer({
          source: new TileWMS({
            url: 'http://vs.ofidia.eu:8080/geoserver/wms',
            params: {LAYERS: 'firebehaviour:' + this.layerEpirus, TILED: true},
            serverType: 'geoserver',
          })
        });
        this.map.addLayer(this.tilelayer);
      }
    }
    this.tilesource.updateParams({LAYERS: 'firebehaviour:' + this.layer, TILED: true});
    this.getCoordinates();
    this.map.render();
  }
  /*choosing between Flammap or WFA*/
  changeMaps(event){
    // document.getElementById('popup').style.display = 'none';
    if (event.value === '1')
    {
      this.radio = 'Flammap';
      this.focused = 'BurnProbability';
      this.indexvalue = 'BurnProbability';
      this.flammap = true;
      this.map.removeLayer(this.tilelayer);
      if (this.place === 'Apulia') {
        this.layer = 'WeightedMean_BP';
      }
      else {
        if (this.place === 'Epiro') {
          this.layer = 'BP';
        }
        else {
          this.layer = 'WeightedMean_BP';
          this.layerEpirus = 'BP';
          this.tilelayer = new TileLayer({
            source: new TileWMS({
              url: 'http://vs.ofidia.eu:8080/geoserver/wms',
              params: {LAYERS: 'firebehaviour:BP', TILED: true},
              serverType: 'geoserver',
            })
          });
          this.map.addLayer(this.tilelayer);
        }
      }
    }
    else {
      this.radio = 'WFA';
      this.focused = 'CampbellAnalysis';
      this.indexvalue = 'CampbellAnalysis';
      this.flammap = false;
      this.map.removeLayer(this.tilelayer);
      if (this.place === 'Apulia') {
        this.layer = 'WeightedMeanCampbell';
      }
      else {
        if (this.place === 'Epiro') {
          this.layer = 'Campbell';
        }
        else {
          this.layer = 'WeightedMeanCampbell';
          this.layerEpirus = 'Campbell';
          this.tilelayer = new TileLayer({
            source: new TileWMS({
              url: 'http://vs.ofidia.eu:8080/geoserver/wms',
              params: {LAYERS: 'firebehaviour:Campbell', TILED: true},
              serverType: 'geoserver',
            })
          });
          this.map.addLayer(this.tilelayer);
        }
      }
    }
    this.tilesource.updateParams({LAYERS: 'firebehaviour:' + this.layer, TILED: true});
    this.getCoordinates();
    this.map.render();
  }
  /**/
  getCoordinates() {
    this.container.style.display = 'block';
    this.overlay = new Overlay({
      element: this.container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    const coord = transform(this.coordinates, 'EPSG:3857', 'EPSG:3035');
    let param;
    if (this.place !== 'All') {
      // const out = toStringXY(coord);
      if (this.coordinates) {
        if (this.overlay) {
          param = this.layer;
        }
      }
    }
    else {
      if (this.coordinates) {
        if (this.overlay) {
          if (coord[0] < 5100000) {
            param = this.layerApulia;
          }
          else {
            param = this.layerEpirus;
          }
        }
      }
    }
    console.log(param, coord);
    const url = `${FIRE_BEHAVIOUR_URL}` + param + '&lat=' + coord[0] + '&lng=' + coord[1];
    // tslint:disable-next-line:max-line-length
    this.http.get(url).subscribe(
      response => {
        // document.getElementById('info').innerHTML = html + out;
        this.content.innerHTML = '<p>The value is:</p><code>' + response[`value`] + '</code>';
        this.overlay.setPosition(this.coordinates);
        this.map.addOverlay(this.overlay);
      });
  }
  /*open Info on buttons*/
  openDialog(param, description): void {
    const dialogRef = this.dialog.open(InfoDialogFBComponent, {
      width: '250px',
      data: {param, description}
    });
  }

  ngOnInit() {
    this.place = 'Apulia';
    this.layer = 'WeightedMean_BP';
    this.coordinates = transform([16.87774658, 40.94256444], 'EPSG:4326',
      'EPSG:3857');
    this.flammap = true;
    this.tilesource = new TileWMS({
      url: 'http://vs.ofidia.eu:8080/geoserver/wms',
      params: {LAYERS: 'firebehaviour:' + this.layer, TILED: true},
      serverType: 'geoserver',
    });
    this.tilesource1 = new TileWMS({
      url: 'http://vs.ofidia.eu:8080/geoserver/wms',
      params: {LAYERS: 'firebehaviour:' + this.layer, TILED: true},
      serverType: 'geoserver',
    });
    /*initialize layer for Apulia and Epirus maps*/
    this.tilelayer = new TileLayer({
      source: this.tilesource1
    });
    /*initialize marker icon*/
    this.iconstyle = new Style({
      image: new Icon(({
        // anchor: [0.5, 46],
        // anchorXUnits: 'fraction',
        // anchorYUnits: 'pixels',
        // opacity: 0.75,
        src: '../../../assets/markers/flag.png'
      }))
    });
    /*initialize marker*/
    this.feature = new Feature({
      geometry: new Point(transform([16.87774658, 40.94256444], 'EPSG:4326',
        'EPSG:3857')),
      name: 'My Marker'
    });

    this.marker = new VectorLayer({
      source: new VectorSource({
        features: [
          this.feature
        ]
      }),
      style: this.iconstyle
    });
    /*loading layer on map*/
    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM()
        }),
        new TileLayer({
          source: this.tilesource,
        }),
        this.marker
      ],
      // overlays: [this.overlay],
      view: new View({
        center: [1866305.86263, 5018067.77048],
        zoom: 8,
        minZoom: 2,
        maxZoom: 19
      })
    });
    // this.map.addLayer(this.marker);
    /*loading map*/
    this.loaded = true;
    setTimeout(() => {
      this.map.setTarget(document.getElementById('map'));
    }, 1000);
    proj4.defs('EPSG:3035',
      '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +units=m +no_defs');
    register(proj4);
    /*Add a click handler to hide the popup*/
    window.onload = () => {
      /*Elements that make up the popup*/
      document.getElementById('popup').style.display = 'none';
      this.container = document.getElementById('popup');
      this.content = document.getElementById('popup-content');
      this.closer = document.getElementById('popup-closer');
      document.getElementById('popup-closer').onclick = () => {
        this.overlay.setPosition(undefined);
        document.getElementById('popup-closer').blur();
        return false;
      };
    };
    /*return coordinates of click event*/
    this.map.on('singleclick', evt => {
      this.coordinates = evt.coordinate;
      this.marker.setZIndex( 1001 );
      this.marker.getSource().getFeatures()[0].getGeometry().setCoordinates(this.coordinates, '');
      this.getCoordinates();
      // document.getElementById('popup-content').innerHTML = '<p>You clicked here:</p><code>' + coordinate + '</code>';
      // this.overlay.setPosition(coordinate);
      // document.getElementById('info').innerHTML = '';
      // const viewResolution = (this.map.getView().getResolution());
      // console.log(evt.coordinate);
      // const coord = transform(evt.coordinate, 'EPSG:3857', 'EPSG:3035');
      // const out = toStringXY(coord);
      // const url = this.tilesource.getFeatureInfoUrl(
      //   evt.coordinate,
      //   viewResolution,
      //   'EPSG:3857',
      //   {INFO_FORMAT: 'text/html'}
      // );
      // if (url) {
      //   fetch(url).then(response => response.text()).then(html => {
      //     document.getElementById('info').innerHTML = html + out;
      //   }); }
    });
    /*calculate bounding box on zooming map*/
    this.map.getView().on('change:resolution', (event) => {
      console.log(this.map.getView().calculateExtent());
    });
    this.map.on('pointermove', evt => {
      // When user was dragging map, then coordinates didn't change and there's
      // no need to continue
      if (evt.dragging) {
        return;
      }
      const coord = transform(evt.coordinate, 'EPSG:3857', 'EPSG:3035');
      document.getElementById('info').innerHTML = 'Lat, Lon coordinates: ' + coord;
      // You can access coordinates from evt.coordinate now
    });
  }
}
@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'infodialogs.html',
})
export class InfoDialogFBComponent {

  constructor(
    public dialogRef: MatDialogRef<InfoDialogFBComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
}
