<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<header>
  <div id = "log-bar" class="icon-bar">
    <a class="image"><img src="../../../assets/logo/barra.png" width=100%></a>
  </div>
  <div class="top-nav" style="text-align:center">
    <img src="assets/DSS.png" align="center">
    <button class="btn login" align="center" (click)="openLogin('mat-login')">Login</button>
  </div>
</header>
<div class="map" style="text-align:center">
  <img src="assets/cartina_grecia_italia.jpg" align="center" width=100.3%>
  <mat-card class="example-card" id="mat-login" layout="row" layout-align="center center">
    <mat-card-title>
      Login
    </mat-card-title>
    <form
      *ngIf="!isLoggedIn"
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
    >
    <mat-card-content>
      <mat-form-field>
        <input matInput placeholder="User Name"
               type="text"
               class="form-control"
               name="username"
               [(ngModel)]="form.username"
               required
               #username="ngModel"
        >
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="f.submitted && username.invalid"
        >
          Username is required!
        </div>
      </mat-form-field>
      <br/>
      <mat-form-field>
        <input type="password" matInput placeholder="Password"
               class="form-control"
               name="password"
               [(ngModel)]="form.password"
               required
               minlength="6"
               #password="ngModel"
        >
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="f.submitted && password.invalid"
        >
          <div *ngIf="!password">Password is required</div>
        </div>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <div class="button-flex-container">
        <button mat-raised-button class="btn login">Login</button>
      </div>
    </mat-card-actions>
      <div class="form-group">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="f.submitted && isLoginFailed"
        >
          Login failed: {{ errorMessage }}
        </div>
      </div>
    </form>
    <div class="alert alert-success" *ngIf="isLoggedIn">
      Logged in as {{ role }}.
      {{goToPage()}}
    </div>
  </mat-card>
</div>

<footer>
  <div class="bottom-nav" style="text-align:center">
  </div>
</footer>
