import {Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import MapTypeId = google.maps.MapTypeId;
import {DomSanitizer} from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {TokenStorageService} from '../../services/token-storage.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-camera-ita',
  templateUrl: './camera-ita.component.html',
  styleUrls: ['./camera-ita.component.css']
})
export class CameraItaComponent implements OnInit {
  dangerousUrl: any;
  trustedUrl: any;
  isLoading: boolean;
  public flagmap = false;
  // tslint:disable-next-line:max-line-length
  constructor(private sanitizer: DomSanitizer, private http: HttpClient, private tokenStorageService: TokenStorageService, private router: Router) { }
  /*role is admin or park manager or operator*/
  role: any;
  usersite: any;

  ngOnInit(): void {
    /*taking the role of user*/
    this.role = this.tokenStorageService.getUser().role;
    this.usersite = this.tokenStorageService.getUser().site;
    if (this.router.url === '/parkmanager/cameraCuturi_ita') {
      this.trustedUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl('http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=2560x1440&camera=quad');
    }
    else {
      this.trustedUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(localStorage.getItem('camera'));
    }
    // prima prova
    // const iframeURL = 'http://lecesine.mooo.com:9000';
    // const iframeID = 'MyIFrame';
    // const req = new XMLHttpRequest();
    // req.open('POST', iframeURL, false, 'cmcc', 'cmcc@2020'); // use POST to safely send combination
    // req.setRequestHeader('Access-Control-Allow-Origin', '*');
    // req.send(null); // here you can pass extra parameters through
    //
    // // set iFrame's SRC attribute
    // const iFrameWin = document.getElementById(iframeID) as HTMLIFrameElement;
    // iFrameWin.src = iframeURL + '?extraParameters=true';
    // seconda prova
    // const username = 'cmcc';
    // const password = 'cmcc@2020';
    // const headers = new HttpHeaders(
    // { 'Access-Control-Allow-Origin' : '*',
    // Authorization: 'Basic ' + btoa(`${username}:${password}`)
    // });
    //
    // const url = 'http://lecesine.mooo.com:9000/#view';
    // this.http.get(url, {headers})
    // .subscribe(
    // data => console.log(data),
    // error => console.log(error)
    // );
  }
}

