<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <h3 class="title">WEATHER STATIONS MAP </h3>
      </div>
      <div class="col-md-2 col-sm-3 clearfix map-legend">
        <img src="../../../assets/markers/weather-station.png" class="img-responsive pull-left map-legend-icon">
        <h4>   Weather station</h4>
      </div>
    </div>
    <div class="container">
      <google-map #googlemap *ngIf="markers; else stillLoading"
                  height="500px"
                  width="100%"
                  [zoom]="zoom"
                  [center]="center"
                  [mapTypeId]="type"
                  (tilesloaded)="handleOnTilesLoaded()">
        <map-marker
          #markerElem
          *ngFor="let marker of markers"
          [position]="marker.position"
          [label]="marker.label"
          [title]="marker.title"
          [options]="marker.options"
          (mapClick)="openInfo(markerElem, marker.title)"
        >
        </map-marker>
        <map-info-window>
          <h4>WEATHER STATION: {{name}}</h4>
          <h5>Last update: {{lastupdate}}</h5>
          <table style="width:100%">
            <tr>
              <td width="50%">Total Precipitation Today</td>
              <td width="50%">{{totalprec}} mm</td>
            </tr>
            <tr>
              <td width="50%">Precipitation Intensity</td>
              <td width="50%">{{precintensity}} mm/min</td>
            </tr>
            <tr>
              <td width="50%">Air Temperature</td>
              <td width="50%">{{temperature}} °C</td>
            </tr>
            <tr>
              <td width="50%">Relative Humidity</td>
              <td width="50%">{{humidity}} %</td>
            </tr>
            <tr>
              <td width="50%">Solar Radiation</td>
              <td width="50%">{{radiation}} W/m2</td>
            </tr>
            <tr>
              <td width="50%">Wind Direction</td>
              <td width="50%">{{winddirection}} deg</td>
            </tr>
            <tr>
              <td width="50%">Wind Speed</td>
              <td width="50%">{{windspeed}} m/s</td>
            </tr>
          </table>
        </map-info-window>
      </google-map>
      <ng-template #stillLoading><p>Loading...</p></ng-template>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12 text-left">
    <h3 class="title">PARAMETERS IN THE LAST MONTH </h3>
    <div class="average box">
      <div class="btn-group">
        <div class="boxbuttons" #2 id="tot_prec">
          <button class="button1" (click) = "toggleDisplay('tot_prec',2)">Total precipitation [mm]</button>
          <button class="button2" (click)="openDialog('Total precipitation ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" #5 id="prec_int">
          <button class="button1" (click) = "toggleDisplay('prec_int',5)">Precipitation intensity [mm/min]</button>
          <button class="button2" (click)="openDialog('Precipitation intensity ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" #9 id="temp">
          <button class="button1" (click) = "toggleDisplay('temp',9)">Air temperature [°C]</button>
          <button class="button2" (click)="openDialog('Air temperature ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" #10 id="rel_hum">
          <button class="button1" (click) = "toggleDisplay('rel_hum',10)">Relative humidity [%]</button>
          <button class="button2" (click)="openDialog('Relative humidity ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" #8 id="sol_rad">
          <button class="button1" (click) = "toggleDisplay('sol_rad',8)">Solar radiation [W/m2]</button>
          <button class="button2" (click)="openDialog('Solar radiation ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" #6 id="wind_dir">
          <button class="button1" (click) = "toggleDisplay('wind_dir',6)">Wind direction [deg]</button>
          <button class="button2" (click)="openDialog('Wind direction ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" #7 id="wind_speed">
          <button class="button1" (click) = "toggleDisplay('wind_speed',7)">Wind speed [m/s]</button>
          <button class="button2" (click)="openDialog('Wind speed ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
      </div>
      <iframe id="Dashboard1" [src]="trustedUrl" width="100%" height="400" frameborder="0"></iframe>
      <canvas id="average_chart"></canvas>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12 text-left">
    <h3 class="title">DETECTED MEASUREMENTS </h3>
    <div class="average box">
      <iframe [src]="trusted1Url" width="100%" height="490" frameborder="0"></iframe>
    </div>
  </div>
</div>


