<div class="row">
  <div class="col-sm-12">
    <div class="container">
      <div *ngIf="loaded">
        <div id="map" [ngClass]="{'map': loaded}"></div>
        <div id="popup" class="ol-popup">
          <a href="#" id="popup-closer" class="ol-popup-closer"></a>
          <div id="popup-content"></div>
        </div>
      </div>
      <form class="select">
        <select name="options" id="select" (change)="changeMapZone($event)" style="height:2.5em;">
          <option value="Apulia">Apulia</option>
          <option value="Epiro">Epiro</option>
          <option value="All">All</option>
        </select>
      </form>
      <div class="list">
        <div class='legend-scale'>
          <div [ngSwitch]="indexvalue">
            <div *ngSwitchCase="'BurnProbability'">
              <img src="assets/legends/BP_legend.png" style="width: 140px">
            </div>
            <div *ngSwitchCase="'ConditionalFlameLength'">
              <img src="assets/legends/CFL_legend.png" style="width: 200px">
            </div>
            <div *ngSwitchCase="'FireSize'">
              <img src="assets/legends/FS_legend.png" style="width: 130px">
            </div>
            <div *ngSwitchCase="'FirePotentialIndex'">
              <img src="assets/legends/FPI_legend.png" style="width: 140px">
            </div>
            <div *ngSwitchCase="'CampbellAnalysis'">
              <img src="assets/legends/Campbell_legend.png" style="width: 140px">
            </div>
            <div *ngSwitchCase="'FlameLength'">
              <img src="assets/legends/FL_legend.png" style="width: 130px">
            </div>
            <div *ngSwitchCase="'RateOfSpread'">
              <img src="assets/legends/ROS_legend.png" style="width: 170px">
            </div>
            <div *ngSwitchCase="'FireIntensity'">
              <img src="assets/legends/FI_legend.png" style="width: 170px">
            </div>
            <div *ngSwitchCase="'SuppressionCapacity'">
              <img src="assets/legends/SupCapacity_legend.png" style="width: 320px">
            </div>
            <div *ngSwitchDefault>
              <img src="assets/legends/BP_legend.png" style="width: 140px">
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="margin-left: 4px">
        <mat-radio-group id="mat-radio-buttons" color="primary" aria-label="Select an option" (change)="changeMaps($event)">
          <mat-radio-button value="1" checked style="font-size: small;">Flammap</mat-radio-button>
          <mat-radio-button value="2" style="font-size: small;">WFA</mat-radio-button>
        </mat-radio-group>
      </div>
      <br>
      <div *ngIf="flammap" class="btn-group pull-left">
        <div class="boxbuttons" id="BurnProbability" style="margin-right: 4px; border-width: 2px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('BurnProbability')">Burn Probability</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Conditional Burn Probability ','for a given pixel is an estimate of the likelihood that the pixel will burn given an ignition within the study area, while considering burn conditions similar to the historical fires. BP is defined as BP = F∕n, where F is the number of times a pixel burns and n is the number of simulated fires.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="ConditionalFlameLength" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('ConditionalFlameLength')">Conditional Flame Length</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Conditional Flame Length ','is expressed in m and it is the probability weighted flame length given a fire occurs and is a measure of wildfire hazard.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="FireSize" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('FireSize')">Fire Size</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Fire Size ','is expressed in ha and it is a text file used to analyze spatial variation in the size of simulated fires.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="FirePotentialIndex" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('FirePotentialIndex')">Fire Potential Index</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Fire Potential Index ','is generated based on fire size and historical ignition locations FPI = FS × IP where FS is the average fire size for all fires that originated from a given pixel and IP is the historical ignition probability determined from the smoothed map of ignitions.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
      </div>
      <div *ngIf="!flammap" class="btn-group pull-left">
        <div class="boxbuttons" id="CampbellAnalysis" style="margin-right: 4px; border-width: 2px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('CampbellAnalysis')">Campbell Analysis</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Campbell Analysis ','Three major forces heavily influence variations in speed and intensity of wildland fires: wind, slope and the preheating of fuels. Wind and slope both act on a fire by changing the angle and orientation of the flame with respect to the fuel bed. Preheat is a force that alters the fire’s intensity. Solar heated fuels ignite more readily than cooler fuels. Solar preheating is a force multiplier when applied to the wind and slope force vectors. These potent forces can work in cooperation or against each other. When these three forces align, the fire is going to be at its maximum intensity. When these forces are not in alignment, the intensity is lower than the maximum. This is the alignment of forces concept. Forces that complement each other (producing a cumulative effect) are said to be “in alignment.” When forces are not aligned and are below their maximum potential to aid the spread or intensity of the fire, the forces are then “out of alignment.” Each side of a fire, the head, flanks and heel, has a different alignment and intensity. For example, if the head is in alignment with the wind, then the flanks and heel will be out of alignment.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="FlameLength" style="margin-right: 10px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('FlameLength')">Flame Length</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Flame Length ','is expressed in m and it is the distance measured from the average flame tip to the middle of the flaming zone at the base of the fire. It is measured on a slant when the flames are tilted due to effects of wind and slope. Flame length is an indicator of fireline intensity.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="RateOfSpread" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('RateOfSpread')">Rate Of Spread</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Rate Of Spread ','is expressed in m/s and it is defined as the speed with which the fire is moving away from the site of origin.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="FireIntensity" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('FireIntensity')">Fire Intensity</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Fire Intensity ','is expressed in MW/m and it represents the heat released per meter of fire front (kW/m of fire front). It is a function of (1) heat yield of fuel (kilojoules/kg), (2) amount of fuel per unit area (kg/m2) and (3) the rate of forward spread of fire front (km/h).')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
        <div class="boxbuttons" id="SuppressionCapacity" style="margin-right: 4px">
          <button class="button1" [disabled]="bool" (click) = "changeMap('SuppressionCapacity')">Suppression Capacity</button>
          <button class="button2" [disabled]="bool" (click)="openDialog('Suppression Capacity ','can be interpreted in terms of suppression capacity. Flame lengths up to 1.2 m can be handled directly by persons with hand tools whereas up to 2.4 m equipment such as dozers, pumpers and retardant aircraft can be more adequate. With flame lengths between 2.4 and 3.4 m, spotting, crowning and torching might occur creating serious problems for fire control. Beyond 3.4 m control efforts at the front are ineffective.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
        </div>
      </div>
      <div id="info"></div>
    </div>
  </div>
</div>

