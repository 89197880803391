<div class="row">
    <div class="col-md-12">
      <h3 class="title">SENSOR DETAILS</h3>
      <form class="list">
        <select name="serial numbers" (change)="timeFilter(false, $event, undefined)" style="top: 7px; position: absolute; right: 25px; height:2.5em;">
          <option value="Last 24 hours">Last 24 hours</option>
          <option value="Last week">Last week</option>
          <option value="Last month">Last month</option>
          <option value="Last quarter">Last quarter</option>
          <option value="Last semester">Last semester</option>
          <option value="Last year">Last year</option>
        </select>
      </form>
    </div>
    <div class="col-md-4">
      <div class="container">
        <google-map
          height="250px"
          width="100%"
          [zoom]="zoom"
          [center]="markercenter"
          [mapTypeId]= "type">
          <map-marker
            #markerElem
            [position]="sensormarker.position"
            [label]="sensormarker.label"
            [title]="sensormarker.title"
            [options]="sensormarker.options"
          >
            <map-circle
              [radius]="10000"
              [options]="circle.options"
              [center]="markercenter"
            >
            </map-circle>
          </map-marker>
        </google-map>
      </div>
    </div>
    <div class="col-md-8">
      <div class="box details">
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <div class="row details-row">
              <div class="col-xs-7 text-right details-label">
                SERIAL
              </div>
              <div class="col-xs-5">
                {{serial}} - {{ttfire}}
              </div>
            </div>
            <div class="row details-row">
              <div class="col-xs-7 text-right details-label">
                SITE
              </div>
              <div class="col-xs-5">
                {{site}}
              </div>
            </div>
            <div class="row details-row">
              <div class="col-xs-7 text-right details-label">
                LATITUDE
              </div>
              <div class="col-xs-5">
                {{latitude}}
              </div>
            </div>
            <div class="row details-row">
              <div class="col-xs-7 text-right details-label">
                LONGITUDE
              </div>
              <div class="col-xs-5">
                {{longitude}}
              </div>
            </div>
            <div class="row details-row">
              <div class="col-xs-7 text-right details-label">
                LAST UPDATE
              </div>
              <div class="col-xs-5">
                {{lastupdate}}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="row details-row">
              <div class="col-xs-7 text-right details-label">
                TOTAL MEASURES
              </div>
              <div class="col-xs-5">
                {{totTTmeasures}}
              </div>
            </div>
            <div class="row details-row">
              <div class="col-xs-7 text-right details-label">
                MEASURES IN THE PERIOD
              </div>
              <div class="col-xs-5">
                {{totTTmeasuresbytime}}
              </div>
            </div>
            <div class="row details-row">
              <div class="col-xs-7 text-right details-label">
                TOTAL ALARMS
              </div>
              <div class="col-xs-5">
                {{totTTalarms}}
              </div>
            </div>
            <div class="row details-row">
              <div class="col-xs-7 text-right details-label">
                ALARMS IN THE PERIOD
              </div>
              <div class="col-xs-5">
                {{totTTalarmsbytime}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-left">
      <h3 class="title">PRE-ALARM PARAMETERS SUMMARY </h3>
      <div class="average box">
        <div class="btn-group">
          <button #2 id="sap_flow" (click) = "toggleDisplay(2, 'prealarm')" autofocus >Sap flow [g/cm2 per hr]</button>
          <button #5 id="stem_hum" (click) = "toggleDisplay(5, 'prealarm')">Stem water Content [%]</button>
          <button #6 id="growth_rate" (click) = "toggleDisplay(6, 'prealarm')">Growth Rate [mm/year]</button>
          <button #8 id="ndvi" (click) = "toggleDisplay(8, 'prealarm')">NDVI</button>
          <button #32 id="green_red" (click) = "toggleDisplay(32, 'prealarm')">Green/Red</button>
          <button #7 id="leaf_temp" (click) = "toggleDisplay(7, 'prealarm')">Leaf Temperature [°C]</button>
          <button #9 id="air_temp" (click) = "toggleDisplay(9, 'prealarm')">Air Temperature [°C]</button>
          <button #10 id="air_hum" (click) = "toggleDisplay(10, 'prealarm')">Air Relative Humidity [%]</button>
        </div>
        <iframe [src]="trustedUrl" width="100%" height="400" frameborder="0"></iframe>
        <canvas id="average_chart"></canvas>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-left">
      <h3 class="title">ALARM PARAMETERS SUMMARY </h3>
      <div class="average box">
        <div class="btn-group">
          <button #11 id="carbon_dioxide" (click) = "toggleDisplay(11, 'alarm')" >Carbon Dioxide [µg/m3]</button>
          <button #12 id="ozone" (click) = "toggleDisplay(12, 'alarm')">Ozone [µg/m3]</button>
          <button #13 id="pm_2.5" (click) = "toggleDisplay(13, 'alarm')">Atmospheric particulate matter 2.5 [µg/m3]</button>
          <button #14 id="pm_10" (click) = "toggleDisplay(14, 'alarm')">Atmospheric particulate matter 10 [µg/m3]</button>
          <button #15 id="flame_pulses" (click) = "toggleDisplay(15, 'alarm')">Flame pulses </button>
          <button #31 id="flame_flag" (click) = "toggleDisplay(31, 'alarm')">Flame flag </button>
          <button #16 id="trunk_mov" (click) = "toggleDisplay(16, 'alarm')">Tree trunk axis movement [rad/s]</button>
        </div>
<!--        <p style="color: red; float: right; text-align: right;">Warning value ≥ {{warning}}</p>-->
        <iframe [src]="trusted1Url" width="100%" height="400" frameborder="0"></iframe>
        <canvas id="average1_chart"></canvas>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-left">
      <h3 class="title">DETECTED PRE-ALARM MEASUREMENTS </h3>
      <div class="average box">
        <iframe [src]="trusted2Url" width="100%" height="490" frameborder="0"></iframe>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-left">
      <h3 class="title">DETECTED ALARM MEASUREMENTS </h3>
      <div class="average box">
        <iframe [src]="trusted3Url" width="100%" height="490" frameborder="0"></iframe>
      </div>
    </div>
  </div>
