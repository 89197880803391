<button title="vedi la mappa" (click)="openmodal('custom-modal-1')" style="font-size:24px; float:right;"><i class="fa fa-map"></i></button>
<div class="menu indicator box">
  <table>
    <tbody>
    <tr>
      <td><iframe src="http://cmcc:cmcc%402020@galeone.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=320x240" width="320" height="240" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
      <td><iframe src="http://cmcc:cmcc%402020@torrecolimena.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=320x240" width="320" height="240" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
      <td><iframe src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=640x360&camera=1" width="270" height="240" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
      <td><iframe src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=640x360&camera=2" width="270" height="240" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
    </tr>
    <br>
    <tr>
      <td align="center"><button id="button_galeone" (click)="openmodal('Masseria Galeone')" font-size="12px" font-family="Arial">Masseria Galeone </button></td>
      <td align="center"><button id="button_navale" (click)="openmodal('Lega Navale')" font-size="12px" font-family="Arial">Litorale Tarantino - Lega Navale</button></td>
      <td align="center"><button id="button_cuturi1" (click)="openmodal('Masseria Cuturi camera 1')" font-size="8px" font-family="Arial">Litorale Tarantino - Masseria Cuturi - CAM1</button></td>
      <td align="center"><button id="button_cuturi2" (click)="openmodal('Masseria Cuturi camera 2')" font-size="8px" font-family="Arial">Litorale Tarantino - Masseria Cuturi - CAM2</button></td>
    </tr>
    <br>
    <tr>
      <td><iframe src="http://cmcc:cmcc%402020@ugento.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=320x240" width="320" height="240" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
      <td><iframe src="http://cmcc:cmcc%402020@lecesine.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=320x240" width="320" height="240" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
      <td><iframe src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=640x360&camera=3" width="270" height="240" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
      <td><iframe src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=640x360&camera=4" width="270" height="240" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
    </tr>
    <br>
    <tr>
      <td align="center"><button id="button_ugento" (click)="openmodal('Ugento')" font-size="12px" font-family="Arial">Ugento</button></td>
      <td align="center"><button id="button_cesine" (click)="openmodal('Cesine')" font-size="12px" font-family="Arial">Cesine</button></td>
      <td align="center"><button id="button_cuturi3" (click)="openmodal('Masseria Cuturi camera 3')" font-size="8px" font-family="Arial">Litorale Tarantino - Masseria Cuturi - CAM3</button></td>
      <td align="center"><button id="button_cuturi4" (click)="openmodal('Masseria Cuturi camera 4')" font-size="8px" font-family="Arial">Litorale Tarantino - Masseria Cuturi - CAM4</button></td>
    </tr>
    </tbody>
</table>
</div>

<div class="jw-modal-wrapper" id="Masseria Galeone">
  <div class="jw-modal">
    <div class="jw-modal-body">
      <div id="galeone">
        <iframe id="galeone-frame" src="http://cmcc:cmcc%402020@galeone.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1280x720" height="500px" width="100%" frameborder=0 scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
      </div>
      <p><button (click)="closemodal('Masseria Galeone')">Chiudi</button></p>
    </div>
  </div>
  <div class="jw-modal-background"></div>
</div>

<div class="jw-modal-wrapper" id="Masseria Cuturi">
  <div class="jw-modal">
    <div class="jw-modal-body">
      <div id="cuturi">
        <table>
          <tbody>
          <tr>
            <td><iframe src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=640x360&camera=1" width="470" height="360" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
            <td><iframe src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=640x360&camera=2" width="470" height="360" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
          </tr>
          <br>
          <tr>
            <td align="center"><button (click)="openmodal('Masseria Cuturi camera 1')" font-size="8px" font-family="Arial">Litorale Tarantino - Masseria Cuturi - CAM1</button></td>
            <td align="center"><button (click)="openmodal('Masseria Cuturi camera 2')" font-size="8px" font-family="Arial">Litorale Tarantino - Masseria Cuturi - CAM2</button></td>
          </tr>
          <br>
          <tr>
            <td><iframe src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=640x360&camera=3" width="470" height="360" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
            <td><iframe src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?fps=0&resolution=640x360&camera=4" width="470" height="360" frameborder="no" marginheight="0px" scrolling="no" align="center"></iframe></td>
          </tr>
          <br>
          <tr>
            <td align="center"><button (click)="openmodal('Masseria Cuturi camera 3')" font-size="8px" font-family="Arial">Litorale Tarantino - Masseria Cuturi - CAM3</button></td>
            <td align="center"><button (click)="openmodal('Masseria Cuturi camera 4')" font-size="8px" font-family="Arial">Litorale Tarantino - Masseria Cuturi - CAM4</button></td>
          </tr>
          </tbody>
        </table>
      </div>
      <p><button (click)="closemodal('Masseria Cuturi')">Chiudi</button></p>
    </div>
  </div>
  <div class="jw-modal-background"></div>
</div>

<div class="jw-modal-wrapper" id="Masseria Cuturi camera 1">
  <div class="jw-modal">
    <div class="jw-modal-body">
      <div id="cuturi1">
        <iframe id="cuturi-frame1" src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1024x576&camera=1" height="500px" width="100%" frameborder=0 scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
      </div>
      <p><button (click)="closemodal('Masseria Cuturi camera 1')">Chiudi</button></p>
    </div>
  </div>
  <div class="jw-modal-background"></div>
</div>
<div class="jw-modal-wrapper" id="Masseria Cuturi camera 2">
  <div class="jw-modal">
    <div class="jw-modal-body">
      <div id="cuturi2">
        <iframe id="cuturi-frame2" src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1024x576&camera=2" height="500px" width="100%" frameborder=0 scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
      </div>
      <p><button (click)="closemodal('Masseria Cuturi camera 2')">Chiudi</button></p>
    </div>
  </div>
  <div class="jw-modal-background"></div>
</div>
<div class="jw-modal-wrapper" id="Masseria Cuturi camera 3">
  <div class="jw-modal">
    <div class="jw-modal-body">
      <div id="cuturi3">
        <iframe id="cuturi-frame3" src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1024x576&camera=3" height="500px" width="100%" frameborder=0 scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
      </div>
      <p><button (click)="closemodal('Masseria Cuturi camera 3')">Chiudi</button></p>
    </div>
  </div>
  <div class="jw-modal-background"></div>
</div>
<div class="jw-modal-wrapper" id="Masseria Cuturi camera 4">
  <div class="jw-modal">
    <div class="jw-modal-body">
      <div id="cuturi4">
        <iframe id="cuturi-frame4" src="http://cmcc:cmcc%402020@cuturi.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1024x576&camera=4" height="500px" width="100%" frameborder=0 scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
      </div>
      <p><button (click)="closemodal('Masseria Cuturi camera 4')">Chiudi</button></p>
    </div>
  </div>
  <div class="jw-modal-background"></div>
</div>

<div class="jw-modal-wrapper" id="Lega Navale">
  <div class="jw-modal">
    <div class="jw-modal-body">
      <div id="navale">
        <iframe id="navale-frame" src="http://cmcc:cmcc%402020@torrecolimena.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1280x720" height="500px" width="100%" frameborder=0 scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
      </div>
      <p><button (click)="closemodal('Lega Navale')">Chiudi</button></p>
    </div>
  </div>
  <div class="jw-modal-background"></div>
</div>

<div class="jw-modal-wrapper" id="Ugento">
  <div class="jw-modal">
    <div class="jw-modal-body">
      <div id="ug">
        <iframe id="ugento-frame" src="http://cmcc:cmcc%402020@ugento.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1280x720" height="500px" width="100%" frameborder=0 scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
      </div>
      <p><button (click)="closemodal('Ugento')">Chiudi</button></p>
    </div>
  </div>
  <div class="jw-modal-background"></div>
</div>

<div class="jw-modal-wrapper" id="Cesine">
  <div class="jw-modal">
    <div class="jw-modal-body">
      <div id="ces">
        <iframe id="cesine-frame" src="http://cmcc:cmcc%402020@lecesine.mooo.com:9000/axis-cgi/mjpg/video.cgi?resolution=1280x720" height="500px" width="100%" frameborder=0 scrolling="yes" allowfullscreen="allowfullscreen"></iframe>
      </div>
      <p><button (click)="closemodal('Cesine')">Chiudi</button></p>
    </div>
  </div>
  <div class="jw-modal-background"></div>
</div>

<div class="jw-modal-wrapper" id="custom-modal-1">
  <div class="jw-modal">
    <div class="jw-modal-body">
      <google-map
        height="500px"
        width="100%"
        [zoom]="zoom"
        [center]="center"
        [mapTypeId]= "type">
        <map-marker
          #markerElem
          *ngFor="let marker of markers"
          [position]="marker.position"
          [label]="marker.label"
          [title]="marker.title"
          [options]="marker.options"
          (mapClick)="openModal(marker.title)"
        >
        </map-marker>
      </google-map>
      <button (click)="closeModal('custom-modal-1');">Chiudi</button>
    </div>
  </div>
  <div class="jw-modal-background"></div>
</div>
