<div class="container-info">
  <div class="grid-container">
    <div class="row">
        <div class="col-sm-3 text-center">
          <h4>{{count}} sensori presenti sul territorio</h4>
        </div>
        <div class="col-sm-3 text-center">
          <h4>{{days}} giorni dall'inizio del progetto</h4>
        </div>
        <div class="col-sm-3 text-center">
          <h4>{{area}} m<sup>2</sup> monitorati</h4>
        </div>
        <div class="col-sm-3 text-center">
          <h4>{{totmeasures}} misure totali</h4>
        </div>
      </div>
  </div>
</div>
<br>
  <div class="row">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <h3 class="title">MAPPA SENSORI</h3>
        </div>
        <div class="col-md-2 col-sm-3 clearfix map-legend">
          <img src="../../../assets/markers/green.png" class="img-responsive pull-left map-legend-icon">
          <h4>Meno di 2 ore fa</h4>
        </div>
        <div class="col-md-2 col-sm-3 clearfix map-legend">
          <img src="../../../assets/markers/yellow.png" class="img-responsive pull-left map-legend-icon">
          <h4>Meno di 2 giorni fa</h4>
        </div>
        <div class="col-md-2 col-sm-3 clearfix map-legend">
          <img src="../../../assets/markers/red.png" class="img-responsive pull-left map-legend-icon">
          <h4>Non aggiornato</h4>
        </div>
      </div>
      <div class="container">
        <!--(zoomChanged)="calculateCenter()"-->
        <div class="content" role="main">
          <div class="card">
            <div class="form-group">
              <input type="text" class="fa" id="search-text" aria-describedby="search-text"
                     [(ngModel)]="searchText" placeholder="&#xf002; Ricerca un TreeTalker" (click)="searchTT()" (mouseleave)="notsearchTT()"
                     autofocus>
            </div>
          </div>
        </div>
        <button id="binoc" class="binoculars" title="Vedi i dettagli del sito" (click)="goToPageSiteDetails('sitedetails_ita')"><i class="fa fa-binoculars pull-left"></i></button>
        <mat-form-field  id="selectfields" class="pull-left" appearance="outline">
          <mat-select #select placeholder="Sensori Ofidia" (selectionChange)="findMarker($event)">
            <mat-option *ngFor="let serialnumber of sortedserialnumbers | appFilter: searchText" value="{{ serialnumber }}">
              {{ serialnumber }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <google-map #googlemap *ngIf="loaded; else stillLoading"
          height="500px"
          width="100%"
          [zoom]="zoom"
          [center]="center"
        [mapTypeId]= "type">
          <map-marker
            #markerElem
            *ngFor="let marker of markers"
            [position]="marker.position"
            [label]="marker.label"
            [title]="marker.title"
            [options]="marker.options"
            (mapClick)="openInfo(markerElem, marker.title)"
          >
          </map-marker>
          <map-info-window>
            <h4>SERIALE: {{ infoContent }} - {{ ttfire }}</h4>
            <h5>Ultimo aggiornamento: {{ lastupdate }} ({{ minutesago }} minuti fa)</h5>
            <table style="width:100%">
              <tr>
                <td width="50%">Temperatura dell'aria</td>
                <td width="50%">{{ lasttemperature }} °C</td>
              </tr>
              <tr>
                <td width="50%">Umidità relativa</td>
                <td width="50%">{{ lasthumidity }} %</td>
              </tr>
              <tr>
                <td width="50%">Temperatura fogliare</td>
                <td width="50%">{{ lastleaft }} °C</td>
              </tr>
              <tr>
                <td width="50%">Indice di rischio <i class="fa fa-info-circle fa-xs" title= "L'Indice di rischio è un parametro riepilogativo orario che ha lo scopo di fornire informazioni generiche sullo stato di salute della pianta. L'indice di rischio è sempre compreso tra 0 e 10: un valore compreso tra 0 e 2 significa rischio basso; un valore compreso tra 2 e 5 significa rischio medio; un valore compreso tra 5 e 8 significa rischio moderato; un valore compreso tra 8 e 10 indica un rischio elevato."></i></td>
                <td width="50%"> {{lastrisk}} - {{clusterrisk}} </td>
              </tr>
              <tr>
                <td width="50%"></td>
                <td width="50%">
                  <div class="progressbar">
                    <div id="risk width" class="progress-bar" role="progressbar" [attr.aria-valuenow]="lastrisk*10"
                         aria-valuemin="0" aria-valuemax="100">
                    </div>
                  </div>
                  <div class="start">0</div>
                  <div class="end">10</div>
                </td>
              </tr>
            </table>
              <br>
              <details>
                <summary>Di più...</summary>
                <br>
                <table style="width:100%">
                  <tr>
                    <td width="50%">Flusso di linfa</td>
                    <td width="50%">{{ lastsapflow }} g/cm2 per hr</td>
                  </tr>
                  <tr>
                    <td width="50%">Umidità nel gambo</td>
                    <td width="50%">{{ laststemh }} %</td>
                  </tr>
                  <tr>
                    <td width="50%">Tasso di crescita del tronco</td>
                    <td width="50%">{{ lastgrowth }} mm/year</td>
                  </tr>
                  <tr>
                    <td width="50%">Rapporto verde-rosso</td>
                    <td width="50%">{{ lastgr }} </td>
                  </tr>
                  <tr>
                    <td width="50%">NDVI</td>
                    <td width="50%">{{ lastndvi }} </td>
                  </tr>
                  <tr>
                    <td width="50%">Diossido di carbonio</td>
                    <td width="50%">{{ lastco2 }} µg/m3</td>
                  </tr>
                  <tr>
                    <td width="50%">Ozono</td>
                    <td width="50%">{{ lasto3 }} µg/m3</td>
                  </tr>
                  <tr>
                    <td width="50%">Particolato atmosferico 2.5</td>
                    <td width="50%">{{ lastpm25 }} µg/m3</td>
                  </tr>
                  <tr>
                    <td width="50%">Particolato atmosferico 10</td>
                    <td width="50%">{{ lastpm10 }} µg/m3</td>
                  </tr>
                  <tr>
                    <td width="50%">Flag di fiamma</td>
                    <td width="50%">{{ lastflameflag }}</td>
                  </tr>
                  <tr>
                    <td width="50%">Impulsi di fiamma</td>
                    <td width="50%">{{ lastflamepulses }}</td>
                  </tr>
                  <tr>
                    <td width="50%">Movimento dell'asse del tronco</td>
                    <td width="50%">{{ lasttam }} rad/s</td>
                  </tr>
                </table>
              </details>
            <button class="btn details" align="center" (click)="goToPageDetails('ttdetails_ita')"><i class="fa fa-binoculars"></i> Storico e Dettagli</button>
            </map-info-window>
        </google-map>
        <ng-template #stillLoading>
          <div class="container" width="100%" height="500px">
            <img src="../../../assets/icons/spinner-icon-gif.gif" class="center" width="128" height="128" alt=""/>
          </div>
        </ng-template>
        <form class="list">
          <select name="serial numbers" id="serial number" (change)="findMarker($event)" style="height:2.5em;">
            <option value="{{ usersite }}">{{ usersite }}</option>
            <option *ngFor="let site of sites" value="{{ site }}">{{ site }}</option>
          </select>
        </form>
        <button class="alarms" (click)="goToPageAlarms('alarms_ita')">Allarmi <i class="fa fa-exclamation-triangle"></i></button>
        <button id="videosite" class="video" (click)="goToCameras('camera_ita')"><i class="fa fa-video-camera"></i></button>
        <button id="Cesinestation" class="station" (click)="goToCesineStation('meteo_ita')"><i class="fa fa-thermometer-empty"></i></button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-left">
      <h3 class="title">PARAMETRI DI PRE-ALLARME NELL'ULTIMO MESE </h3>
      <div class="average box">
        <div class="btn-group">
          <div class="boxbuttons" #2 id="sap_flow">
            <button class="button1" (click) = "toggleDisplay('sap_flow',2, 'prealarm')">Flusso di linfa [g/cm2 per hr]</button>
            <button class="button2" (click)="openDialog('Il flusso di linfa ','è il movimento del fluido nelle radici, nei fusti e nei rami delle piante. Il flusso di linfa viene generalmente misurato nella porzione di alburno dello xilema delle piante. I dati sono espressi in litri / ora.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #5 id="stem_hum">
            <button class="button1" (click) = "toggleDisplay('stem_hum',5, 'prealarm')">Umidità nel gambo [%]</button>
            <button class="button2" (click)="openDialog('L\'umidità nel gambo (StWC %) ','è la misura del contenuto di umidità ed è espressa come segnale di frequenza con un range da 7000 a 14000 Hz nel legno verde. I dati sono espressi in % del volume d\'acqua per unità di volume di legno.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #6 id="growth_rate">
            <button class="button1" (click) = "toggleDisplay('growth_rate',6, 'prealarm')">Tasso di crescita del tronco [mm/year]</button>
            <button class="button2" (click)="openDialog('Il tasso di crescita del tronco ','è la crescita radiale cumulativa del fusto misurata da un sensore di distanza pulsato a infrarossi posizionato a pochi centimetri dalla superficie del tronco dell\'albero e tenuto in posizione da un bastoncino in fibra di carbonio ancorato nello xilema. I dati sono espressi in mm / anno.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #8 id="ndvi">
            <button class="button1" (click) = "toggleDisplay('ndvi',8, 'prealarm')">NDVI</button>
            <button class="button2" (click)="openDialog('L\'indice di vegetazione a differenza normalizzata NDVI ','quantifica la vegetazione misurando la differenza tra il vicino infrarosso (che la vegetazione riflette fortemente) e la luce rossa (che la vegetazione assorbe). Il NDVI varia sempre da -1 a +1.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #32 id="green_red">
            <button class="button1" (click) = "toggleDisplay('green_red',32, 'prealarm')">Verde/Rosso</button>
            <button class="button2" (click)="openDialog('Il rapporto verde-rosso ','è altamente correlato alla crescita delle piante verdi e alla biomassa. È il rapporto tra la risposta nella banda verde e la risposta nella banda rossa.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #7 id="leaf_temp">
            <button class="button1" (click) = "toggleDisplay('leaf_temp',7, 'prealarm')">Temperatura fogliare [°C]</button>
            <button class="button2" (click)="openDialog('La temperatura fogliare ','permette di rilevare lo stato idrico della pianta perché, al diminuire della traspirazione fogliare, la temperatura aumenta rispetto a quella dell\'aria. I valori di temperatura della foglia sono espressi in ° C.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #9 id="air_temp">
            <button class="button1" (click) = "toggleDisplay('air_temp',9, 'prealarm')">Temperatura dell'aria [°C]</button>
            <button class="button2" (click)="openDialog('La temperatura dell\'aria ','è un dato meteorologico espresso in °C.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #10 id="air_hum">
            <button class="button1" (click) = "toggleDisplay('air_hum',10, 'prealarm')">Umidità relativa [%]</button>
            <button class="button2" (click)="openDialog('L\'umidità relativa ','è un dato meteorologico espresso in %.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
        </div>
        <iframe id="prealarmDashboard" [src]="trustedUrl" width="100%" height="400" frameborder="0"></iframe>
        <canvas id="average_chart"></canvas>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-left">
      <h3 class="title">PARAMETRI DI ALLARME NELL'ULTIMO MESE </h3>
      <div class="average box">
        <div class="btn-group">
          <div class="boxbuttons" #11 id="carbon_dioxide">
            <button class="button1" (click) = "toggleDisplay('carbon_dioxide',11, 'alarm')">Diossido di carbonio [µg/m3]</button>
            <button class="button2" (click)="openDialog('Il diossido di carbonio (CO2) ','è un parametro della concentrazione di Gas espresso in mg/m3.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #12 id="ozone">
            <button class="button1" (click) = "toggleDisplay('ozone',12, 'alarm')">Ozono [µg/m3]</button>
            <button class="button2" (click)="openDialog('L\'ozono (03) ','è un parametro della concentrazione di Gas espresso in mg/m3.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #13 id="pm_2.5">
            <button class="button1" (click) = "toggleDisplay('pm_2.5',13, 'alarm')">Particolato atmosferico 2.5 [µg/m3]</button>
            <button class="button2" (click)="openDialog('Il particolato atmosferico (PM 2.5) ','è un parametro della concentrazione di Gas espresso in mg/m3.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #14 id="pm_10">
            <button class="button1" (click) = "toggleDisplay('pm_10',14, 'alarm')">Particolato atmosferico 10 [µg/m3]</button>
            <button class="button2" (click)="openDialog('Il particolato atmosferico (PM 10) ','è un parametro della concentrazione di Gas espresso in mg/m3.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #15 id="flame_pulses">
            <button class="button1" (click) = "toggleDisplay('flame_pulses',15, 'alarm')">Impulsi di fiamma</button>
            <button class="button2" (click)="openDialog('Gli impulsi di fiamma ','permette di rilevare la temperatura della fiamma (2000-3000 ° K) ad una distanza di centinaia di metri. Verifica la presenza dell\'accensione di un incendio a distanze tipicamente di cento metri.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #31 id="flame_flag">
            <button class="button1" (click) = "toggleDisplay('flame_flag',31, 'alarm')">Flag di fiamma</button>
            <button class="button2" (click)="openDialog('Il flag di fiamma ','è un valore booleano che è 1 se viene rilevata la presenza di fiamma, 0 altrimenti.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
          <div class="boxbuttons" #16 id="trunk_mov">
            <button class="button1" (click) = "toggleDisplay('trunk_mov',16, 'alarm')">Movimento dell'asse del tronco [rad/s]</button>
            <button class="button2" (click)="openDialog('Il movimento dell\'asse del tronco ','permette di misurare in tempo reale la posizione dell\'albero rispetto al vettore di gravità (tramite un sensore giroscopico). I dati sono espressi in rad / sec.')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
          </div>
        </div>
        <iframe id="alarmDashboard" [src]="trusted1Url" width="100%" height="400" frameborder="0"></iframe>
        <canvas id="average1_chart"></canvas>
      </div>
    </div>
  </div>
