import {Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import MapTypeId = google.maps.MapTypeId;
import {DomSanitizer} from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-video-cameras',
  templateUrl: './video-cameras.component.html',
  styleUrls: ['./video-cameras.component.css']
})
export class VideoCamerasComponent implements OnInit {
  dangerousUrl: any;
  trustedUrl: any;
  public flagmap = false;
  public markers = [{
    position: {
      lat: 39.882533333333335,
      lng: 18.136266666666668,
    },
    title: 'Ugento',
    options: { icon: {
        url: '../../../assets/markers/camera.png'
      } },
  }, {
    position: {
      lat: 40.34096944444445,
      lng: 17.661394444444444,
    },
    title: 'Masseria Cuturi',
    options: { icon: {
        url: '../../../assets/markers/camera.png'
      } },
  },
    {
      position: {
        lat: 40.29735,
        lng: 17.74593888888889,
      },
      title: 'Lega Navale',
      options: { icon: {
          url: '../../../assets/markers/camera.png'
        } },
    },
    {
      position: {
        lat: 40.744952777777776,
        lng: 17.20138972222222,
      },
      title: 'Masseria Galeone',
      options: { icon: {
          url: '../../../assets/markers/camera.png'
        } },
    },
    {
      position: {
        lat: 40.350096944444445,
        lng: 18.335247499999998,
      },
      title: 'Cesine',
      options: { icon: {
          url: '../../../assets/markers/camera.png'
        } },
    }];
  /*map values*/
  zoom = 8;
  type: google.maps.MapTypeId = MapTypeId.HYBRID;
  public center = {
    lat: 40.34654412118006,
    lng: 18.171386718750004
  };

  constructor(private sanitizer: DomSanitizer, private http: HttpClient) { }

  ngOnInit(): void {
    // const username = 'cmcc';
    // const password = 'cmcc@2020';
    // const headers = new HttpHeaders(
      // {
        // Authorization: 'Basic ' + btoa(`${username}:${password}`)
      // });

    // const url = 'http://lecesine.mooo.com:9000';
    // this.http.get(url, {headers})
      // .subscribe(
        // data => console.log(data),
        // error => console.log(error)
      // );
    this.dangerousUrl = 'http://lecesine.mooo.com:9000/#view';
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
  }
  openModal(id: string) {
    this.flagmap = true;
    document.getElementById('custom-modal-1').style.display = 'none';
    document.getElementById(id).style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }
  openmodal(id: string) {
    document.getElementById(id).style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }
  closemodal(id: string) {
    if (this.flagmap === true) {
      if (id === 'Masseria Cuturi camera 1' || id === 'Masseria Cuturi camera 2' || id === 'Masseria Cuturi camera 3' || id === 'Masseria Cuturi camera 4') {
        document.getElementById('Masseria Cuturi').style.display = 'block';
      }
      else {
        document.getElementById('custom-modal-1').style.display = 'block';
      }
    }
    document.getElementById(id).style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  closeModal(id: string) {
    document.getElementById(id).style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.flagmap = false;
  }

}
