<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-md-5 col-sm-8">
        <h3 class="title">MAPPA GESTIONE FLOTTE</h3>
      </div>
      <div class="col-md-4 col-sm-8 clearfix map-legend">
        <img src="../../../assets/markers/vol_yellow.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px">
        <h4>Veicoli in movimento</h4>
      </div>
      <div class="col-md-2 col-sm-3 clearfix map-legend">
        <img src="../../../assets/markers/source.gif" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px">
        <h4>Luogo incendio</h4>
      </div>
    </div>
    <div class="container">
      <div id="map"></div>
      <button id="path" class="path" (click)="hideDirs()">Nascondi percorsi <i class="fa fa-globe"></i></button>
      <button id="coord" class="coord" (click)="openDialog()">Inserisci incendio <i class="fa fa-fire"></i></button>
      <button id="search" class="search" (click)="openEventLogs()">Report incendi <i class="fa fa-search"></i></button>
      <button (click)="openDir()" id="go">Number</button>
      <button (click)="showDir()" id="show">Number</button>
      <button (click)="selectFire()" id="selectyes"></button>
      <button (click)="closeFire()" id="selectno"></button>
      <button (click)="editFire()" id="edityes"></button>
      <button (click)="closeFire()" id="editno"></button>
      <button (click)="deleteFire()" id="deleteyes"></button>
      <button (click)="closeFire()" id="deleteno"></button>
      <table id="t01">
        <tbody>
        <tr>
          <th>Icona squadra</th>
          <th>Nome squadra</th>
          <th>Distretto monitorato</th>
          <th>Vedi messaggi</th>
          <th>Vedi log</th>
        </tr>
        <tr *ngFor="let team of teams">
          <td><img [src]="team[3]" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>
          <td>{{team[1]}}</td>
          <td>{{team[2]}}</td>
          <td>
            <button (click)="openMessages(team[0], team[1], team[4])">
              <i [id]="'button' + team[4]" class="fa fa-envelope-open-o" aria-hidden="true"></i>
            </button>
          </td>
          <td>
            <button (click)="openLogs(team[0])">
              <i class="fa fa-file-text-o" aria-hidden="true"></i></button>
          </td>
        </tr>
        </tbody>
      </table>



<!--      <table id="t00">-->
<!--        <tr>-->
<!--          <th>Icona squadra</th>-->
<!--          <th>Distretto monitorato</th>-->
<!--          <th>Nome squadra</th>-->
<!--          <th>Invia un messaggio</th>-->
<!--          <th>Vedi messaggi</th>-->
<!--          <th>Vedi log</th>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/vol_yellow.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>BA_01</td>-->
<!--          <td>Team1</td>-->
<!--          <td>-->
<!--            <input type="text" id="message1" name="message" #inputName1>-->
<!--            <button (click)="sendChatMessages('Team1', inputName1)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team1')">-->
<!--              <i id="buttonTeam1" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team1')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/BA_02.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>BA_02</td>-->
<!--          <td>Team2</td>-->
<!--          <td>-->
<!--            <input type="text" id="message2" name="message" #inputName2>-->
<!--            <button (click)="sendChatMessages('Team2', inputName2)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team5')">-->
<!--              <i id="buttonTeam2" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team2')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/BA_03.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>BA_03</td>-->
<!--          <td>Team3</td>-->
<!--          <td>-->
<!--            <input type="text" id="message3" name="message" #inputName3>-->
<!--            <button (click)="sendChatMessages('Team3', inputName3)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team3')">-->
<!--              <i id="buttonTeam3" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team3')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/BAT.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>BAT</td>-->
<!--          <td>Team4</td>-->
<!--          <td>-->
<!--            <input type="text" id="message4" name="message" #inputName4>-->
<!--            <button (click)="sendChatMessages('Team4', inputName4)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team4')">-->
<!--              <i id="buttonTeam4" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team4')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/BR_01.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>BR_01</td>-->
<!--          <td>Team5</td>-->
<!--          <td>-->
<!--            <input type="text" id="message5" name="message" #inputName5>-->
<!--            <button (click)="sendChatMessages('Team5', inputName5)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team5')">-->
<!--              <i id="buttonTeam5" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team5')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/BR_02.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>BR_02</td>-->
<!--          <td>Team6</td>-->
<!--          <td>-->
<!--            <input type="text" id="message6" name="message" #inputName6>-->
<!--            <button (click)="sendChatMessages('Team6',inputName6)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team6')">-->
<!--              <i id="buttonTeam6" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team6')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/FG_01.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>FG_01</td>-->
<!--          <td>Team7</td>-->
<!--          <td>-->
<!--            <input type="text" id="message7" name="message" #inputName7>-->
<!--            <button (click)="sendChatMessages('Team7', inputName7)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team7')">-->
<!--              <i id="buttonTeam7" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team7')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/FG_02.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>FG_02</td>-->
<!--          <td>Team8</td>-->
<!--          <td>-->
<!--            <input type="text" id="message8" name="message" #inputName8>-->
<!--            <button (click)="sendChatMessages('Team8', inputName8)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team8')">-->
<!--              <i id="buttonTeam8" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team8')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/FG_03.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>FG_03</td>-->
<!--          <td>Team9</td>-->
<!--          <td>-->
<!--            <input type="text" id="message9" name="message" #inputName9>-->
<!--            <button (click)="sendChatMessages('Team9',inputName9)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team9')">-->
<!--              <i id="buttonTeam9" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team9')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/FG_04.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>FG_04</td>-->
<!--          <td>Team10</td>-->
<!--          <td>-->
<!--            <input type="text" id="message10" name="message" #inputName10>-->
<!--            <button (click)="sendChatMessages('Team10', inputName10)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team10')">-->
<!--              <i id="buttonTeam10" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team10')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/LE_01.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>LE_01</td>-->
<!--          <td>Team11</td>-->
<!--          <td>-->
<!--            <input type="text" id="message11" name="message" #inputName11>-->
<!--            <button (click)="sendChatMessages('Team11', inputName11)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team11')">-->
<!--              <i id="buttonTeam11" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team11')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/LE_02.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>LE_02</td>-->
<!--          <td>Team12</td>-->
<!--          <td>-->
<!--            <input type="text" id="message12" name="message" #inputName12>-->
<!--            <button (click)="sendChatMessages('Team12', inputName12)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team12')">-->
<!--              <i id="buttonTeam12" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team12')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/LE_03.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>LE_03</td>-->
<!--          <td>Team13</td>-->
<!--          <td>-->
<!--            <input type="text" id="message13" name="message" #inputName13>-->
<!--            <button (click)="sendChatMessages('Team13', inputName13)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team13')">-->
<!--              <i id="buttonTeam13" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team13')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/TA_01.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>TA_01</td>-->
<!--          <td>Team14</td>-->
<!--          <td>-->
<!--            <input type="text" id="message14" name="message" #inputName14>-->
<!--            <button (click)="sendChatMessages('Team14', inputName14)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team14')">-->
<!--              <i id="buttonTeam14" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team14')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/TA_02.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>TA_02</td>-->
<!--          <td>Team15</td>-->
<!--          <td>-->
<!--            <input type="text" id="message15" name="message" #inputName15>-->
<!--            <button (click)="sendChatMessages('Team15', inputName15)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team15')">-->
<!--              <i id="buttonTeam15" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team15')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td><img src="../../../assets/markers/TA_03.png" class="img-responsive pull-left map-legend-icon" style="height:40px; margin-right: 5px"></td>-->
<!--          <td>TA_03</td>-->
<!--          <td>Team16</td>-->
<!--          <td>-->
<!--            <input type="text" id="message16" name="message" #inputName16>-->
<!--            <button (click)="sendChatMessages('Team16', inputName16)"><i class="fa fa-telegram" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openMessages('Team16')">-->
<!--              <i id="buttonTeam16" class="fa fa-envelope-open-o" aria-hidden="true"></i>-->
<!--            </button>-->
<!--          </td>-->
<!--          <td>-->
<!--            <button (click)="openLogs('Team16')"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>-->
<!--          </td>-->
<!--        </tr>-->
<!--      </table>-->
    </div>
  </div>
</div>


<!--<div class="row">-->
<!--  <div class="col-sm-12">-->
<!--    <div class="container">-->
<!--<div id="map"></div>-->
<!--<div id="right-panel">-->
<!--  <div>-->
<!--    <b>Start:</b>-->
<!--    <select id="start">-->
<!--      <option value="Halifax, NS">Halifax, NS</option>-->
<!--      <option value="Boston, MA">Boston, MA</option>-->
<!--      <option value="New York, NY">New York, NY</option>-->
<!--      <option value="Miami, FL">Miami, FL</option>-->
<!--    </select>-->
<!--    <br />-->
<!--    <b>Waypoints:</b> <br />-->
<!--    <i>(Ctrl+Click or Cmd+Click for multiple selection)</i> <br />-->
<!--    <select multiple id="waypoints">-->
<!--      <option value="montreal, quebec">Montreal, QBC</option>-->
<!--      <option value="toronto, ont">Toronto, ONT</option>-->
<!--      <option value="chicago, il">Chicago</option>-->
<!--      <option value="winnipeg, mb">Winnipeg</option>-->
<!--      <option value="fargo, nd">Fargo</option>-->
<!--      <option value="calgary, ab">Calgary</option>-->
<!--      <option value="spokane, wa">Spokane</option>-->
<!--    </select>-->
<!--    <br />-->
<!--    <b>End:</b>-->
<!--    <select id="end">-->
<!--      <option value="Vancouver, BC">Vancouver, BC</option>-->
<!--      <option value="Seattle, WA">Seattle, WA</option>-->
<!--      <option value="San Francisco, CA">San Francisco, CA</option>-->
<!--      <option value="Los Angeles, CA">Los Angeles, CA</option>-->
<!--    </select>-->
<!--    <br />-->
<!--    <input type="submit" id="submit" />-->
<!--  </div>-->
<!--  <div id="directions-panel"></div>-->
<!--</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
