import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }
  goToPage(pageName: string): void {
    this.router.navigate([`../${pageName}`], { relativeTo: this.route }).then(() => {
      window.location.reload();
    });
  }

  ngOnInit(): void {
  }

}
