import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {ChatService} from '../../services/chat.service';
import {DatePipe} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {LOGS_BY_TEAM_URL, MESSAGES_BY_TEAM_URL, LOGS_BY_EVENT_URL, EVENT_BY_TEAM_URL, DISTANCE_URL, FLEET_URL} from '../../env';
import {HttpClient} from '@angular/common/http';
import {HashTable} from 'angular-hashtable';

declare var saveAs: any;
declare var initialize: any;
declare var calcRoute: any;
declare var deleteRoute: any;
export interface LogsData {
  team_id: any;
  message: [];
}
export interface MessagesData {
  team_id: any;
  team_name: any;
  message: [];
}
export interface CoordinatesData {
  sinapsiid: string;
  latitude: any;
  longitude: any;
}
@Component({
  selector: 'app-fleet-management',
  templateUrl: './fleet-management.component.html',
  styleUrls: ['./fleet-management.component.css']
})
export class FleetManagementComponent implements OnInit{
  constructor(private http: HttpClient, private chat: ChatService, public dialog: MatDialog, private datePipe: DatePipe) {
  }
  /*hashTable*/
  table = new HashTable<any, any>();
  googleMap: google.maps.Map;
  marker: google.maps.Marker;
  storemarker: any;
  fire: google.maps.Marker;
  // info window for teams
  teammarker: google.maps.Marker;
  teamwindow: google.maps.InfoWindow;
  // info window for fires
  firemarker: google.maps.Marker;
  firewindow: google.maps.InfoWindow;
  // distances variables
  mindistance: number;
  distance: number;
  // markers vehicles
  markers: any[] = [];
  storemarkers: any[] = [];
  messages: any[] = [];
  textinput: any;
  fires: any[] = [];
  newfire: any;
  value: any;
  /*static fire point*/
  sinapsiid: any;
  latitude: any;
  longitude: any;
  // minimum directions
  dir: any;
  dirs: Array<any> = [];
  path: any;
  paths: Array<any> = [];
  // devices id
  device: any;
  devices: Array<any> = [];
  // object that contains all coordinates of vehicles
  events: any[];
  teams: Array<any> = [];
  coordinatesfrom: any[];
  from: any[];
  lengthevents: number;
  lengthfrom: number;
  // object that contains all coordinates of alarms
  coordinatesto: any[] = [];
  lengthto: number;
  // objects that grouped coordinates by site
  coordCesine: Array<any> = [];
  coordCuturi: Array<any> = [];
  coordGaleone: Array<any> = [];
  coordUgento: Array<any> = [];
  coordGeneric: Array<any> = [];
  // object that contain centers of fires for each site
  coordAlarms: Array<any> = [];
  public origin: any;
  public destination: any;
  public origin1: any;
  public destination1: any;
  public selectedfire: any;
  // map center and options
  myCenter = new google.maps.LatLng(40.272542, 18.014692);
  mapOptions = {
    center: this.myCenter,
    zoom: 9,
    mapTypeId: google.maps.MapTypeId.ROADMAP
  };
  /*function that takes event from team id*/
  public getJSON_event(team): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${EVENT_BY_TEAM_URL}` + team);
  }
  /*function that takes log messages by team name*/
  public getJSON_logs(team): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${LOGS_BY_TEAM_URL}` + team + '&date=' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  }
  /*function that takes messages by team name*/
  public getJSON_messages(team): Observable<any> {
    return this.http.get(`${MESSAGES_BY_TEAM_URL}` + team + '&date=' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
  }
  /*OK function that removes markers*/
  clearMarkers() {
    if (this.markers) {
      this.markers.forEach(element => {
        element.setMap(null);
      });
      this.markers = [];
    }
  }
  /*OK function that removes fires*/
  clearFires() {
    if (this.fires) {
      this.fires.forEach(element => {
        element.setMap(null);
      });
      this.fires = [];
    }
  }
  /*function that creates an array of fires positions*/
  setCoordinates(array, sitename){
    if (array.length >= 1 && sitename !== 'Generico') {
      const coords: google.maps.LatLngLiteral[] = [];
      const boundsfire = new google.maps.LatLngBounds();
      let status = 'not managed';
      let team;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < array.length; i++) {
        if (array[i].status === 'in progress') {
          status = 'in progress';
          team = array[i].team;
        }
        // Define the LatLng coordinates for the polygon.
        coords.push({lat: parseFloat(array[i].lat), lng: parseFloat(array[i].lng)});
        // Construct the polygon.
        const polygon = new google.maps.Polygon({
          paths: coords,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        });
        polygon.setMap(this.googleMap);
      }
      for (const each of coords) {boundsfire.extend(each); }
      this.coordAlarms.push({
        lat: boundsfire.getCenter().lat(),
        lng: boundsfire.getCenter().lng(),
        site: sitename,
        status,
        team
      });
      /*if there is a fire, put icon on map*/
      this.fire = new google.maps.Marker({
        position: new google.maps.LatLng(boundsfire.getCenter().lat(), boundsfire.getCenter().lng()),
        title: sitename,
        icon: {url: '../../../assets/markers/source.gif', scaledSize: new google.maps.Size(80, 80), },
      });
      this.fire.setMap(this.googleMap);
      this.fires.push(this.fire);
    }
  }
  /*function that creates a centroid in case of more fire points in the same site*/
  getCentroid() {
    console.log('get centroid');
    this.clearFires();
    this.coordAlarms = [];
    this.coordCesine = [];
    this.coordCuturi = [];
    this.coordGaleone = [];
    this.coordUgento = [];
    this.coordGeneric = [];
    /*it creates arrays, one for each site*/
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.lengthto; i++) {
      switch (this.coordinatesto[i].site) {
        case 'Cesine':
          this.coordCesine.push(this.coordinatesto[i]);
          break;
        case 'Masseria Cuturi':
          this.coordCuturi.push(this.coordinatesto[i]);
          break;
        case 'Masseria Galeone':
          this.coordGaleone.push(this.coordinatesto[i]);
          break;
        case 'Ugento':
          this.coordUgento.push(this.coordinatesto[i]);
          break;
        default:
          this.coordGeneric.push(this.coordinatesto[i]);
          this.coordAlarms.push({
            lat: this.coordinatesto[i].lat,
            lng: this.coordinatesto[i].lng,
            site: this.coordinatesto[i].site,
            status: this.coordinatesto[i].status,
            team: this.coordinatesto[i].team
          });
          /*if there is a fire, put icon on map*/
          this.fire = new google.maps.Marker({
            position: new google.maps.LatLng(this.coordinatesto[i].lat, this.coordinatesto[i].lng),
            title: this.coordinatesto[i].site,
            icon: {url: '../../../assets/markers/source.gif', scaledSize: new google.maps.Size(80, 80), },
          });
          this.fire.setMap(this.googleMap);
          this.fires.push(this.fire);
          break;
      }
    }
    /*Cesine fire points*/
    this.setCoordinates(this.coordCesine, 'Cesine');
    /*Cuturi fire points*/
    this.setCoordinates(this.coordCuturi, 'Masseria Cuturi');
    /*Galeone fire points*/
    this.setCoordinates(this.coordGaleone, 'Masseria Galeone');
    /*Ugento fire points*/
    this.setCoordinates(this.coordUgento, 'Ugento');
    /*Generic fire points*/
    // this.setCoordinates(this.coordGeneric, 'Generico');
  }
  /*function that returns a color*/
  getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }
  /*function that selects the neighbour*/
  selectNeighbour(){
    this.dirs = [];
    this.devices = [];
    this.from = this.coordinatesfrom;
    let j = 0;
    let index = 0;
    while (j < this.coordAlarms.length) {
      if (this.coordAlarms[j].status === 'not managed') {
        for (let i = 0; i < this.lengthfrom; i++) {
          if (this.from[i] !== undefined) {
            this.markers.forEach(marker => {
              if (this.from[i].deviceid === marker.getTitle() && marker.getIcon().url !== '../../../assets/markers/vol_green.png') {
                this.origin1 = new google.maps.LatLng(this.from[i].lat, this.from[i].lng);
                this.destination1 = new google.maps.LatLng(this.coordAlarms[j].lat, this.coordAlarms[j].lng);
                this.origin = {lat: this.from[i].lat, lng: this.from[i].lng};
                this.destination = {lat: this.coordAlarms[j].lat, lng: this.coordAlarms[j].lng};
                this.distance = google.maps.geometry.spherical.computeDistanceBetween(this.origin1, this.destination1);
                if (this.distance <= this.mindistance) {
                  this.mindistance = this.distance;
                  this.device = this.from[i].deviceid;
                  this.dir = {
                    origin: this.origin,
                    destination: this.destination,
                    travelMode: 'DRIVING',
                    provideRouteAlternatives: true,
                    deviceid: this.from[i].deviceid
                    // tslint:disable-next-line:max-line-length
                    // renderOptions: { polylineOptions: { strokeColor: this.getRandomColor(), strokeWeight: 2, }, suppressMarkers: true,
                    //   // preserveViewport: true
                    // },
                  };
                  index = i;
                }
              }
            });
          }
        }
        delete this.from[index];
        this.mindistance = 10000000000000000000000;
        this.dirs.push(this.dir);
        this.devices.push(this.device);
        initialize(this.googleMap);
      }
      j = j + 1;
    }
    let near;
    for (let i = 0; i < this.lengthfrom; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let k = 0; k < this.dirs.length; k++) {
        // tslint:disable-next-line:max-line-length
        // if (this.markers[i].getPosition().lat() === this.dirs[k].origin.lat && this.markers[i].getPosition().lng() === this.dirs[k].origin.lng) {
        //   near = i;
        //   if (this.markers[i].getIcon().url !== '../../../assets/markers/vol_green.png') {
        //     // this.markers[i].setIcon({url: '../../../assets/markers/vol_green_cand.png', scaledSize: new google.maps.Size(48, 48), });
        //     this.markers[i].setAnimation(google.maps.Animation.BOUNCE);
        //   }
        // }
        if (this.markers[i].getTitle() === this.dirs[k].deviceid) {
          near = i;
          if (this.markers[i].getIcon().url !== '../../../assets/markers/vol_green.png') {
            this.markers[i].setAnimation(google.maps.Animation.BOUNCE);
          }
        }
      }
      if (near !== i) {
        if (this.markers[i].getIcon().url !== '../../../assets/markers/vol_green.png') {
          // this.markers[i].setIcon({url: '../../../assets/markers/vol.png', scaledSize: new google.maps.Size(48, 48), });
          this.markers[i].setAnimation(null);
        }
      }
    }
  }
  /*function that monitors in progress fires*/
  checkDirs() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.coordAlarms.length; i++) {
      if (this.coordAlarms[i].status === 'in progress') {
        this.markers.forEach(marker => {
          if (marker.getTitle() === this.coordAlarms[i].team) {
            this.path =  {
              origin: { lat: marker.getPosition().lat(), lng: marker.getPosition().lng() },
              destination: { lat: this.coordAlarms[i].lat, lng: this.coordAlarms[i].lng },
              travelMode: 'DRIVING',
              provideRouteAlternatives: true,
              region: marker.title,
              // tslint:disable-next-line:max-line-length
              // renderOptions: { polylineOptions: { strokeColor: this.getRandomColor(), strokeWeight: 2, }, suppressMarkers: true,
              //   // preserveViewport: true
              // },
            };
            // calcRoute(this.googleMap, this.path, this.getRandomColor());
            // marker.setIcon({url: '../../../assets/markers/vol_green.png', scaledSize: new google.maps.Size(48, 48), });
            marker.setAnimation(null);
          }
        });
      }
    }
  }

  hideDirs() {
    deleteRoute();
    this.paths = [];
    document.getElementById('path').style.display = 'none';
  }

  /*function that generates a path for a selected team*/
  showDir() {
    console.log('showdir');
    this.getJSON_event(this.teammarker.getLabel()).subscribe(data => {
        this.path = {
          origin: {lat: this.teammarker.getPosition().lat(), lng: this.teammarker.getPosition().lng()},
          destination: {lat: data[0][`latitude`], lng: data[0][`longitude`]},
          travelMode: 'DRIVING',
          provideRouteAlternatives: true,
          region: this.teammarker.getCursor(),
          // tslint:disable-next-line:max-line-length
          // renderOptions: { polylineOptions: { strokeColor: this.getRandomColor(), strokeWeight: 2, }, suppressMarkers: true,
          //   // preserveViewport: true
          // },
        };
        this.paths.push(this.path);
        document.getElementById('path').style.display = 'block';
        calcRoute(this.googleMap, this.path, this.getRandomColor());
        this.teamwindow.close();
        this.teammarker = undefined;
      },
      err => {
        console.log('Error! ' + err.message);
      });
  }
  /*function that inserts an event for a selected team*/
  openDir() {
    console.log('opendir');
    // let destination;
    /*for (const item of this.coordAlarms) {
      if (this.markerwindow.getCursor() === item.site) {
        destination = { lat: item.lat, lng: item.lng };
      }
    }*/
    if (this.selectedfire !== undefined) {
      this.path =  {
        origin: { lat: this.teammarker.getPosition().lat(), lng: this.teammarker.getPosition().lng() },
        destination: this.selectedfire,
        travelMode: 'DRIVING',
        provideRouteAlternatives: true,
        region: this.teammarker.getCursor(),
        // tslint:disable-next-line:max-line-length
        // renderOptions: { polylineOptions: { strokeColor: this.getRandomColor(), strokeWeight: 2, }, suppressMarkers: true,
        //   // preserveViewport: true
        // },
      };
      // calcRoute(this.googleMap, this.path, this.getRandomColor());
      // tslint:disable-next-line:radix
      const teamid = Number(this.teammarker.getLabel());
      const eventid = Number(this.selectedfire.title);
      const timestamp = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
      const description = this.teammarker.getTitle() + ' raggiunge il luogo ' + this.selectedfire.title;
      const headers = { 'Content-Type': 'application/json'};
      // tslint:disable-next-line:max-line-length
      const body = { team_idteam: teamid, event_idevent: eventid, timestamp, latitude: this.teammarker.getPosition().lat(), longitude: this.teammarker.getPosition().lng(), state: 'start', description};
      this.http.post<any>(`${FLEET_URL}` + 'insertPosition', body, { headers }).subscribe(data => {
      });
      this.chat.sendAlarm({event: 'start alarm', teamid, destination: this.selectedfire, state: 'start', description});
      // this.markers.forEach(marker => {
      //   if (marker.title === this.markerwindow.getTitle()) {
      //     marker.setIcon({url: '../../../assets/markers/vol_green.png', scaledSize: new google.maps.Size(48, 48), });
      //     marker.setAnimation(null);
      //   }
      // });
      const message = 'Inizio evento incendio: ' + this.teammarker.getTitle() + ' parte';
      // tslint:disable-next-line:max-line-length
      const body1 = { team_name: this.teammarker.getTitle(), latitude: this.selectedfire.lat, longitude: this.selectedfire.lng, timestamp, message};
      // this.http.post<any>('http://vs.ofidia.eu:8085/setfire', body1, { headers }).subscribe(data => {
      // });
      this.teamwindow.close();
      alert('Team go');
      this.selectedfire = undefined;
      this.teammarker = undefined;
    }
    else {
      this.teamwindow.close();
      console.log('there is not alarm!');
      this.selectedfire = undefined;
      this.teammarker = undefined;
    }
  }
  /*OK open dialog to edit fire coordinates*/
  editDialog(): void {
    const dialogRef = this.dialog.open(FireCoordinatesComponent, {
      width: '250px',
      // tslint:disable-next-line:max-line-length
      data: {sinapsiid: this.sinapsiid, latitude: this.latitude, longitude: this.longitude}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        //   /*if user edits a fire, edit position in db*/
        // tslint:disable-next-line:max-line-length
        if (result.sinapsiid !== undefined || !isNaN(result.latitude) || !isNaN(result.longitude)) {
          let sinapsiid = result.sinapsiid;
          let lat = result.latitude;
          let long = result.longitude;
          const headers = { 'Content-Type': 'application/json'};
          if (sinapsiid === undefined) {
            sinapsiid = this.firemarker.getCursor();
          }
          if (isNaN(lat)) {
            lat = this.firemarker.getPosition().lat();
          }
          if (isNaN(long)) {
            long = this.firemarker.getPosition().lng();
          }
          // tslint:disable-next-line:max-line-length
          const body = { idevent: this.firemarker.getTitle(), sinapsiid, latitude: lat, longitude: long};
          this.http.post<any>(`${FLEET_URL}` + 'updateEvent', body, { headers }).subscribe(data => {
            alert('Fire changed!');
          });
        }
      }
    });
  }
  /*OK function that edits a fire*/
  editFire() {
    console.log('editFire');
    if (this.firemarker !== undefined) {
      this.firewindow.close();
      this.firemarker = undefined;
      this.editDialog();
    }
  }
  /*OK function that deletes a fire*/
  deleteFire() {
    console.log('deleteFire');
    if (this.firemarker !== undefined) {
      const dialogRef = this.dialog.open(DeleteConfirmComponent, {
        width: '250px',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.data !== 'Back') {
          const headers = { 'Content-Type': 'application/json'};
          const body = { idevent: this.firemarker.getCursor()};
          this.http.post<any>(`${FLEET_URL}` + 'deleteEvent', body, { headers }).subscribe(data => {
            alert('Fire removed!');
          });
          this.firewindow.close();
          this.firemarker = undefined;
        }
      });
    }
  }
  selectMessage(text, id) {
    this.textinput = 'input' + id;
    document.getElementById(this.textinput).focus();
  }

  /* send chat messages */
  sendChatMessages(idteam, team, message) {
    console.log(team, message);
    if (message.value !== '') {
      const headers = { 'Content-Type': 'application/json'};
      // tslint:disable-next-line:max-line-length
      const body = { team_idteam: idteam, textmessage: message.value, from: 'Operator', to: team, timestamp: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'), type_idtype: null};
      this.http.post<any>(`${FLEET_URL}` + 'insertMessage', body, { headers }).subscribe(data => {
      });
      this.chat.sendMsg({team, chat_message: message.value});
    }
  }
  /*OK open messages on buttons*/
  openMessages(idteam, team, teamunique): void {
    const buttonid = 'button' + teamunique;
    document.getElementById(buttonid).className = 'fa fa-envelope-open-o';
    document.getElementById(buttonid).setAttribute('style', 'color:none');
    this.getJSON_messages(team).subscribe(data => {
        const dialogRef = this.dialog.open(MessagesComponent, {
          data: {team_id: idteam, team_name: team, message: data}
        });
      },
      err => {
        console.log('Error! ' + err.message);
      });
  }
  /*OK open logs for event*/
  openEventLogs(): void {
    const dialogRef = this.dialog.open(EventLogsComponent, {
      width: '500px',
    });
  }
  /*OK open Logs on buttons*/
  openLogs(idteam): void {
    this.getJSON_logs(idteam).subscribe(data => {
        const dialogRef = this.dialog.open(LogsComponent, {
          width: '500px',
          data: {team_id: idteam, message: data}
        });
      },
      err => {
        console.log('Error! ' + err.message);
      });
  }
  /* manage fires discarding resolved fires */
  async getTo(to) {
    if (Object.keys(to).length > 0) {
      for (let i = 0; i < Object.keys(to).length; i++) {
        this.coordinatesto.push(to[i]);
      }
    }
    if (this.newfire !== undefined) {
      this.coordinatesto.push(this.newfire);
      this.lengthto = this.coordinatesto.length;
      this.newfire = undefined;
    }
    let coordinatesto = Array();
    /* if any alarm is resolved */
    if (localStorage.getItem('resolvealarm') === 'null') {
      coordinatesto = this.coordinatesto;
    }
    else {
      for (let i = 0; i < this.lengthto; i++) {
        // tslint:disable-next-line:max-line-length
        if (this.coordinatesto[i].time < JSON.parse(localStorage.getItem('resolvealarm')).time && this.coordinatesto[i].site === JSON.parse(localStorage.getItem('resolvealarm')).site)
        {}
        else {
          coordinatesto.push(this.coordinatesto[i]);
        }
      }
    }
    return coordinatesto;
  }
  /*OK open dialog to insert fire coordinates*/
  openDialog(): void {
    const dialogRef = this.dialog.open(FireCoordinatesComponent, {
      width: '250px',
      // tslint:disable-next-line:max-line-length
      data: {sinapsiid: this.sinapsiid, latitude: this.latitude, longitude: this.longitude}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      //   /*if user sets a fire, put icon on map*/
      if (result !== undefined) {
        const sinapsiid = result.sinapsiid;
        const lat = result.latitude;
        const long = result.longitude;
        console.log(sinapsiid);
        const headers = { 'Content-Type': 'application/json'};
        // tslint:disable-next-line:max-line-length
        const body = { site: null, sinapsiid, type_idtype: 2, latitude: lat, longitude: long, timestamp: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')};
        this.http.post<any>(`${FLEET_URL}` + 'insertEvent', body, { headers }).subscribe(data => {
        });
        alert('Fire inserted!');
      }
    });
  }
  /* OK select a fire to send team */
  selectFire(){
    // tslint:disable-next-line:max-line-length
    this.selectedfire = { title: this.firemarker.getCursor(), lat: this.firemarker.getPosition().lat(), lng: this.firemarker.getPosition().lng() };
    this.firewindow.close();
    this.firemarker = undefined;
    alert('Fire selected!');
  }
  /*OK  close a fire window */
  closeFire(){
    this.firewindow.close();
    this.firemarker = undefined;
  }

  containsWhitespace(str) {
    return /\s/.test(str);
  }
  ngOnInit() {
    document.getElementById('go').style.display = 'none';
    document.getElementById('show').style.display = 'none';
    document.getElementById('path').style.display = 'none';
    document.getElementById('selectyes').style.display = 'none';
    document.getElementById('selectno').style.display = 'none';
    document.getElementById('edityes').style.display = 'none';
    document.getElementById('editno').style.display = 'none';
    document.getElementById('deleteyes').style.display = 'none';
    document.getElementById('deleteno').style.display = 'none';
    this.origin = { lat: 40.351966, lng: 18.249536 };
    this.destination = { lat: 40.353562, lng: 18.333731 };
    this.googleMap = new google.maps.Map(document.getElementById('map'), this.mapOptions);
    this.teamwindow = new google.maps.InfoWindow();
    this.firewindow = new google.maps.InfoWindow();
    localStorage.setItem('resolvealarm', null);
    // subscribe to web socket messages
    this.chat.events.subscribe(msg => {
      if (this.textinput !== undefined) {
        console.log(this.textinput);
        document.getElementById(this.textinput).autofocus = true;
        // document.getElementById(this.textinput).
      }
      this.clearFires();
      this.clearMarkers();
      this.fires = [];
      this.markers = [];
      this.teams = [];
      // info about teams and events
      this.lengthevents = Object.keys(msg.events).length;
      this.events = msg.events;
      for (let i = 0; i < this.lengthevents; i++) {
        // this.table.put(this.events[i].event_id,
        //   {event_id: this.events[i].event_id,
        //     team_id: this.events[i].team_id,
        //     team_name: this.events[i].team_name,
        //     sinapsi_id: this.events[i].sinapsi_id,
        //     site: this.events[i].site,
        //     team_timestamp: this.events[i].team_timestamp,
        //     team_lat: this.events[i].team_lat,
        //     team_lng: this.events[i].team_lng,
        //     type: this.events[i].type,
        //     state: this.events[i].state,
        //     event_timestamp: this.events[i].event_timestamp,
        //     event_lat: this.events[i].event_lat,
        //     event_lng: this.events[i].event_lng,
        //     managed: this.events[i].managed,
        //     description: this.events[i].description
        //   });
        this.table.putArray(this.events[i].event_id,
          {team_id: this.events[i].team_id,
            team_name: this.events[i].team_name,
            sinapsi_id: this.events[i].sinapsi_id,
            site: this.events[i].site,
            team_timestamp: this.events[i].team_timestamp,
            team_lat: this.events[i].team_lat,
            team_lng: this.events[i].team_lng,
            type: this.events[i].type,
            state: this.events[i].state,
            event_timestamp: this.events[i].event_timestamp,
            event_lat: this.events[i].event_lat,
            event_lng: this.events[i].event_lng,
            managed: this.events[i].managed,
            description: this.events[i].description
          });
      }
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.table.getKeys().length; i++) {
        let url;
        const key = this.table.getKeys()[i];
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.table.getArray(key).length; j++) {
          if (this.table.getArray(key)[j].team_id !== null && this.table.getArray(key)[j].state !== null) {
            let cursor;
            if (this.table.getArray(key)[j].sinapsi_id !== null) {
              cursor = this.table.getArray(key)[j].sinapsi_id;
            }
            else { cursor = this.table.getArray(key)[j].site; }
            if (this.table.getArray(key)[j].type === 2) {
              switch (this.table.getArray(key)[j].state) {
                case 'start': {
                  url = '../../../assets/markers/vol_green.png';
                  console.log('incendio con squadra verde');
                  break;
                }
                case 'intervention': {
                  // tslint:disable-next-line:no-shadowed-variable
                  url = '../../../assets/markers/vol_red.png';
                  console.log('incendio con squadra rossa');
                  break;
                }
                default: {
                  url = '../../../assets/markers/vol_yellow.png';
                  break;
                }
              }
            }
            else if (this.table.getArray(key)[j].type === 1) {
              url = '../../../assets/markers/vol_yellow.png';
              console.log('squadra in patrolling gialla');
            }
            else {
              if (this.table.getArray(key)[j].state === 'start') {
                url = '../../../assets/markers/vol_grey.png';
                console.log('squadra in avaria');
              }
              else {
                url = '../../../assets/markers/vol_yellow.png';
                console.log('squadra in patrolling');
              }
            }
            this.marker = new google.maps.Marker({
              position: new google.maps.LatLng(this.table.getArray(key)[j].team_lat, this.table.getArray(key)[j].team_lng),
              title: this.table.getArray(key)[j].team_name,
              label: this.table.getArray(key)[j].team_id.toString(),
              icon: {url, scaledSize: new google.maps.Size(40, 40), },
              cursor
            });
            this.marker.setMap(this.googleMap);
            this.markers.push(this.marker);
          }
          /*fire not managed*/
          else {
            console.log('fire not managed');
          }
        }
      }
      /*populate table of teams and indicate the reserve teams*/
      for (let i = 0; i < Object.keys(msg.teams).length; i++) {
        const input = msg.teams[i];
        let url = '../../../assets/markers/vol_yellow.png';
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.markers.length; j++) {
          if (this.markers[j].title === input.name && this.markers[j].icon.url === '../../../assets/markers/vol_grey.png') {
            url = '../../../assets/markers/vol_grey.png';
            break;
          }
        }
        let uniquename = input.name;
        if (this.containsWhitespace(uniquename)) {
          uniquename = uniquename.replace(/\s/g, '');
        }
        this.teams.push([input.team_id, input.name, input.municipality, url, uniquename]);
      }
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.table.getKeys().length; i++) {
        const key = this.table.getKeys()[i];
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.table.getArray(key).length; j++) {
          if (this.table.getArray(key)[j].type === 2) {
            console.log('fire');
            this.fire = new google.maps.Marker({
              position: new google.maps.LatLng(this.table.getArray(key)[j].event_lat, this.table.getArray(key)[j].event_lng),
              title: this.table.getArray(key)[j].sinapsi_id,
              icon: {url: '../../../assets/markers/source.gif', scaledSize: new google.maps.Size(80, 80), },
              cursor: key
            });
            this.fire.setMap(this.googleMap);
            this.fires.push(this.fire);
            break;
          }
        }
      }
      // tslint:disable-next-line:prefer-for-of
      this.table = new HashTable<any, any>();
      // this.lengthfrom = Object.keys(msg.events).length;
      // this.coordinatesfrom = msg.coordinatesfrom;
      // this.from1 = this.coordinatesfrom;
      // info about fires
      // this.lengthto = Object.keys(msg.coordinatesto).length;
      // this.coordinatesto = msg.coordinatesto;
      /*verify if there is a fire*/
      // if (this.lengthto > 0) {
      //   if (JSON.stringify(this.coordinatestoold) !== JSON.stringify(this.coordinatesto)) {
      //     console.log('fires not managed');
      //     this.getCentroid();
      //   }
      // }
      // else {
      //   this.clearFires();
      //   this.dirs = [];
      //   this.devices = [];
      //   this.markers.forEach(marker => {
      //   marker.setAnimation(null);
      //   });
      // }
      // // initialize the value of minimum distance
      // this.mindistance = 10000000000000000000000;
      // /*verify if there is a team that patrols*/
      // if (this.lengthfrom > 0) {
      //   if (JSON.stringify(this.coordinatesfromold) !== JSON.stringify(this.coordinatesfrom)) {
      //     console.log('new teams start patrolling');
      //     this.putinitVehicles();
      //   }
      //   else {this.putVehicles(); }
      // }
      // else {
      //   this.clearMarkers();
      //   this.googleMap.setZoom(8);
      //   this.googleMap.setCenter(this.myCenter);
      // }
      // if (this.lengthto > 0) {
      //   this.selectNeighbour();
      //   if (JSON.stringify(this.coordinatestoold) !== JSON.stringify(this.coordinatesto)) {
      //     this.checkDirs();
      //     this.setbounds();
      //     this.coordinatestoold = this.coordinatesto;
      //   }
      // }
      // Iterate the markers array
      this.markers.forEach(marker => {
        // Set up a click event listener for each marker in the array
        marker.addListener('click', () => {
          this.teammarker = marker;
          if (marker.getIcon().url !== '../../../assets/markers/vol_grey.png') {
            if (marker.getIcon().url === '../../../assets/markers/vol_green.png') {
              this.teamwindow.setContent(
                // '<p>Event Name: '+this.title+'</p>' +
                '<p>Nome squadra: ' + marker.title + '</p>' +
                '<button onclick= document.getElementById("show").click() >Mostra percorso</button>');
            }
            else {
              this.teamwindow.setContent(
                // '<p>Event Name: '+this.title+'</p>' +
                '<p>Nome squadra: ' + marker.title + '</p>' +
                // '<p>Cause: '+this.cause+'</p>' +
                // '<p>Date: '+this.date+'</p>' +
                // '<p>Time: '+this.time+'</p>' +
                '<button onclick= document.getElementById("go").click() >Vai!</button>');
            }
          }
          else {
            this.teamwindow.setContent(
              // '<p>Event Name: '+this.title+'</p>' +
              '<p>Nome squadra: ' + marker.title + '</p>');
          }
          this.teamwindow.open(this.googleMap, marker);
        });
        this.teamwindow.addListener('closeclick', () => {
          // Handle focus manually.
          this.teammarker = undefined;
        });
      });
      if (this.teammarker !== undefined) {
        this.teamwindow.open(this.googleMap, this.teammarker);
      }
      this.fires.forEach(fire => {
        // Set up a click event listener for each fire in the array
        fire.addListener('click', () => {
          this.firemarker = fire;
          this.firewindow.setContent(
            // '<p>Event Name: '+this.title+'</p>' +
            '<p> Vuoi selezionare questo incendio? </p>' +
            '<button onclick= document.getElementById("selectyes").click() >Si</button>' +
            '<button onclick= document.getElementById("selectno").click() >No</button>' +
            '<p> Vuoi modificare la posizione di questo incendio? </p>' +
            '<button onclick= document.getElementById("edityes").click() >Si</button>' +
            '<button onclick= document.getElementById("editno").click() >No</button>' +
            '<p> Vuoi eliminare questo incendio? </p>' +
            '<button onclick= document.getElementById("deleteyes").click() >Si</button>' +
            '<button onclick= document.getElementById("deleteno").click() >No</button>'
          );
          this.firewindow.open(this.googleMap, fire);
        });
        this.firewindow.addListener('closeclick', () => {
          // Handle focus manually.
          this.firewindow = new google.maps.InfoWindow();
          this.firemarker = undefined;
        });
      });
      if (this.firemarker !== undefined) {
        this.firewindow.open(this.googleMap, this.firemarker);
      }
      // this.getTo(msg.coordinatesto).then(coordinatesto => {
      //   localStorage.setItem('coordinatesto', JSON.stringify(coordinatesto));
      //   /*verify if there is a fire*/
      //   if (this.lengthto > 0) {
      //     if (JSON.stringify(this.coordinatestoold) !== JSON.stringify(coordinatesto)) {
      //       this.getCentroid();
      //     }
      //   }
      //   // initialize the value of minimum distance
      //   this.mindistance = 10000000000000000000000;
      //   if (localStorage.getItem('markersfrom') === 'null') {this.putinitVehicles(); }
      //   else {this.putVehicles(); }
      //   if (this.lengthto > 0) {
      //     this.selectNeighbour();
      //     if (JSON.stringify(this.coordinatestoold) !== JSON.stringify(this.coordinatesto)) {
      //       this.setbounds();
      //       this.coordinatestoold = this.coordinatesto;
      //     }
      //   }
      //   // fit zoom on map
      //   google.maps.event.addListener(this.googleMap, 'zoom_changed', () => {
      //     document.getElementById('bounds').style.display = 'block';
      //   });
      //   // Iterate the markers array
      //   this.markers.forEach(marker => {
      //     // Set up a click event listener for each marker in the array
      //     marker.addListener('click', () => {
      //       this.markerwindow = marker;
      //       this.infowindow.setContent(
      //         // '<p>Event Name: '+this.title+'</p>' +
      //         '<p>Nome squadra: ' + marker.title + '</p>' +
      //         // '<p>Cause: '+this.cause+'</p>' +
      //         // '<p>Date: '+this.date+'</p>' +
      //         // '<p>Time: '+this.time+'</p>' +
      //         '<button onclick= document.getElementById("go").click() >Vai!</button>');
      //       this.infowindow.open(this.googleMap, marker);
      //     });
      //     this.infowindow.addListener('closeclick', () => {
      //       // Handle focus manually.
      //       this.markerwindow = undefined;
      //     });
      //   });
      //   if (this.markerwindow !== undefined) {
      //     this.infowindow.open(this.googleMap, this.markerwindow);
      //   }
      //   this.fires.forEach(fire => {
      //     // Set up a click event listener for each fire in the array
      //     fire.addListener('click', () => {
      //       this.firemarker = fire;
      //       this.firewindow.setContent(
      //         // '<p>Event Name: '+this.title+'</p>' +
      //         '<p> Vuoi selezionare questo incendio? </p>' +
      //         '<button onclick= document.getElementById("selectyes").click() >Si</button>' +
      //         '<button onclick= document.getElementById("selectno").click() >No</button>');
      //       this.firewindow.open(this.googleMap, fire);
      //     });
      //     this.firewindow.addListener('closeclick', () => {
      //       // Handle focus manually.
      //       this.firemarker = undefined;
      //     });
      //   });
      // });
    });
    this.chat.messages.subscribe(msg => {
      const jsonmessage = JSON.parse(msg.text);
      let buttonid = 'button' + jsonmessage.sender;
      if (this.containsWhitespace(jsonmessage.sender)) {
        buttonid = 'button' + jsonmessage.sender.replace(/\s/g, '');
      }
      document.getElementById(buttonid).className = 'fa fa-envelope';
      document.getElementById(buttonid).setAttribute('style', 'color:red');
      alert('You have a message from ' + jsonmessage.sender);
    });
    // this.chat.stopalarms.subscribe(alarm => {
    //   console.log(alarm.text);
    //   const jsonvalues = JSON.parse(alarm.text);
    //   const sender = jsonvalues.sender;
    //   const headers = { 'Content-Type': 'application/json'};
    //   const body = { team: sender, status: 'completed'};
    //   this.http.post<any>('http://vs.ofidia.eu:8085/finishfire', body, { headers }).subscribe(data => {
    //     alert('The fire has been solved by ' + sender);
    //   });
    //   this.markers.forEach(marker => {
    //     if (marker.title === sender) {
    //       marker.setIcon({url: '../../../assets/markers/' + marker.cursor + '.png', scaledSize: new google.maps.Size(48, 48), });
    //     }
    //   });
    //   deleteRoute(sender);
    //   let url;
    //   const jsonmessage = JSON.stringify(JSON.parse(alarm.text));
    //   const jsonvalues = JSON.parse(alarm.text);
    //   const sender = jsonvalues.sender;
    //   localStorage.setItem('resolvealarm', jsonmessage);
    //   this.clearFires();
    //   this.markers.forEach(marker => {
    //     if (marker.title === sender) {
    //       url = '../../../assets/markers/' + jsonvalues.site + '.png';
    //       // switch (jsonvalues.site) {
    //       //   case 'Cesine':
    //       //     url = '../../../assets/markers/vol_Cesine.png';
    //       //     break;
    //       //   case 'Masseria Cuturi':
    //       //     url = '../../../assets/markers/vol_Cuturi.png';
    //       //     break;
    //       //   case 'Masseria Galeone':
    //       //     url = '../../../assets/markers/vol_Galeone.png';
    //       //     break;
    //       //   case 'Ugento':
    //       //     url = '../../../assets/markers/vol_Ugento.png';
    //       //     break;
    //       // }
    //       marker.setIcon({url, scaledSize: new google.maps.Size(48, 48), });
    //       marker.setAnimation(null);
    //     }
    //   });
    //   deleteRoute(jsonvalues.site);
    // });
  }
}

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'logsevent.html',
})
export class EventLogsComponent implements OnInit{

  constructor(
    // tslint:disable-next-line:max-line-length
    private http: HttpClient, public logsRef: MatDialogRef<EventLogsComponent>, @Inject(MAT_DIALOG_DATA) public data: LogsData, private datePipe: DatePipe) {
  }
  date: string;
  sinapsiid: any;
  head: string;
  row: string;
  rows: Array<any> = [];
  takeSinapsiId(event) {
    this.sinapsiid = event.target.value;
  }
  dateChange(event){
    this.date = this.datePipe.transform(event.target.value, 'yyyy-MM-dd');
  }
  /* search logs */
  searchLogs() {
    if (this.sinapsiid !== undefined && this.date !== undefined) {
      // tslint:disable-next-line:max-line-length
      this.http.get(`${LOGS_BY_EVENT_URL}` + this.sinapsiid + '&date=' + this.date).subscribe(data => {
          this.rows = [];
          this.head = 'SINAPSI ID:' + this.sinapsiid;
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.row = '[' + this.datePipe.transform(data[i][`timestamp`], 'yyyy-MM-dd HH:mm:ss') + '] ' + data[i][`description`];
            this.rows.push(this.row);
          }
        },
        err => {
          console.log('Error! ' + err.message);
        });
    }
  }
  ngOnInit() {

  }
  download(){
    const blob = new Blob(this.rows.map(e => e.concat('\n'), {type: 'text/plain;charset=utf-8'}));
    saveAs(blob, 'logs.txt');
  }
  onNoClick(): void {
    this.logsRef.close();
  }
}

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'logs.html',
})
export class LogsComponent implements OnInit{

  constructor(
    // tslint:disable-next-line:max-line-length
    private http: HttpClient, public logsRef: MatDialogRef<LogsComponent>, @Inject(MAT_DIALOG_DATA) public data: LogsData, private datePipe: DatePipe) {
  }
  date: string;
  teamid: any;
  distance: any;
  row: string;
  rows: Array<any> = [];
  dateChange(event){
    this.date = this.datePipe.transform(event.target.value, 'yyyy-MM-dd');
  }

  /* send chat messages */
  searchLogs() {
    console.log(this.teamid, this.date);
    if (this.date !== undefined) {
      this.http.get(`${LOGS_BY_TEAM_URL}` + this.teamid + '&date=' + this.date).subscribe(data => {
          this.rows = [];
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < Object.keys(data).length; i++) {
            this.row = '[' + this.datePipe.transform(data[i][`timestamp`], 'yyyy-MM-dd HH:mm:ss') + '] ' + data[i][`description`];
            this.rows.push(this.row);
          }
        },
        err => {
          console.log('Error! ' + err.message);
        });
      this.http.get(`${DISTANCE_URL}` + this.teamid + '&date=' + this.date).subscribe(data => {
          // tslint:disable-next-line:prefer-for-of
          this.distance = 'DISTANZA PERCORSA: ' + data + ' km';
        },
        err => {
          console.log('Error! ' + err.message);
        });
    }
  }
  ngOnInit() {
    // @ts-ignore
    this.teamid = this.data.team_id;
    console.log(this.data.message);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.data.message.length; i++) {
      this.row = '[' + this.datePipe.transform(this.data.message[i][`timestamp`], 'yyyy-MM-dd HH:mm:ss') + '] ' + this.data.message[i][`description`];
      this.rows.push(this.row);
    }
  }
  download(){
    const blob = new Blob(this.rows.map(e => e.concat('\n'), {type: 'text/plain;charset=utf-8'}));
    saveAs(blob, 'logs.txt');
  }
  onNoClick(): void {
    this.logsRef.close();
  }
}
@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'messages.html',
})
export class MessagesComponent implements OnInit{

  constructor(
    // tslint:disable-next-line:max-line-length
    private http: HttpClient, private chat: ChatService, public logsRef: MatDialogRef<MessagesComponent>, @Inject(MAT_DIALOG_DATA) public data: MessagesData, private datePipe: DatePipe) {
  }
  @ViewChild('inputName') inputName;
  idteam: string;
  team: string;
  row: string;
  msg: string;
  rows: Array<any> = [];
  /* send chat messages */
  sendChatMessages(idteam, team, message) {
    console.log(team, message);
    if (message.value !== '') {
      const headers = { 'Content-Type': 'application/json'};
      // tslint:disable-next-line:max-line-length
      const body = { team_idteam: idteam, textmessage: message.value, from: 'Operator', to: team, timestamp: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'), type_idtype: null};
      this.http.post<any>(`${FLEET_URL}` + 'insertMessage', body, { headers }).subscribe(data => {
      });
      this.chat.sendMsg({team, chat_message: message.value});
      alert('message sent');
      this.inputName.nativeElement.value = ' ';
    }
  }
  ngOnInit() {
    this.idteam = this.data.team_id;
    this.team = this.data.team_name;
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.data.message.length - 1; i >= 0; i--) {
      this.row = '[FROM ' + this.data.message[i][`from`] + ' AT ' + this.datePipe.transform(this.data.message[i][`timestamp`], 'yyyy-MM-dd HH:mm:ss') + '] ';
      this.rows.push(this.row);
      this.msg = this.data.message[i][`body`];
      this.rows.push(this.msg);
    }
  }
  download(){
    const blob = new Blob(this.rows.map(e => e.concat('\n'), {type: 'text/plain;charset=utf-8'}));
    saveAs(blob, 'messages.txt');
  }
  onNoClick(): void {
    this.logsRef.close();
  }
}
@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'firecoordinates.html',
})
export class FireCoordinatesComponent {

  constructor(
    public dialogRef: MatDialogRef<FireCoordinatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CoordinatesData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'deleteconfirm.html',
})
export class DeleteConfirmComponent {

  formDialog: any;
  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmComponent>) {}

  onNoClick(): void {
    this.formDialog = 'Back';
    this.dialogRef.close({ event: 'close', data: this.formDialog });
  }

}

