import {Component, HostListener, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {
  DASHBOARD_URL, SITE_ALARM_DAILY_URL, SITE_ALARM_HOURLY_URL,
  SITE_LAST_UPDATE_URL, SITE_PREALARM_DAILY_URL, SITE_PREALARM_HOURLY_URL, TOTAL_TTALARMS_URL,
  TOTAL_TTMEASURES_URL
} from '../../env';
import MapTypeId = google.maps.MapTypeId;
import { DatePipe } from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'app-site-monitoring-details',
  templateUrl: './site-monitoring-details.component.html',
  styleUrls: ['./site-monitoring-details.component.css']
})
export class SiteMonitoringDetailsItaComponent implements OnInit {
  /*site info*/
  site: string;
  lastupdate: string;
  /*time filter*/
  aggregationprealarm: any;
  aggregationalarm: any;
  timefilter: string;
  totTTmeasures: number;
  totTTmeasuresbytime: number;
  totTTalarms: number;
  totTTalarmsbytime: number;
  timeperiod: any = 'Last 24 hours';
  nowtimestamp: number;
  timestamp: number;
  prealarmvalue: any = 2;
  alarmvalue: any = 24;
  /* Grafana url */
  url: any;
  dangerousriskUrl: any;
  trustedriskUrl: any;
  dangerousUrl: any;
  trustedUrl: any;
  dangerous1Url: any;
  trusted1Url: any;
  constructor(private http: HttpClient, private datePipe: DatePipe, private sanitizer: DomSanitizer) {
  }
  public getJSON_lastupdate(value): Observable<any> {
    return this.http.get(`${SITE_LAST_UPDATE_URL}` + '\'' + value + '\'');
  }
  public getJSON_totTTmeasures(query, value): Observable<any> {
    return this.http.get(`${TOTAL_TTMEASURES_URL}` + query + '\'' + value + '\'');
  }
  public getJSON_totTTalarms(query, value): Observable<any> {
    return this.http.get(`${TOTAL_TTALARMS_URL}` + query + '\'' + value + '\'');
  }
  /*function to execute refreshing of data*/
  callEveryHour(){
    const nextdate: any = new Date();
    let thisdate: any;
    let difference: any;
    if (nextdate.getMinutes() === 10 && nextdate.getSeconds() === 0) { // You can check for seconds here too
      this.refreshData(true);
      this.timeFilter(true, undefined, this.timeperiod);
    } else {
      if (nextdate.getMinutes() < 10) {
        nextdate.setMinutes(10);
        nextdate.setSeconds(0);
      }
      else {
        nextdate.setHours(nextdate.getHours() + 1);
        nextdate.setMinutes(10);
        nextdate.setSeconds(0);
      }
      thisdate = new Date();
      difference = nextdate - thisdate;
      setTimeout(function() {
        this.refreshData(true);
        this.timeFilter(true, undefined, this.timeperiod);
      }.bind(this), difference);
    }
  }
  /*function to change indicator*/
  toggleDisplay(value: any, dashboard: any) {
    this.nowtimestamp = Math.trunc(new Date().getTime());
    switch (this.timeperiod) {
      case 'Last 24 hours': {
        this.aggregationprealarm = `${SITE_PREALARM_HOURLY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_HOURLY_URL}`;
        this.timefilter = 'WHERE time > now()-1d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 86400000;
        break;
      }
      case 'Last week': {
        this.aggregationprealarm = `${SITE_PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-7d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 604800000;
        break;
      }
      case 'Last month': {
        this.aggregationprealarm = `${SITE_PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-30d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 2592000000;
        break;
      }
      case 'Last quarter': {
        this.aggregationprealarm = `${SITE_PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-120d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 10368000000;
        break;
      }
      case 'Last semester': {
        this.aggregationprealarm = `${SITE_PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-180d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 15552000000;
        break;
      }
      case 'Last year': {
        this.aggregationprealarm = `${SITE_PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-365d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 31104000000;
        break;
      }
    }
    if (dashboard === 'prealarm'){
      this.prealarmvalue = value;
      // tslint:disable-next-line:max-line-length
      this.dangerousUrl = this.url + this.aggregationprealarm + this.site + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=' + this.prealarmvalue;
      this.trustedUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
    }
    else {
      this.alarmvalue = value;
      // tslint:disable-next-line:max-line-length
      this.dangerous1Url = this.url + this.aggregationalarm + this.site + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=' + this.alarmvalue;
      this.trusted1Url =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    }
  }
  /*function to refresh tt sensor details*/
  refreshData(check){
    if (check) {
      setInterval(() => {
        this.refreshData(false);
      }, 3600000);
    }
    this.getJSON_lastupdate(this.site).subscribe(data => {
      if (data[`results`][0][`series`] !== undefined) {
        this.lastupdate = (this.datePipe.transform(new Date(data[`results`][0][`series`][0][`values`][0][0]), 'yyyy-MM-dd HH:mm:ss'));
        localStorage.setItem('update', this.lastupdate);
      }
    });
    this.timefilter = 'WHERE "site"=';
    this.getJSON_totTTmeasures(this.timefilter, this.site).subscribe(data => {
      if (data[`results`][0][`series`] !== undefined) {
        this.totTTmeasures = data[`results`][0][`series`][0][`values`][0][1];
      }
    });
    this.getJSON_totTTalarms(this.timefilter, this.site).subscribe(data => {
      if (data[`results`][0][`series`] !== undefined) {
        this.totTTalarms = data[`results`][0][`series`][0][`values`][0][1];
      }
      else {
        this.totTTalarms = 0;
      }
    });
  }
  /*function to refresh measures by time period*/
  timeFilter(check, event, period) {
    if (check) {
      setInterval(() => {
        this.timeFilter(false, undefined, this.timeperiod);
      }, 3600000);
    }
    if (event !== undefined) {
      this.timeperiod = event.target.value;
    }
    else {
      this.timeperiod = period;
    }
    this.nowtimestamp = Math.trunc(new Date().getTime());
    switch (this.timeperiod) {
      case 'Last 24 hours': {
        this.aggregationprealarm = `${SITE_PREALARM_HOURLY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_HOURLY_URL}`;
        this.timefilter = 'WHERE time > now()-1d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 86400000;
        break;
      }
      case 'Last week': {
        this.aggregationprealarm = `${SITE_PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-7d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 604800000;
        break;
      }
      case 'Last month': {
        this.aggregationprealarm = `${SITE_PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-30d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 2592000000;
        break;
      }
      case 'Last quarter': {
        this.aggregationprealarm = `${SITE_PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-120d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 10368000000;
        break;
      }
      case 'Last semester': {
        this.aggregationprealarm = `${SITE_PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-180d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 15552000000;
        break;
      }
      case 'Last year': {
        this.aggregationprealarm = `${SITE_PREALARM_DAILY_URL}`;
        this.aggregationalarm = `${SITE_ALARM_DAILY_URL}`;
        this.timefilter = 'WHERE time > now()-365d AND time < now() AND "site"=';
        this.timestamp = this.nowtimestamp - 31104000000;
        break;
      }
    }
    // tslint:disable-next-line:max-line-length
    this.dangerousUrl = this.url + this.aggregationprealarm + this.site + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=' + this.prealarmvalue;
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
    // tslint:disable-next-line:max-line-length
    this.dangerous1Url = this.url + this.aggregationalarm + this.site + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=' + this.alarmvalue;
    this.trusted1Url =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    this.getJSON_totTTmeasures(this.timefilter, this.site).subscribe(data => {
      if (data[`results`][0][`series`] !== undefined) {
        this.totTTmeasuresbytime = data[`results`][0][`series`][0][`values`][0][1];
      }
      else {
        this.totTTmeasuresbytime = 0;
      }
    });
    this.getJSON_totTTalarms(this.timefilter, this.site).subscribe(data => {
      if (data[`results`][0][`series`] !== undefined) {
        this.totTTalarmsbytime = data[`results`][0][`series`][0][`values`][0][1];
      }
      else {
        this.totTTalarmsbytime = 0;
      }
    });
  }
  ngOnInit() {
    this.site = localStorage.getItem('selectedSite');
    /*setting Grafana dashboards url*/
    this.url = `${DASHBOARD_URL}`;
    this.aggregationprealarm = `${SITE_PREALARM_HOURLY_URL}`;
    this.aggregationalarm = `${SITE_ALARM_HOURLY_URL}`;
    this.nowtimestamp = Math.trunc(new Date().getTime());
    this.timestamp = this.nowtimestamp - 86400000;
    this.lastupdate = localStorage.getItem('update');
    this.timefilter = 'WHERE "site"=';
    // tslint:disable-next-line:max-line-length
    this.dangerousriskUrl = this.url + this.aggregationprealarm + this.site + '&panelId=18';
    this.trustedriskUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousriskUrl);
    // tslint:disable-next-line:max-line-length
    this.dangerousUrl = this.url + this.aggregationprealarm + this.site + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=2';
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
    // tslint:disable-next-line:max-line-length
    this.dangerous1Url = this.url + this.aggregationalarm + this.site + '&from=' + this.timestamp + '&to=' + this.nowtimestamp + '&panelId=24';
    this.trusted1Url =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerous1Url);
    this.refreshData(false);
    this.timeFilter(false, undefined, this.timeperiod);
    this.callEveryHour();
  }
}

