<div class="menu indicator box">
  <div class="btn-group" style="width:100%">
    <button class="item" (click)="goToPage('firedanger_ita')" style="width:25%">
      <img src="assets/icons/logo1.jpg" width="230" height="138" alt=""/>
      <p>Indici di pericolosità potenziale degli incendi</p>
    </button>
    <button class="item" (click)="goToPage('firebehaviour_ita')" style="width:25%">
      <img src="assets/icons/logo2.jpg" width="230" height="138" alt=""/>
      <p>Comportamento dell'incendio</p>
    </button>
    <button class="item" (click)="goToPage('sensor_ita')" style="width:25%">
      <img src="assets/icons/logo3.jpg" width="230" height="138" alt=""/>
      <p>Monitoraggio dei sensori</p>
    </button>
    <button class="item" (click)="goToPage('meteo_ita')" style="width:25%">
      <img src="assets/icons/logo7.png" width="230" height="138" alt=""/>
      <p>Stazioni meteo</p>
    </button>
  </div>
  <div class="btn-group" style="width:100%">
    <button class="item" (click)="goToPage('videocameras_ita')" style="width:33.3%">
      <img src="assets/icons/logo4.jpg" width="230" height="138" alt=""/>
      <p>Video Camere</p>
    </button>
    <button class="item" (click)="goToPage('drones_ita')" style="width:33.3%">
      <img src="assets/icons/logo5.jpg" width="230" height="138" alt=""/>
      <p>Storico video dei droni</p>
    </button>
    <button class="item" (click)="goToPage('fleet_ita')" style="width:33.3%">
      <img src="assets/icons/logo6.jpg" width="230" height="138" alt=""/>
      <p>Gestione delle flotte</p>
    </button>
  </div>
</div>

