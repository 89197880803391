<div class="row">
    <div class="col-sm-12">
      <div class="container">
        <div id="map"></div>
        <div class="list">
          <div class='legend-scale'>
            <div [ngSwitch]="indexvalue">
              <div *ngSwitchCase="'ffwi'">
                <img src="assets/legends/FFWI.png" width="80" height="230">
              </div>
              <div *ngSwitchCase="'wind'">
              </div>
              <div *ngSwitchCase="'tp'">
                <img src="assets/legends/tp.png" width="70" height="230">
              </div>
              <div *ngSwitchCase="'rh'">
                <img src="assets/legends/rh.png" width="70" height="230">
              </div>
              <div *ngSwitchCase="'temp'">
                <img src="assets/legends/t.png" width="70" height="230">
              </div>
              <div *ngSwitchCase="'swf'">
                <img src="assets/legends/swf.png" width="70" height="230">
              </div>
              <div *ngSwitchDefault>
                <img src="assets/legends/fireindices.png" width="80" height="230">
              </div>
            </div>
          </div>
        </div>
          <div class="btn-group pull-left">
            <div class="boxbuttons" #1 id="ffwi" style="margin-right: 4px">
              <button class="button1" [disabled]="bool" (click) = "changeMap('ffwi')">FFWI</button>
              <button class="button2" [disabled]="bool" (click)="openDialog('Canadian Fire Weather Index ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
            </div>
            <div class="boxbuttons" #2 id="fwi" style="margin-right: 4px">
              <button class="button1" [disabled]="bool" (click) = "changeMap('fwi')">FWI</button>
              <button class="button2" [disabled]="bool" (click)="openDialog('Fosberg Fire Weather Index ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
            </div>
            <div class="boxbuttons" #3 id="ifi" style="margin-right: 10px">
              <button class="button1" [disabled]="bool" (click) = "changeMap('ifi')">IFI</button>
              <button class="button2" [disabled]="bool" (click)="openDialog('Integrated Fire Danger Index ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
            </div>
            <div class="boxbuttons" #4 id="wind" style="margin-right: 10px">
              <button class="button1" [disabled]="bool" (click) = "changeMap('wind')">WIND</button>
              <button class="button2" [disabled]="bool" (click)="openDialog('Velocità e direzione del vento ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
            </div>
            <div class="boxbuttons" #5 id="tp" style="margin-right: 4px">
              <button class="button1" [disabled]="bool" (click) = "changeMap('tp')">TP</button>
              <button class="button2" [disabled]="bool" (click)="openDialog('Precipitazione totale ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
            </div>
            <div class="boxbuttons" #6 id="rh" style="margin-right: 4px">
              <button class="button1" [disabled]="bool" (click) = "changeMap('rh')">RH</button>
              <button class="button2" [disabled]="bool" (click)="openDialog('Umidità relativa ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
            </div>
            <div class="boxbuttons" #7 id="temp" style="margin-right: 4px">
              <button class="button1" [disabled]="bool" (click) = "changeMap('temp')">T</button>
              <button class="button2" [disabled]="bool" (click)="openDialog('Temperatura dell\'aria ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
            </div>
            <div class="boxbuttons" #8 id="swf" style="margin-right: 20px">
              <button class="button1" [disabled]="bool" (click) = "changeMap('swf')">SWF</button>
              <button class="button2" [disabled]="bool" (click)="openDialog('Radiazione solare ','')" style="font-size: 1rem;"><i class="fa fa-info-circle fa-xs"></i></button>
            </div>
          </div>
        <progress *ngIf="max && hour" class="bar pull-left" [value]="hour" [max]="max" style="width: 200px; height: 30px; margin-right: 20px"></progress>
        <button id = "forward" type="button" class="btn btn-default btn-circle" [disabled]="bool" (click) = "changeHour()" style="margin-top: 0px; margin-right: 20px"><i class="fa fa-forward"></i></button>
        <button id = "play" type="button" class="btn btn-default btn-circle" [disabled]="bool" (click) = "startTimer(true)" style="margin-top: 0px; margin-right: 4px"><i class="fa fa-play"></i></button>
        <button id = "stop" type="button" class="btn btn-default btn-circle" [disabled]="!bool" (click) = "startTimer(false)" style="margin-top: 0px; margin-right: 10px"><i class="fa fa-stop"></i></button>
        <label style="margin-right: 50px">{{date}} {{timer}}:00</label>
        <mat-radio-group id="mat-radio-buttons" color="primary" aria-label="Select an option" (change)="changePeriod($event)">
          <mat-radio-button value="1" checked style="font-size: small;">Ultima settimana</mat-radio-button>
          <mat-radio-button value="2" style="font-size: small;">Ultimo mese</mat-radio-button>
        </mat-radio-group>
        <iframe [src]="trustedUrl" style="margin-top: 15px" width="100%" height="250" frameborder="0"></iframe>
      </div>
    </div>
  </div>
