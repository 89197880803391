<div mat-dialog-content>
  <p>Inserisci Sinapsi ID: </p>
  <mat-form-field class="full-width" appearance="fill" style="width:195px; height: 20px">
    <mat-label></mat-label>
    <input maxlength="15" matInput [(ngModel)]="data.sinapsiid" autofocus>
  </mat-form-field>
  <br>
  <br>
  <p>Inserisci latitudine: </p>
  <mat-form-field class="full-width" appearance="fill" style="width:195px; height: 20px">
    <mat-label></mat-label>
    <input maxlength="15" matInput [(ngModel)]="data.latitude" autofocus>
  </mat-form-field>
  <br>
  <br>
  <p>Inserisci longitudine: </p>
  <mat-form-field class="full-width" appearance="fill" style="width:195px; height: 20px">
  <mat-label></mat-label>
  <input maxlength="15" matInput [(ngModel)]="data.longitude" autofocus>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Indietro</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
