import {Component, OnInit} from '@angular/core';
import { DatePipe } from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {DRONES_VIDEOS_URL} from '../../env';

@Component({
  selector: 'app-drones',
  templateUrl: './drones.component.html',
  styleUrls: ['./drones.component.css']
})
export class DronesComponent implements OnInit {
  dangerousUrl: any;
  trustedUrl: any;
  constructor(private http: HttpClient, private datePipe: DatePipe, private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this.dangerousUrl = `${DRONES_VIDEOS_URL}`;
    this.trustedUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousUrl);
  }
}
