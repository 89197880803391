import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {TokenStorageService} from '../../services/token-storage.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
  providers: [AuthService]
})
export class WelcomeComponent implements OnInit {

  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  role: string;
  constructor(private router: Router, private authService: AuthService, private tokenStorage: TokenStorageService) { }
  ngOnInit(): void {
    document.getElementById('mat-login').style.display = 'none';
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.role = this.tokenStorage.getUser().role;
    }
  }
  goToLogin(pageName: string): void {
    this.router.navigate([`${pageName}`]);
  }
  openLogin(id: string) {
    document.getElementById(id).style.display = 'block';
  }
  onSubmit(): void {
    this.authService.login(this.form).subscribe(
      data => {
        this.tokenStorage.saveToken(data);
        this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.role = this.tokenStorage.getUser().role;
        this.reloadPage();
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  goToPage() {
    if (this.role === 'ROLE_ADMIN') {
      this.router.navigate(['admin']);
    }
    if (this.role === 'ROLE_PARKMANAGER') {
      this.router.navigate(['parkmanager']);
    }
    if (this.role === 'ROLE_OPERATOR') {
      this.router.navigate(['operator']);
    }
  }
  reloadPage(): void {
    window.location.reload();
  }
}
